const baseRoutes = {
  login: `login`,
  register: `register`,
  schools: `schools`,
  dashboard: `dashboard`,
  reception: `reception`,
  studentManagement: `student-management`,
  schoolManagement: `management`,
  fees_management: `fees_management`,
  ledger_and_account: `ledger-n-account`,
  examControl: `exam-control`,
  academics: `academics`,
  communication: `communication`,
  transportation: `transportation`,
  hr: "hr",
  validateSession: `validate-session`,
  bioMetric: "bio-metric",
  websiteBuilder: "website-builder",
  paymentGateway: "payment-gateway",
};

const appRoutes = {
  login: baseRoutes.login,
  register: baseRoutes.register,
  forgotPassword: "/forgot-password",
  // passwordChange: "/password-change",
  schools: baseRoutes.schools,
  bioMetric: baseRoutes.bioMetric,
  paymentGateway: baseRoutes.paymentGateway,
  userProfile: "user-profile",
  applySchool: "apply-school",
  validateSession: baseRoutes.validateSession,
  // dashboard
  dashboard: baseRoutes.dashboard,
  schoolDashboard: `${baseRoutes.dashboard}/school`,
  collegeDashboard: `${baseRoutes.dashboard}/college`,
  coachingDashboard: `${baseRoutes.dashboard}/coaching`,
  groupOfInstitutesDashboard: `${baseRoutes.dashboard}/group-of-institutes`,
  // students
  studentManagement: baseRoutes.studentManagement,
  admissionApproval: `${baseRoutes.studentManagement}/admission-approval`,
  certificate: `${baseRoutes.studentManagement}/certificate`,
  students: `${baseRoutes.studentManagement}/students`,
  studentReport: `${baseRoutes.studentManagement}/student-report`,
  bulkEdit: `${baseRoutes.studentManagement}/bulkEdit`,
  promotionAndMigration: `${baseRoutes.studentManagement}/promotionAndMigration`,
  create_student: `${baseRoutes.studentManagement}/students/create-student`,
  student_profile: `${baseRoutes.studentManagement}/students/student-profile`,
  alumni: `${baseRoutes.studentManagement}/alumni`,
  idCard: `${baseRoutes.studentManagement}/idCard`,
  existStudent: `${baseRoutes.studentManagement}/existStudent`,
  edit_promotion_student_details: `${baseRoutes.studentManagement}/promotionAndMigration/edit-details`,
  migrate: `${baseRoutes.studentManagement}/promotionAndMigration/migrate`,
  editMigrate: `${baseRoutes.studentManagement}/promotionAndMigration/edit-migration`,
  edit_admission_approval_details: `${baseRoutes.studentManagement}/admission-approval/edit-details/:requestDocId`,
  //reception
  reception: baseRoutes.reception,
  complaints: `${baseRoutes.reception}/complaints`,
  visitorControl: `${baseRoutes.reception}/visitor-control`,
  staffAttendance: `${baseRoutes.reception}/staff-attendance`,
  admission_enquiry: `${baseRoutes.reception}/admission-enquiry`,
  // school management
  schoolManagement: baseRoutes.schoolManagement,
  sessionSettings: `${baseRoutes.schoolManagement}/session-settings`,
  departments: `${baseRoutes.schoolManagement}/departments`,
  classes: `${baseRoutes.schoolManagement}/classes`,
  accessControl: `${baseRoutes.schoolManagement}/access-control`,
  invitationCode: `${baseRoutes.schoolManagement}/invitation-code`,

  //fees
  fees_management: baseRoutes.fees_management,
  fees: `${baseRoutes.fees_management}/fees-structure`,
  collectFee: `${baseRoutes.fees_management}/collect-fee`,
  fees_collection: `${baseRoutes.fees_management}/fees_collection`,
  fees_collection2: `${baseRoutes.fees_management}/new-fee-collection`,
  fees_report: `${baseRoutes.fees_management}/fees_report`,
  fees_invoice: `${baseRoutes.fees_management}/fees_invoice`,
  fees_discount: `${baseRoutes.fees_management}/fees_discount`,
  arrear: `${baseRoutes.fees_management}/arrear`,
  invoice: `${baseRoutes.fees_management}/invoice`,
  sessionOrderInvoice: `${baseRoutes.fees_management}/session-order-invoice`,
  NewInvoice: `${baseRoutes.fees_management}/new-invoice`,
  arrearInvoice: `${baseRoutes.fees_management}/arrear-invoice`,

  // Ledger and Account
  ledger_and_account: baseRoutes.ledger_and_account,
  collections: `${baseRoutes.ledger_and_account}/collections`,
  expenses: `${baseRoutes.ledger_and_account}/expenses`,
  ledger_repport: `${baseRoutes.ledger_and_account}/ledger-report`,
  collectionSubHeads: `${baseRoutes.ledger_and_account}/ledger-report/sub-heads/c/:id`,
  expenseSubHeads: `${baseRoutes.ledger_and_account}/ledger-report/sub-heads/e/:id`,
  firms: `${baseRoutes.ledger_and_account}/firms`,
  firmProfile: `${baseRoutes.ledger_and_account}/firm/profile`,
  books: `${baseRoutes.ledger_and_account}/books`,
  paySlip: `${baseRoutes.ledger_and_account}/books/pay-slip`,
  // collectionInvoice: `${baseRoutes.ledger_and_account}/collection/invoice`,
  firmInvoice: `${baseRoutes.ledger_and_account}/firm-invoice`,
  //exam-control
  examControl: baseRoutes.examControl,
  testsAndExams: `${baseRoutes.examControl}/tests-and-exams`,
  reportCard: `${baseRoutes.examControl}/report-card`,
  admitCard: `${baseRoutes.examControl}/admit-card`,
  printAdmitCard: `${baseRoutes.examControl}/admit-card/printAdmitCard`,

  //academics
  academics: baseRoutes.academics,
  homework: `${baseRoutes.academics}/homework`,
  attendanceAndLeave: `${baseRoutes.academics}/attendance-and-leave`,
  timeTable: `${baseRoutes.academics}/timetable`,
  syllabus: `${baseRoutes.academics}/syllabus`,
  subject_details: `${baseRoutes.academics}/syllabus/subject-details`,
  academicCalendar: `${baseRoutes.academics}/academic-calendar`,

  // transportation
  transport: baseRoutes.transportation,
  transportDetail: `${baseRoutes.transportation}/transport-detail`,
  routeDetail: `${baseRoutes.transportation}/route-detail`,
  feeStructure: `${baseRoutes.transportation}/fee-structure`,
  updateTransportDetail: `${baseRoutes.transportation}/updateTransportDetail`,

  //communication
  communication: baseRoutes.communication,
  noticeBoard: `${baseRoutes.communication}/notice-board`,
  textSms: `${baseRoutes.communication}/text-sms`,
  whatsApp: `${baseRoutes.communication}/whatsapp`,

  // Human Resources
  hr: baseRoutes.hr,
  staffManagement: `${baseRoutes.hr}/staff-management`,
  joiningApproval: `${baseRoutes.hr}/joining-approval`,
  staffProfile: `${baseRoutes.hr}/staff-management/staff-profile`,
  newRegistration: `${baseRoutes.hr}/staff-management/newRegistration`,
  staffLeave: `${baseRoutes.hr}/staff-leave`,
  payroll: `${baseRoutes.hr}/payroll`,
  createPayroll: `${baseRoutes.hr}/payroll/create`,
  printSlip: `${baseRoutes.hr}/payroll/print-slip`,

  //About School
  aboutSchool: `${baseRoutes.schools}/about-school`,
  schoolProfile: `${baseRoutes.schools}/school-profile`,

  // bio-metric
  punchLog: `${baseRoutes.bioMetric}/punch-log`,
  deviceDetail: `${baseRoutes.bioMetric}/device-detail`,
  userDetail: `${baseRoutes.bioMetric}/user-detail`,

  // website-builder
  websiteBuilder: baseRoutes.websiteBuilder,
  websiteSlider: `${baseRoutes.websiteBuilder}/website-slider`,
  classPhoto: `${baseRoutes.websiteBuilder}/class-photo`,
  websitePopup: `${baseRoutes.websiteBuilder}/website-popup`,
  achievement: `${baseRoutes.websiteBuilder}/achievement`,
  schoolTopper: `${baseRoutes.websiteBuilder}/school-topper`,
  sportAchiever: `${baseRoutes.websiteBuilder}/sport-achiever`,
  chiefGuest: `${baseRoutes.websiteBuilder}/chief-guest`,
  alumniAchiever: `${baseRoutes.websiteBuilder}/alumni-achiever`,
  founder: `${baseRoutes.websiteBuilder}/founder`,
  schoolTeam: `${baseRoutes.websiteBuilder}/school-team`,
  thoughtOfTheDay: `${baseRoutes.websiteBuilder}/thought-of-the-day`,
  schoolGallery: `${baseRoutes.websiteBuilder}/school-gallery`,
  youtubeVideos: `${baseRoutes.websiteBuilder}/youtube-videos`,
  newsCalendar: `${baseRoutes.websiteBuilder}/news-calendar`,
  aboutSchoolWebsite: `${baseRoutes.websiteBuilder}/about-school`,

  //payment gateway
  orders: `${baseRoutes.paymentGateway}/orders`,
  configuration: `${baseRoutes.paymentGateway}/configuration`,
};

export default appRoutes;
