import React, { useEffect, useState } from "react";
import { ArrowRight } from "react-bootstrap-icons";
import { MenuItem, Grid, Typography, styled, Divider } from "@mui/material";

import {
  getAllRouteList,
  getTransportFeeByStopName,
} from "app/services/student-management";
import { useQuery } from "react-query";
import { CustomTextField } from "assets/styles/globalStyledComponent";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { HOUSE_MEMBERSHIP } from "app/config/administrativeConstants";
import { IndianCurrency, MenuProps } from "app/utils/helper";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {
  selectActiveSession,
  sessionMonths,
} from "app/modules/schools/selectors";
import CustomLoader from "app/components/common/CustomLoader";
import dayjs from "dayjs";

// Admission Info
export const AdmissionDetailComponent = ({ classesResponse, mainFormik }) => {
  const [selectedStream, setSelectedStream] = useState([]);
  const classes = classesResponse?.data?.list;

  return (
    <form onSubmit={mainFormik.validateOnBlur}>
      <Grid container spacing={1.5} sx={{ mt: 0.3 }}>
        <Grid item xs={12} sm={6} md={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Admission Date"
              inputFormat="DD-MM-YYYY"
              // onChange={mainFormik.handleChange}
              readOnly
              value={mainFormik.values.admissionDate}
              renderInput={(params) => (
                <CustomTextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomTextField
            fullWidth
            size="small"
            label="Admission Number"
            onChange={mainFormik.handleChange}
            value={mainFormik?.values?.admissionNumber}
            name="admissionNumber"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomTextField
            fullWidth
            size="small"
            label="Registration Number"
            id="registrationNumber"
            name="registrationNumber"
            onChange={mainFormik.handleChange}
            value={mainFormik?.values?.registrationNumber}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomTextField
            fullWidth
            size="small"
            label="SR Number"
            value={mainFormik?.values?.srNumber}
            name="srNumber"
            onChange={mainFormik.handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomTextField
            select
            fullWidth
            size="small"
            id="admissionType"
            value={mainFormik.values.admissionType}
            name="admissionType"
            onChange={mainFormik.handleChange}
            label="Admission Type"
            MenuProps={MenuProps}
            error={mainFormik.errors.admissionType}
          >
            <MenuItem value={"Regular"}>Regular</MenuItem>
            <MenuItem value={"Private"}>Private</MenuItem>
          </CustomTextField>
          {mainFormik.touched.admissionType &&
          mainFormik.errors.admissionType ? (
            <p
              style={{
                color: "red",
              }}
            >
              {mainFormik.errors.admissionType}
            </p>
          ) : null}
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <CustomTextField
            fullWidth
            size="small"
            label="Roll Number"
            id="rollNumber"
            value={mainFormik.values.rollNumber}
            onChange={mainFormik.handleChange}
            name="rollNumber"
            error={
              mainFormik.touched.rollNumber &&
              Boolean(mainFormik.errors.rollNumber)
            }
          />
          {mainFormik.touched.rollNumber &&
            Boolean(mainFormik.errors.rollNumber) && (
              <Typography color="error">
                {mainFormik.errors.rollNumber}
              </Typography>
            )}
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomTextField
            select
            SelectProps={{
              MenuProps: MenuProps,
            }}
            fullWidth
            size="small"
            id="classDocId"
            value={mainFormik?.values?.classDocId}
            name="classDocId"
            // onChange={mainFormik.handleChange}
            label="Class-Stream"
            MenuProps={MenuProps}
            error={mainFormik.errors.classDocId}
          >
            {classes?.map((res) => (
              <MenuItem
                key={res.classDocId}
                onClick={() => {
                  mainFormik.values.className = res.className;
                  mainFormik.values.stream = res.stream;
                  mainFormik.values.classDocId = res.classDocId;
                  mainFormik.values.section = "";

                  setSelectedStream(res?.sections);
                }}
                value={res.classDocId}
              >
                {res.className_stream}
              </MenuItem>
            ))}
          </CustomTextField>
          {mainFormik.touched.classDocId && mainFormik.errors.classDocId ? (
            <p
              style={{
                color: "red",
              }}
            >
              {mainFormik.errors.classDocId}
            </p>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomTextField
            select
            fullWidth
            size="small"
            id="section"
            value={mainFormik.values.section}
            name="section"
            onChange={mainFormik.handleChange}
            label="Section"
            MenuProps={MenuProps}
            error={mainFormik.errors.section}
          >
            {selectedStream?.map((res, index) => (
              <MenuItem key={index} value={res}>
                {res}
              </MenuItem>
            ))}
          </CustomTextField>
          {mainFormik.touched.section && mainFormik.errors.section ? (
            <p
              style={{
                color: "red",
              }}
            >
              {mainFormik.errors.section}
            </p>
          ) : null}
        </Grid>
      </Grid>
    </form>
  );
};

// Transport info
export const TransportationComponent = ({ setSelectedStop, mainFormik }) => {
  const selectedSession = useSelector(selectActiveSession);
  const {
    isLoading: getAllRouteListLoading,
    isFetching: getAllRouteListFetching,
    data: routeResponse,
  } = useQuery("getAllRouteList", getAllRouteList);
  const sessionMonth = useSelector(sessionMonths);
  const [selectedRoute, setSelectedRoute] = React.useState(null);
  const {
    isLoading: getTransportFeeByStopNameLoading,
    isFetching: getTransportFeeByStopNameFetching,
    data: getTransportFeeByStopNameData,
  } = useQuery({
    queryKey: [
      "getTransportFeeByStopName",
      mainFormik.values.transportInfo.to,
      selectedSession,
    ],
    queryFn: () =>
      getTransportFeeByStopName(
        mainFormik.values.transportInfo.to,
        selectedSession
      ),
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
    enabled: mainFormik.values.transportInfo.to ? true : false,
  });
  const calculateFeeAmount = () => {
    if (
      mainFormik.values.transportInfo?.using === "Yes" &&
      mainFormik.values.transportInfo.transportFeeDocId
    ) {
      const filteredDta = getTransportFeeByStopNameData?.data?.list?.find(
        (res) => res?._id === mainFormik.values.transportInfo.transportFeeDocId
      );
      const stDateMonth = dayjs(
        mainFormik.values.transportInfo?.startDate
      ).format("MMMM");
      ["or"].indexOf("or");
      const mont = 12 - sessionMonth?.indexOf(stDateMonth);

      const oneMonthAmount =
        Number(filteredDta?.feeAmount ?? 0) -
        Number(mainFormik.values.transportInfo?.recurringDiscountAmount ?? 0);
      return {
        fee: filteredDta?.feeAmount,
        recurring: mainFormik.values.transportInfo?.recurringDiscountAmount,
        finalAmount: oneMonthAmount * mont,
        month: mont,
      };
    }
  };
  useEffect(() => {
    if (mainFormik.values.transportInfo.using === "No") {
      mainFormik.setValues((prev) => ({
        ...prev,
        transportInfo: {
          using: "No",
          routeNumber: "",
          to: "",
          vehicleNumber: "",
          feeAmount: "",
          transportFeeDocId: "",
          remarks: "",
          routeName: "",
          feeCode: "",
          recurringDiscountAmount: "",
          startDate: new Date(),
        },
      }));
      setSelectedRoute(null);
    }
  }, [mainFormik.values.transportInfo.using]);

  useEffect(() => {
    if (mainFormik.values.transportInfo.to) {
      setSelectedStop(mainFormik.values.transportInfo.to);
    }
  }, []);
  useEffect(() => {
    if (
      mainFormik.values.transportInfo.routeNumber &&
      routeResponse?.data?.list &&
      routeResponse?.data?.list?.length > 0
    ) {
      const filtered = routeResponse?.data?.list?.find(
        (item) =>
          Number(item?.routeNumber) ===
          Number(mainFormik.values.transportInfo.routeNumber)
      );
      if (filtered) {
        setSelectedRoute(filtered);
      } else {
        mainFormik.values.routeNo = "";
        mainFormik.values.to = "";
        mainFormik.values.transportVehicle = "";
        mainFormik.values.remarks = "";
      }
    }
  }, [routeResponse]);
  return (
    <>
      <CustomLoader
        show={
          getTransportFeeByStopNameLoading ||
          getTransportFeeByStopNameFetching ||
          getAllRouteListLoading ||
          getAllRouteListFetching
        }
      />
      <Wrapper>
        <form>
          <Grid container spacing={1} sx={{ mt: 0.3 }} columns={14}>
            <Grid item xs={12} sm={6} md={2}>
              <CustomTextField
                select
                fullWidth
                size="small"
                id="transportInfo.using"
                value={mainFormik.values.transportInfo.using}
                name="transportInfo.using"
                onChange={mainFormik.handleChange}
                label="Using"
                MenuProps={MenuProps}

                // error={mainFormik.errors.transportInfo.using}
              >
                <MenuItem value={"Yes"}>Yes</MenuItem>
                <MenuItem value={"No"}>No</MenuItem>
              </CustomTextField>
            </Grid>
            {mainFormik.values.transportInfo.using === "Yes" && (
              <>
                <Grid item xs={12} sm={6} md={3}>
                  <CustomTextField
                    size="small"
                    select
                    labelId="demo-controlled-open-select-labe"
                    id="transportInfo.routeNumber"
                    value={mainFormik.values.transportInfo.routeNumber}
                    name="transportInfo.routeNumber"
                    label="Route No. (Route Name)"
                    fullWidth
                    SelectProps={{ MenuProps }}
                    error={
                      mainFormik.touched.transportInfo?.routeNumber &&
                      Boolean(mainFormik.errors.transportInfo?.routeNumber)
                    }
                  >
                    {routeResponse?.data?.list?.map((route) => (
                      <MenuItem
                        key={route?._id}
                        value={route?.routeNumber}
                        onClick={() => {
                          mainFormik.setValues((prev) => ({
                            ...prev,
                            transportInfo: {
                              ...prev.transportInfo,
                              routeNumber: route?.routeNumber,
                              routeName: route?.routeName,
                            },
                          }));
                          setSelectedRoute(route);
                        }}
                      >
                        {route?.routeNumber} ({route?.routeName})
                      </MenuItem>
                    ))}
                  </CustomTextField>
                  {mainFormik.touched.transportInfo?.routeNumber &&
                  mainFormik.errors.transportInfo?.routeNumber ? (
                    <Typography color="error">
                      {mainFormik.errors.transportInfo?.routeNumber}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid item xs={1} sm={6} md={3}>
                  <CustomTextField
                    size="small"
                    select
                    labelId="demo-controlled-open-select-labe"
                    id="transportInfo.vehicleNumber"
                    value={mainFormik.values.transportInfo.vehicleNumber}
                    name="transportInfo.vehicleNumber"
                    onChange={mainFormik.handleChange}
                    label="Transport Vehicle"
                    fullWidth
                    SelectProps={{ MenuProps }}
                    error={
                      mainFormik.touched.transportInfo?.vehicleNumber &&
                      Boolean(mainFormik.errors.transportInfo?.vehicleNumber)
                    }
                  >
                    {selectedRoute?.assignedVehicle?.map((vehicle) => (
                      <MenuItem
                        key={vehicle.vehicleNumber}
                        value={vehicle.vehicleNumber}
                      >
                        {vehicle.vehicleNumber}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                  {mainFormik.touched.transportInfo?.vehicleNumber &&
                  mainFormik.errors.transportInfo?.vehicleNumber ? (
                    <Typography color="error">
                      {mainFormik.errors.transportInfo?.vehicleNumber}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid item xs={1} sm={6} md={3}>
                  <CustomTextField
                    select
                    size="small"
                    id="transportInfo.to"
                    value={mainFormik.values.transportInfo.to}
                    name="transportInfo.to"
                    onChange={mainFormik.handleChange}
                    label="To"
                    fullWidth
                    SelectProps={{ MenuProps }}
                    error={
                      mainFormik.touched.transportInfo?.to &&
                      Boolean(mainFormik.errors.transportInfo?.to)
                    }
                  >
                    <MenuItem disabled value={" "}>
                      Stop Name (Pickup Point)
                    </MenuItem>
                    {selectedRoute ? null : (
                      <MenuItem value={"No result"}>No results</MenuItem>
                    )}
                    {selectedRoute?.stopList?.map((stop) => (
                      <MenuItem
                        value={stop.stopName}
                        key={stop._id}
                        onClick={() => {
                          setSelectedStop(stop.stopName);
                        }}
                      >
                        {stop.stopName}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                  {mainFormik.touched.transportInfo?.to &&
                  mainFormik.errors.transportInfo?.to ? (
                    <Typography color="error">
                      {mainFormik.errors.transportInfo?.to}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid item xs={1} sm={6} md={3}>
                  <CustomTextField
                    fullWidth
                    label="Fee Structure"
                    select
                    SelectProps={{ MenuProps }}
                    size="small"
                    value={mainFormik.values.transportInfo.transportFeeDocId}
                    name="transportInfo.transportFeeDocId"
                    error={
                      mainFormik.touched.transportInfo?.transportFeeDocId &&
                      Boolean(
                        mainFormik.errors.transportInfo?.transportFeeDocId
                      )
                    }
                  >
                    {getTransportFeeByStopNameData?.data?.list?.map(
                      (fee, i) => (
                        <MenuItem
                          value={fee._id}
                          key={i}
                          onClick={() =>
                            mainFormik.setValues((prev) => ({
                              ...prev,
                              transportInfo: {
                                ...prev.transportInfo,
                                transportFeeDocId: fee._id,
                                feeAmount: fee.feeAmount,
                                feeCode: fee?.feeCode,
                              },
                            }))
                          }
                        >
                          {fee.feeCode} - Rs.{fee.feeAmount}{" "}
                          {fee?.feeRelaxationCategory &&
                            `- ${fee?.feeRelaxationCategory}`}
                        </MenuItem>
                      )
                    )}
                  </CustomTextField>
                  {mainFormik.touched.transportInfo?.transportFeeDocId &&
                  mainFormik.errors.transportInfo?.transportFeeDocId ? (
                    <Typography color="error">
                      {mainFormik.errors.transportInfo?.transportFeeDocId}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid item xs={14}>
                  <div className="route-details">
                    <span> Route Details :- </span>
                    {selectedRoute?.stopList?.map((stop) => (
                      <>
                        <span key={stop._id}>
                          {stop.stopName} ({stop.distance} -{" "}
                          {Math.floor(stop.duration / 60) != 0
                            ? Math.floor(stop.duration / 60) + "Hrs"
                            : ""}{" "}
                          {stop.duration % 60 != 0
                            ? (stop.duration % 60) + "mins"
                            : ""}
                          )
                        </span>
                        <span key={stop._id}>
                          <ArrowRight color="royalblue" size={20} />
                        </span>
                      </>
                    ))}
                  </div>
                </Grid>
                <Grid item xs={5}>
                  <CustomTextField
                    size="small"
                    label="Remarks (If Any)"
                    id="transportInfo.remarks"
                    value={mainFormik.values.transportInfo.remarks}
                    name="transportInfo.remarks"
                    fullWidth
                    onChange={mainFormik.handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <DatePicker
                    value={mainFormik.values.transportInfo.startDate}
                    inputFormat="DD-MM-YYYY"
                    onChange={(e) =>
                      mainFormik.setValues((prev) => ({
                        ...prev,
                        transportInfo: {
                          ...prev.transportInfo,
                          startDate: e,
                        },
                      }))
                    }
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        size="small"
                        fullWidth
                        label="Start Date"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <CustomTextField
                    type="number"
                    size="small"
                    label="Recurring Discount"
                    id="transportInfo.recurringDiscountAmount"
                    value={
                      mainFormik.values.transportInfo.recurringDiscountAmount
                    }
                    name="transportInfo.recurringDiscountAmount"
                    fullWidth
                    onChange={mainFormik.handleChange}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </form>
        {/* show fee strutucre */}
        {mainFormik.values.transportInfo.transportFeeDocId && (
          <>
            <Typography sx={{ fontSize: "18px", fontWeight: 600, mt: 2 }}>
              Applied Fee Amount
            </Typography>
            <Divider sx={{ my: 1, background: "#999" }} />
            <FeeWrapper container spacing={2}>
              {/* header */}
              <Grid item xs={4}>
                <Typography className="title"> Fee Amount</Typography>
                <Typography>
                  <span className="amount">{calculateFeeAmount()?.fee}</span> x{" "}
                  <span className="month">{calculateFeeAmount()?.month}</span> ={" "}
                  <span className="totalAmount">
                    {IndianCurrency(
                      calculateFeeAmount()?.fee * calculateFeeAmount()?.month
                    )}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className="title">Recurring Discount</Typography>
                <Typography>
                  <span className="amount">
                    {calculateFeeAmount()?.recurring}
                  </span>{" "}
                  x <span className="month">{calculateFeeAmount()?.month}</span>{" "}
                  ={" "}
                  <span className="totalAmount">
                    {IndianCurrency(
                      calculateFeeAmount()?.recurring *
                        calculateFeeAmount()?.month
                    )}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className="title">Final Amount</Typography>
                <Typography>
                  <span className="amount">
                    {calculateFeeAmount()?.fee -
                      calculateFeeAmount()?.recurring}
                  </span>{" "}
                  x <span className="month">{calculateFeeAmount()?.month}</span>{" "}
                  ={" "}
                  <span className="totalAmount">
                    {IndianCurrency(
                      (calculateFeeAmount()?.fee -
                        calculateFeeAmount()?.recurring) *
                        calculateFeeAmount()?.month
                    )}
                  </span>
                </Typography>
              </Grid>
            </FeeWrapper>
          </>
        )}
      </Wrapper>
    </>
  );
};

export const HouseComponent = ({ allValues, mainFormik }) => {
  return (
    <form>
      <CustomTextField
        size="small"
        label="House Color"
        id="house.houseColor"
        value={mainFormik.values?.house?.houseColor}
        name="house.houseColor"
        sx={{ width: "200px", margin: "10px" }}
        onChange={mainFormik.handleChange}
      />
      <CustomTextField
        size="small"
        label="House Name"
        id="house.houseName"
        value={mainFormik.values?.house?.houseName}
        name="house.houseName"
        sx={{ width: "200px", margin: "10px" }}
        onChange={mainFormik.handleChange}
      />
      <CustomTextField
        size="small"
        select
        id="house.membershipRole"
        value={mainFormik.values?.house?.membershipRole}
        name="house.membershipRole"
        onChange={mainFormik.handleChange}
        label="Membership Role"
        sx={{ width: "200px", color: "black", margin: "10px" }}
        MenuProps={MenuProps}
      >
        {HOUSE_MEMBERSHIP?.map((member) => (
          <MenuItem value={member} key={member}>
            {member}
          </MenuItem>
        ))}
      </CustomTextField>
    </form>
  );
};

const Wrapper = styled("div")`
  position: relative;
  border-radius: round;
  overflow: hidden !important;
  margin: 2px;
  flex-wrap: wrap;
  .separate {
    margin: 20px 0 10px 0;
  }
  .route-details {
    margin: 7px 7px 5px 12px;
    font-size: 15px;
  }
  .route-details span {
    margin: 3px;
  }
`;
const FeeWrapper = styled(Grid)`
  .title {
    font-size: 18px;
    font-weight: bold;
    opacity: 0.7;
  }
  .amount {
    font-size: 14px;
    font-weight: 500;
  }
  .month {
    font-size: 10px;
  }
  .totalAmount {
    font-size: 14px;
    font-weight: 700;
  }
`;
