import {
  Box,
  Button,
  CircularProgress,
  Divider,
  MenuItem,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import SchoolPrintHeader from "app/components/common/SchoolPrintHeader";
import { selectActiveSession } from "app/modules/schools/selectors";
import { getTcById } from "app/services/student-management";
import dayjs from "dayjs";
import React, { useRef, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";

import TcTemplate1 from "./TcTemplate1";
import TcTemplate2 from "./TcTemplate2";
import { CustomSelectBox } from "assets/styles/globalStyledComponent";

const Preview = ({ id }) => {
  const selectedSession = useSelector(selectActiveSession);
  const [data, setData] = useState({});
  const printRef = useRef(null);
  const [color, setcolor] = useState("#e6ffe9");
  const [template, setTemplate] = useState(1);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `TransferCertificate-${data?.firstName}
                    ${data?.middleName} ${data?.lastName}`,
  });
  const { isLoading: studentDataForMigrateLoading, isFetching } = useQuery({
    queryKey: ["getTcById"],
    queryFn: () =>
      getTcById({
        session: selectedSession,
        transferCertificateDocId: id,
      }),
    onSuccess: ({ data }) => {
      toast.success(data?.message);
      setData(data?.obj);
    },

    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const handleChangeTemplate = (value) => {
    setTemplate(value);
  };
  return (
    <>
      {studentDataForMigrateLoading || isFetching ? (
        <Stack direction="row" justifyContent="center" sx={{ py: 5 }}>
          <CircularProgress />
        </Stack>
      ) : (
        <>
          <Stack
            sx={{ m: 1 }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button variant="contained" color="secondary" onClick={handlePrint}>
              Print
            </Button>
            <Stack direction={"row"} gap={2} alignItems={"center"}>
              <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                Select Template :
              </Typography>
              <CustomSelectBox
                label="Select Template"
                value={template}
                onChange={(e) => handleChangeTemplate(e.target.value)}
              >
                <MenuItem value={1}>Template 1</MenuItem>
                <MenuItem value={2}>Template 2</MenuItem>
              </CustomSelectBox>
            </Stack>

            <Stack direction="row" gap={1.5}>
              <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                Change Color :
              </Typography>
              <ColorBox
                sx={{
                  backgroundColor: "transparent",
                  transform: color === "#fff" && "scale(1.5)",
                }}
                onClick={() => setcolor("#fff")}
              />
              <ColorBox
                sx={{
                  backgroundColor: "#e6ffe9",
                  transform: color === "#e6ffe9" && "scale(1.5)",
                }}
                onClick={() => setcolor("#e6ffe9")}
              />
              <ColorBox
                sx={{
                  backgroundColor: "#fff5b9",
                  transform: color === "#fff5b9" && "scale(1.5)",
                }}
                onClick={() => setcolor("#fff5b9")}
              />
            </Stack>
          </Stack>
          {template === 1 && (
            <TcTemplate1 data={data} color={color} printRef={printRef} />
          )}
          {template === 2 && (
            <TcTemplate2 data={data} color={color} printRef={printRef} />
          )}
        </>
      )}
    </>
  );
};

export default Preview;
const ColorBox = styled(Box)`
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 1px solid gray;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
`;
const Wrapper = styled("div")`
  /* border: 1px solid #000000; */
  padding: 20px;

  .upperTypo {
    font-weight: 600;
    font-size: 12px;
    text-align: center;
  }
  .border {
    background: #ffffff;
    border: 1px solid #b6c8d6;
    border-radius: 12px;
  }
  .upperContent {
    justify-content: space-between;
    /* padding: 15px; */
  }
  .leftTypo {
    font-weight: 400;
    font-size: 12px;
    text-align: center;
  }
  .rightTypo {
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    margin-left: 4em;
    margin-right: 2em;
  }
  .bottomContent {
    font-weight: 700;
    font-size: 12px;
    text-align: center;
    margin-left: 1em;
    margin-right: 3em;
  }
`;
const StackWrapper = styled(Stack)`
  margin-top: 8px;
`;
