import {
  Box,
  DialogContent,
  Divider,
  Grid,
  styled,
  Typography,
} from "@mui/material";
import CommonDialog from "app/components/common/Dialogs/CommonDialog";
import { IndianCurrency } from "app/utils/helper";
import dayjs from "dayjs";
import React from "react";

const TransactionDetailPayroll = ({
  open = false,
  onClose = () => {},
  data = { transactionArray: [] },
}) => {
  return (
    <CommonDialog
      open={open}
      title="Transaction Detail"
      onClose={onClose}
      minWidth="900px"
    >
      <DialogContent sx={{ p: "0 !important" }}>
        <Box sx={{ mt: 1 }} />
        <InvoiceHeading>
          <Grid container spacing={1} p={1} columns={11}>
            <Grid item xs={2}>
              <Typography className="invoice_head__text">
                Transaction Date
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className="invoice_head__text">
                Transaction Number
              </Typography>
            </Grid>

            <Grid item xs={2}>
              <Typography className="invoice_head__text">Amount</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className="invoice_head__text">Mode</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className="invoice_head__text">
                Ref. Number
              </Typography>
            </Grid>
          </Grid>
        </InvoiceHeading>
        {data?.transactionArray?.map((item, i) => (
          <Box key={i}>
            <Grid
              container
              spacing={1}
              p={1}
              columns={11}
              display={"flex"}
              alignItems={"center"}
            >
              <Grid item xs={2}>
                <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                  {dayjs(item?.transactionDate).format("DD MMM, YYYY")}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                  {item?.transactionNumber}
                </Typography>
              </Grid>

              <Grid item xs={2}>
                <Typography sx={{ fontSize: "13px", fontWeight: 600 }}>
                  {IndianCurrency(item?.transactionAmount)}
                </Typography>
              </Grid>

              <Grid item xs={2}>
                <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                  {item?.paymentMode}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                  {item?.referenceNumber}
                </Typography>
              </Grid>
            </Grid>
            <Divider
              sx={{ background: (theme) => theme.palette.primary.main }}
            />
          </Box>
        ))}
      </DialogContent>
    </CommonDialog>
  );
};

export default TransactionDetailPayroll;
const InvoiceHeading = styled(Box)`
  background-color: rgba(246, 248, 252, 1);
  .invoice_head__text {
    color: #0c2f49;
    font-size: 14px;
    font-weight: 500;
  }
`;
