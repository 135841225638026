import React from "react";
import CommonDialog from "../../../CommonDialog";
import CustomizeBookContentForGroup from "../components/CustomizeBookContentForGroup";

const AddBookGroupDialog = ({
  open = false,
  onClose = () => {},
  refetch = () => {},
  classDocId = "",
}) => {
  const handleClose = () => {
    onClose();
  };
  return (
    <>
      <CommonDialog
        open={open}
        onClose={handleClose}
        minWidth="700px"
        title="Add Book Group"
      >
        <CustomizeBookContentForGroup
          classDocId={classDocId}
          refetch={refetch}
          dialogClose={handleClose}
        />
      </CommonDialog>
    </>
  );
};

export default AddBookGroupDialog;
