import { schoolTokenAxios } from "../axios";

const SCHOOL_BASE = process.env.REACT_APP_SCHOOL_BASE;

let ledger_url = `${SCHOOL_BASE}/ledger`;

export const getAllCollectionHeadAndSubHead = (params) => {
  return schoolTokenAxios.get(`${ledger_url}/getAllCollectionHeadAndSubHead`, {
    params: params,
  });
};

export const getAllExpenseHeadAndSubHead = (params) => {
  return schoolTokenAxios.get(`${ledger_url}/getAllExpenseHeadAndSubHead`, {
    params: params,
  });
};

export const getBookGroup = (params) => {
  return schoolTokenAxios.get(`${ledger_url}/getBookGroup`, {
    params: params,
  });
};

export const getAggregatedBookGroup = (params) => {
  return schoolTokenAxios.get(`${ledger_url}/getAggregatedBookGroup`, {
    params: params,
  });
};

export const updateBookGroupName = (body) => {
  return schoolTokenAxios.put(`${ledger_url}/updateBookGroupName`, body);
};

export const deleteBookFromBookGroup = (body) => {
  return schoolTokenAxios.put(`${ledger_url}/deleteBookFromBookGroup`, body);
};
export const deleteBookGroup = (id) => {
  return schoolTokenAxios.delete(`${ledger_url}/deleteBookGroup/${id}`);
};

export const addBookInBookGroup = (body) => {
  return schoolTokenAxios.put(`${ledger_url}/addBookInBookGroup`, body);
};

export const addBookGroup = (body) => {
  return schoolTokenAxios.post(`${ledger_url}/addBookGroup`, body);
};
