import { Box, Stack, Typography } from "@mui/material";
import { selectSelectedSchool } from "app/modules/schools/selectors";
import React from "react";
import { useSelector } from "react-redux";
const TruncatedTypography = ({ label, value, maxLength = 30 }) => {
  const trimmedValue =
    value?.length > maxLength ? value?.substring(0, maxLength) + "..." : value;

  return (
    <Stack alignItems={"center"} flex={1} display={"flex"}>
      <Typography
        sx={{
          fontWeight: "500",
          fontSize: "13px",
        }}
      >
        {label}
      </Typography>
      <Typography sx={{ fontWeight: "700" }}>{trimmedValue}</Typography>
    </Stack>
  );
};

const SchoolPrintHeader3 = () => {
  const selectedSchool = useSelector(selectSelectedSchool);

  // Function to calculate font size based on text length
  const calculateFontSize = (text, baseFontSize, maxLength) => {
    return text?.length > maxLength
      ? `${baseFontSize - (text?.length - maxLength) * 0.5}px`
      : `${baseFontSize}px`;
  };
  const calculateFontSizeSchoolName = (text, baseFontSize, maxLength) => {
    return text?.length > maxLength
      ? `${baseFontSize - (text?.length - maxLength) * 0.1}px`
      : `${baseFontSize}px`;
  };

  const schoolNameFontSize = calculateFontSizeSchoolName(
    selectedSchool?.schoolName ?? "--",
    32,
    20
  ); // Adjust baseFontSize and maxLength as needed
  const addressFontSize = calculateFontSize(
    `${selectedSchool?.schoolAddress?.address}, ${selectedSchool?.schoolAddress?.dist}, ${selectedSchool?.schoolAddress?.state}, ${selectedSchool?.schoolAddress?.pinCode}`,
    20,
    90
  );

  return (
    <Box
      sx={{
        height: "100%",
        width: "90%",
        alignItems: "center",
        justifyContent: "center",
        alignSelf: "center",
        marginTop: "16%",
      }}
    >
      <Stack direction="row" alignItems="center">
        <Box
          sx={{
            ml: 1,
            height: "8%",
            width: "8%",
          }}
        >
          <img
            src={selectedSchool?.schoolLogo}
            style={{
              height: "100%",
              width: "100%",
              objectFit: "contain",
            }}
            alt="school-logo"
          />
        </Box>
        <Stack sx={{ width: "calc(100% - 96px)" }} justifyContent="center">
          <Box>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: schoolNameFontSize,
                textAlign: "center",
                lineHeight: `calc(${schoolNameFontSize} + 4px)`,
              }}
            >
              {selectedSchool?.schoolName}
            </Typography>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: addressFontSize,
                textAlign: "center",
              }}
            >
              {selectedSchool?.schoolAddress?.address}
              {selectedSchool?.schoolAddress?.dist},{" "}
              {selectedSchool?.schoolAddress?.state},{" "}
              {selectedSchool?.schoolAddress?.pinCode}
              <br />
            </Typography>
          </Box>
        </Stack>
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ px: 2 }}
      >
        <TruncatedTypography
          label={"Affiliation No."}
          maxLength={20}
          value={selectedSchool?.affiliationNumber}
        />

        <TruncatedTypography
          label={"Website"}
          maxLength={40}
          value={selectedSchool?.website}
        />

        <TruncatedTypography
          label={"Contact No"}
          maxLength={20}
          value={selectedSchool?.mobileNumber}
        />
        <TruncatedTypography
          label="Email"
          maxLength={40}
          value={selectedSchool?.email}
        />
      </Stack>
    </Box>
  );
};

export default SchoolPrintHeader3;
