import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  Grid,
  Stack,
  useMediaQuery,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import "react-notifications/lib/notifications.css";
import {
  AdmissionDetailComponent,
  TransportationComponent,
  HouseComponent,
} from "../../components/PromotionAndMigrationComponents/index";
import styled from "styled-components";
import { useMutation, useQuery } from "react-query";
import {
  getClassDropdown,
  putPromoteStudent,
} from "app/services/student-management";
import {
  selectedMediumSelector,
  selectActiveSession,
  selectSelectedSchool,
} from "app/modules/schools/selectors";
import { selectSelectedSchoolId } from "app/modules/schools/selectors";
import { useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import PreviewForPromotion, {
  getNewSessionValues,
} from "../../components/PromotionAndMigrationComponents/PreviewForPromotion";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment/moment";

import { PencilSquare } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import CustomLoader from "app/components/common/CustomLoader";
import LabelTextComponent from "app/components/common/LabelTextComponent";
import { getStudentProfile } from "app/services/schoolService/studentProfile";
import ShowFeeStructure from "../CreateStudent/components/ShowFeeStructure";
import DialogHeader from "app/components/common/DialogHeader";
import FeeStructureList from "./FeeStructureList";
import {
  getAppliedFeeStructure,
  getFeeStructureForStudentRegistration,
} from "app/services/schoolService/newFee.service";
import EditFeeRelaxationDialog from "../CreateStudent/components/EditFeeRelaxationDialog";
import appRoutes from "app/config/routes";
import { LoadingButton } from "@mui/lab";
import dayjs from "dayjs";

const validationSchema = Yup.object().shape({
  classDocId: Yup.string().required("Class Name is required"),
  section: Yup.string().required("Section is required"),
  transportInfo: Yup.object().shape({
    using: Yup.string().required("Select whether student is using transport"),

    routeNumber: Yup.string().when("transportInfo.using", {
      is: "Yes",
      then: Yup.string().required("Route Number is required"),
    }),
    to: Yup.string().when("transportInfo.using", {
      is: "Yes",
      then: Yup.string().required("To required"),
    }),
    vehicleNumber: Yup.string().when("transportInfo.using", {
      is: "Yes",
      then: Yup.string().required("Vehicle number is required"),
    }),
    transportFeeDocId: Yup.string().when("transportInfo.using", {
      is: "Yes",
      then: Yup.string().required("Transport fee  required"),
    }),
  }),
});

const PromoteModal = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const selectedSession = useSelector(selectActiveSession);
  const selectedMedium = useSelector(selectedMediumSelector);
  const [showPreview, setShowPreview] = useState(false);
  const [expanded, setExpanded] = useState("all");
  const [drawer, setDrawer] = useState(false);
  const [selectedStop, setSelectedStop] = useState("");
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [state, setState] = useState({
    feeRelaxationCategoryDialog: false,
    feeRelaxationCategory: "General",
    certificateName: "",
    certificateUrl: "",
    staffWardSchoolName: "",
    employeeId: "",
    empoyeeName: "",
    profilePic: "",
    siblingDetails: [
      {
        schoolDocId: "",
        studentDocId: "",
        classDocId: "",
      },
    ],
  });
  const [feeData, setFeeData] = useState({
    list: [],
  });
  const newSession = getNewSessionValues(selectedSession);

  const { studentDocId } = useParams();
  const selectedSchoolId = useSelector(selectSelectedSchool);
  const schoolDocId = useSelector(selectSelectedSchoolId);
  const [classId, setClassId] = useState("");
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setShowPreview(false);
  };

  const mainFormik = useFormik({
    initialValues: {
      session: "",
      selectedNewSession: newSession,
      admissionDate: "",
      admissionType: "Regular",
      studentAdmissionType: "oldStudent",
      classDocId: "",
      section: "",
      rollNumber: "",
      transportInfo: {
        using: "No",
        routeNumber: "",
        to: "",
        vehicleNumber: "",
        transportFeeDocId: "",
        feeAmount: "",
        remarks: "",
        routeName: "",
        feeCode: "",
      },
      house: {
        houseName: "",
        houseColor: "",
        membershipRole: "",
      },
    },

    validationSchema: validationSchema,
    onSubmit: (values) => {
      setDrawer(false);
    },
  });

  const { isLoading: studentProfileLoading, data: studentProfileData } =
    useQuery({
      queryKey: ["getProfileByStudentDocId", studentDocId],
      queryFn: () =>
        getStudentProfile({
          studentDocId: studentDocId,
          session: selectedSession,
        }),
      onSuccess: (success) => {
        mainFormik.setValues((prev) => ({
          ...prev,
          session: success?.data?.obj?.academicInfo?.selectedNewSession,
          admissionDate: new Date(),
          admissionType: success?.data?.obj?.studentProfile?.admissionType,
          studentAdmissionType: "oldStudent",
          rollNumber: success?.data?.obj?.academicInfo?.rollNumber,
          house: {
            houseName: success?.data?.obj?.academicInfo?.house?.houseName,
            houseColor: success?.data?.obj?.academicInfo?.house?.houseColor,
            membershipRole:
              success?.data?.obj?.academicInfo?.house?.membershipRole,
          },
        }));
      },

      onError: (err) => {
        toast.error(err?.response?.data?.message);
      },
    });
  const {
    isLoading: getAppliedFeeStructureLoading,
    data: getAppliedFeeStructureData,
  } = useQuery({
    queryKey: ["getAppliedFeeStructure", mainFormik.values?.session],
    queryFn: () =>
      getAppliedFeeStructure({
        studentDocId: studentDocId,
        session: mainFormik.values?.session,
      }),
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: mainFormik.values?.session ? true : false,
  });
  const { data: classesResponse } = useQuery({
    queryKey: ["getClassDropdown", schoolDocId, selectedMedium],
    queryFn: () => getClassDropdown(schoolDocId, selectedMedium),
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });
  const {
    isLoading: getAllFeeStructureLoading,
    isFetching: getAllFeeStructureFetching,
  } = useQuery({
    queryKey: [
      "getAllFeeStructure",
      mainFormik?.values?.selectedNewSession,
      mainFormik.values.section,
      state.feeRelaxationCategory,
      mainFormik.values.admissionType,
    ],
    queryFn: () =>
      getFeeStructureForStudentRegistration({
        medium: selectedMedium,
        session: mainFormik?.values?.selectedNewSession,
        classDocId: mainFormik.values.classDocId,
        section: mainFormik.values.section,
        feeRelaxationCategory: state?.feeRelaxationCategory,
        studentAdmissionType: mainFormik.values.studentAdmissionType,
      }),
    onSuccess: (success) => {
      setFeeData(success?.data);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
    enabled: mainFormik.values.section ? true : false,
  });

  const { mutate, isLoading: promoting } = useMutation(putPromoteStudent, {
    onSuccess: (data) => {
      toast.success(data?.data?.message);
      handleCloseConfirmation();
      setShowPreview(false);
      navigate(`/${appRoutes.promotionAndMigration}`);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  const submitAdmission = (allValues) => {
    let category = {};
    category.feeRelaxationCategory = state.feeRelaxationCategory;
    if (state.feeRelaxationCategory === "Siblings") {
      const data = [];
      state.siblingDetails?.forEach((item) => {
        if (item?.studentDocId) {
          data.push({
            schoolDocId: selectedSchoolId?._id,
            studentDocId: item?.studentDocId,
          });
        }
      });
      category.siblingDetails = data;
    }
    if (state.feeRelaxationCategory === "Staff_Ward") {
      category.staffWardDetail = {
        schoolDocId: selectedSchoolId?._id,
        staffDocId: state.employeeId,
      };
    }
    if (
      !["General", "Siblings", "Staff_Ward"].includes(
        state.feeRelaxationCategory
      )
    ) {
      category.otherFeeRelaxationCategoryDetail = {
        certificateName: state.certificateName,
        certificateLink: state.certificateUrl,
      };
    }
    const finalObject = {
      studentDocId,
      currentSession: selectedSession,
      promotionDetail: {
        session: allValues?.selectedNewSession,
        admissionType: allValues?.admissionType,
        admissionDate: allValues?.admissionDate,
        ...category,
        rollNumber: allValues?.rollNumber,
        classDocId: allValues?.classDocId,
        section: allValues?.section,
        transportInfo: {
          using: allValues?.transportInfo?.using,
          routeNumber: allValues.transportInfo?.routeNumber,
          feeAmount:
            allValues?.transportInfo?.using === "Yes"
              ? allValues?.transportInfo.feeAmount
              : null,
          to: allValues?.transportInfo?.to,
          vehicleNumber: allValues?.transportInfo?.vehicleNumber,
          remarks: allValues?.transportInfo?.remarks,
          transportFeeDocId: allValues?.transportInfo?.transportFeeDocId,
        },
        house: {
          ...allValues?.house,
        },
        feeStructureList: feeData?.list?.map((item) => item?._id),
      },
    };
    mutate(finalObject);
  };

  return (
    <>
      <CustomLoader
        show={
          studentProfileLoading ||
          getAllFeeStructureLoading ||
          getAllFeeStructureFetching ||
          getAppliedFeeStructureLoading
        }
      />
      <EditFeeRelaxationDialog
        getClassDropdownData={classesResponse}
        state={state}
        setState={setState}
      />

      {/* Confirmation Dialog  */}
      <Dialog open={openConfirmation} onClose={() => handleCloseConfirmation()}>
        <DialogTitle sx={{ p: "0 !important" }}>
          <DialogHeader
            handleClose={() => handleCloseConfirmation()}
            title="Promotion Confirmation"
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mt: 2 }}>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                color: "#0C2F49",
              }}
            >
              Are you sure you want to promote the student?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            color="error"
            size="medium"
            onClick={() => handleCloseConfirmation()}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            loading={promoting}
            variant="contained"
            color="success"
            size="medium"
            onClick={() => submitAdmission(mainFormik.values)}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {/* form drawer */}
      <Drawer
        open={drawer}
        onClose={() => setDrawer(false)}
        PaperProps={{
          sx: { maxWidth: "60vw", p: 2 },
        }}
        anchor="right"
      >
        <DrawerWrapper>
          <form onSubmit={mainFormik.handleSubmit}>
            <DialogHeader
              title={`Student Promotion Form : ${selectedSession}`}
              handleClose={() => setDrawer(false)}
            />

            <div className="accordian-wrapper">
              {/* Admission details */}
              <Accordion
                expanded={expanded === "admission_info" || expanded === "all"}
                onChange={handleChange("admission_info")}
                className="customaccordian_style"
              >
                <AccordionSummary
                  expandIcon={
                    <ArrowForwardIosIcon
                      sx={{
                        transform:
                          expanded === "admission_info" || expanded === "all"
                            ? "rotate(270deg) !important"
                            : "rotate(0deg) !important",
                      }}
                    />
                  }
                >
                  <Typography className="accordian_title">
                    Admission Detail
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "1rem",
                        flexWrap: isMobile && "wrap",
                      }}
                    >
                      {mainFormik.touched.admissionDate &&
                      mainFormik.errors.admissionDate ? (
                        <p
                          style={{
                            color: "red",
                          }}
                        >
                          {mainFormik.errors.admissionDate}
                        </p>
                      ) : null}
                      {mainFormik.touched.admissionType &&
                      mainFormik.errors.admissionType ? (
                        <p
                          style={{
                            color: "red",
                          }}
                        >
                          {mainFormik.errors.admissionType}
                        </p>
                      ) : null}

                      {mainFormik.touched.classDocId &&
                      mainFormik.errors.classDocId ? (
                        <p
                          style={{
                            color: "red",
                          }}
                        >
                          {mainFormik.errors.classDocId}
                        </p>
                      ) : null}
                      {mainFormik.touched.section &&
                      mainFormik.errors.section ? (
                        <p
                          style={{
                            color: "red",
                          }}
                        >
                          {mainFormik.errors.section}
                        </p>
                      ) : null}
                    </div>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="accordiantDetail_border">
                  <Typography component={"span"}>
                    <AdmissionDetailComponent
                      mainFormik={mainFormik}
                      state={state}
                      setState={setState}
                      setClassId={setClassId}
                      studentData={
                        studentProfileData?.data?.obj?.studentProfile
                      }
                      classesResponse={classesResponse}
                    />
                  </Typography>
                </AccordionDetails>
              </Accordion>
              {/* Transport Info */}
              <Accordion
                expanded={expanded === "transport_info" || expanded === "all"}
                onChange={handleChange("transport_info")}
                className="customaccordian_style"
              >
                <AccordionSummary
                  expandIcon={
                    <ArrowForwardIosIcon
                      sx={{
                        transform:
                          expanded === "transport_info" || expanded === "all"
                            ? "rotate(270deg) !important"
                            : "rotate(0deg) !important",
                      }}
                    />
                  }
                >
                  <Typography className="accordian_title">
                    Transportation
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="accordiantDetail_border">
                  <Typography component={"span"}>
                    <TransportationComponent
                      selectedMedium={selectedMedium}
                      setSelectedStop={setSelectedStop}
                      newSession={mainFormik?.values?.selectedNewSession}
                      mainFormik={mainFormik}
                      // allValues={mainData}
                      // setAllValues={setMainData}
                    />
                  </Typography>
                </AccordionDetails>
              </Accordion>

              {/* House Info */}
              <Accordion
                expanded={expanded === "house_info" || expanded === "all"}
                onChange={handleChange("house_info")}
                className="customaccordian_style"
              >
                <AccordionSummary
                  expandIcon={
                    <ArrowForwardIosIcon
                      sx={{
                        transform:
                          expanded === "house_info" || expanded === "all"
                            ? "rotate(270deg) !important"
                            : "rotate(0deg) !important",
                      }}
                    />
                  }
                >
                  <Typography className="accordian_title">
                    House Info
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="accordiantDetail_border">
                  <HouseComponent mainFormik={mainFormik} />
                </AccordionDetails>
              </Accordion>
              {/* Fee Info */}
              <Accordion
                expanded={expanded === "fee_info" || expanded === "all"}
                onChange={handleChange("fee_info")}
                className="customaccordian_style"
              >
                <AccordionSummary
                  expandIcon={
                    <ArrowForwardIosIcon
                      sx={{
                        transform:
                          expanded === "fee_info" || expanded === "all"
                            ? "rotate(270deg) !important"
                            : "rotate(0deg) !important",
                      }}
                    />
                  }
                >
                  <Typography className="accordian_title">
                    Fee Structure
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="accordiantDetail_border">
                  <ShowFeeStructure
                    feeData={feeData}
                    showTransportFee={
                      mainFormik.values?.transportInfo?.using === "Yes"
                        ? true
                        : false
                    }
                    transportData={mainFormik.values?.transportInfo}
                    transportFeeData={[
                      {
                        _id: mainFormik.values?.transportInfo
                          ?.transportFeeDocId,
                        feeAmount: mainFormik.values?.transportInfo?.feeAmount,
                      },
                    ]}
                  />
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="preview-btn-div">
              <button className="preview-button" type="submit">
                Preview
              </button>
            </div>
          </form>
        </DrawerWrapper>
      </Drawer>
      {/* preview drawer */}
      <Drawer
        open={showPreview}
        anchor="right"
        onClose={handleClose}
        PaperProps={{
          sx: { maxWidth: "70vw" },
        }}
      >
        <PreviewForPromotion
          allValues={mainFormik.values}
          setShowPreview={setShowPreview}
          newSession={mainFormik?.values?.selectedNewSession}
          studentData={studentProfileData?.data?.obj?.studentProfile}
          state={state}
          feeData={feeData}
          classList={classesResponse?.data?.list}
        />
      </Drawer>
      <Wrapper>
        <div className="student_detail__container">
          <img
            className="student_detail__img"
            alt="student-img"
            src={
              studentProfileData?.data?.obj?.studentProfile?.profilePic
                ? studentProfileData?.data?.obj?.studentProfile?.profilePic
                : "/image-placeholder.jpeg"
            }
          />

          {/* Personal Info  */}
          <Typography
            fontSize={18}
            color="primary"
            fontWeight={600}
            sx={{ mb: 2 }}
          >
            Personal Information
          </Typography>
          <div className="student_personal__detail">
            <Stack
              direction="row"
              sx={{ mb: 1 }}
              gap={isMobile && 7}
              flexWrap={isMobile && "wrap"}
            >
              <LabelTextComponent
                label="Full Name"
                text={`${studentProfileData?.data?.obj?.studentProfile?.firstName} ${studentProfileData?.data?.obj?.studentProfile?.middleName} ${studentProfileData?.data?.obj?.studentProfile?.lastName}`}
              />
              <LabelTextComponent
                label="Gender"
                text={studentProfileData?.data?.obj?.studentProfile?.gender}
              />
              <LabelTextComponent
                label="Addhar Number"
                text={studentProfileData?.data?.obj?.studentProfile?.AADHAR}
              />
              <LabelTextComponent
                label="Date Of Birth"
                text={moment(
                  studentProfileData?.data?.obj?.studentProfile?.DOB
                ).format("DD-MM-YYYY")}
              />
              <LabelTextComponent
                label="Email ID"
                text={studentProfileData?.data?.obj?.studentProfile?.email}
              />
              <LabelTextComponent
                label="Mobile Number"
                text={
                  studentProfileData?.data?.obj?.studentProfile?.mobileNumber
                }
              />
            </Stack>

            {/* Student Identity */}
            <Stack
              direction="row"
              sx={{ mb: 1 }}
              gap={isMobile && 7}
              flexWrap={isMobile && "wrap"}
            >
              <LabelTextComponent
                label="Registration Number"
                text={`${studentProfileData?.data?.obj?.studentProfile?.registrationNumber} `}
              />
              <LabelTextComponent
                label="Admission Number"
                text={
                  studentProfileData?.data?.obj?.studentProfile?.admissionNumber
                }
              />
              <LabelTextComponent
                label="SR Number"
                text={studentProfileData?.data?.obj?.studentProfile?.srNumber}
              />
              <LabelTextComponent
                label="Student House"
                text={`${studentProfileData?.data?.obj?.house?.membershipRole} (${studentProfileData?.data?.obj?.house?.houseName} : ${studentProfileData?.data?.obj?.house?.houseColor})`}
              />
              <LabelTextComponent
                label="PEN Number"
                text={
                  studentProfileData?.data?.obj?.studentProfile
                    ?.penNumber_center
                }
              />
            </Stack>
            <Stack
              direction="row"
              mt={isMobile && 2}
              gap={isMobile && 5}
              flexWrap={isMobile && "wrap"}
            >
              <LabelTextComponent
                label="Community"
                text={studentProfileData?.data?.obj?.studentProfile?.community}
              />
              <LabelTextComponent
                label="Category"
                text={studentProfileData?.data?.obj?.studentProfile?.category}
              />
              <LabelTextComponent
                label="Special Category"
                text={
                  studentProfileData?.data?.obj?.studentProfile?.specialCategory
                }
              />
              <LabelTextComponent
                label="Disability"
                text={studentProfileData?.data?.obj?.studentProfile?.disability}
              />
              <LabelTextComponent
                label="Blood Group"
                text={studentProfileData?.data?.obj?.studentProfile?.bloodGroup}
              />
              <Box sx={{ flex: 1 }} />
            </Stack>
          </div>

          {/* Address Info  */}
          <Typography
            fontSize={18}
            color="primary"
            fontWeight={600}
            sx={{ mb: 2, my: 2 }}
          >
            Address Information
          </Typography>
          <div className="student_personal__detail">
            <Stack
              direction="row"
              sx={{ mb: 1 }}
              gap={isMobile && 7}
              flexWrap={isMobile && "wrap"}
            >
              <LabelTextComponent
                label="Current Address"
                text={`${studentProfileData?.data?.obj?.studentProfile?.currentAddress?.address} ${studentProfileData?.data?.obj?.studentProfile?.currentAddress?.dist}, ${studentProfileData?.data?.obj?.studentProfile?.currentAddress?.state}, ${studentProfileData?.data?.obj?.studentProfile?.currentAddress?.pinCode}`}
              />
            </Stack>
            <Stack
              direction="row"
              mt={isMobile && 2}
              gap={isMobile && 5}
              flexWrap={isMobile && "wrap"}
            >
              <LabelTextComponent
                label="Permanent Address"
                text={`${studentProfileData?.data?.obj?.studentProfile?.permanentAddress?.address} ${studentProfileData?.data?.obj?.studentProfile?.permanentAddress?.dist}, ${studentProfileData?.data?.obj?.studentProfile?.permanentAddress?.state}, ${studentProfileData?.data?.obj?.studentProfile?.permanentAddress?.pinCode}`}
              />

              <Box sx={{ flex: 1 }} />
            </Stack>
          </div>
          <Typography
            fontSize={18}
            color="primary"
            fontWeight={600}
            sx={{ my: 2 }}
          >
            Admission Detail
          </Typography>
          <Stack direction={isMobile ? "column" : "row"} gap={2}>
            <div className="student_admission__detail">
              <Stack direction="row" sx={{ px: 2, py: 0.5 }}>
                <Typography fontSize={16} color="primary">
                  Current Session -{" "}
                  <span style={{ fontWeight: 600 }}>
                    {studentProfileData?.data?.obj?.academicInfo?.session}
                  </span>
                </Typography>
              </Stack>
              <div className="divider" />
              <Box sx={{ px: 1.5 }}>
                <Stack direction="row" sx={{ py: 0.5 }} gap={isMobile && 2}>
                  <LabelTextComponent
                    label="Admission Date"
                    text={moment(
                      studentProfileData?.data?.obj?.academicInfo?.admissionDate
                    ).format("DD-MM-YYYY")}
                  />
                  <LabelTextComponent
                    label="Admission Type"
                    text={
                      studentProfileData?.data?.obj?.studentProfile
                        ?.admissionType
                    }
                  />
                </Stack>
                <Stack direction="row" sx={{ pb: 0.5 }} gap={isMobile && 2}>
                  <LabelTextComponent
                    label="Roll Number"
                    text={
                      studentProfileData?.data?.obj?.academicInfo?.rollNumber
                    }
                  />
                  <LabelTextComponent
                    label="Class-Stream"
                    text={`${studentProfileData?.data?.obj?.academicInfo?.className}-${studentProfileData?.data?.obj?.academicInfo?.stream}`}
                  />
                  <LabelTextComponent
                    label="Section"
                    text={studentProfileData?.data?.obj?.academicInfo?.section}
                  />
                </Stack>
                <div className="divider" sx={{ my: 1 }} />
                <Typography
                  fontSize={16}
                  color="primary"
                  fontWeight={600}
                  sx={{ my: 0.5, marginTop: isMobile && 2 }}
                >
                  Transport Detail
                </Typography>
                <div className="divider" />
                <Stack
                  direction="row"
                  sx={{ pb: 0.5, marginTop: isMobile && 2 }}
                  gap={isMobile && 2}
                >
                  <LabelTextComponent
                    label="Using Transport"
                    text={studentProfileData?.data?.obj?.transportInfo?.using}
                  />
                  <LabelTextComponent
                    label="Route No. (Route Name)"
                    text={
                      studentProfileData?.data?.obj?.transportInfo?.routeNumber
                    }
                  />
                  <LabelTextComponent
                    label="To"
                    text={studentProfileData?.data?.obj?.transportInfo?.to}
                  />
                </Stack>
                <Stack direction="row" sx={{ py: 0.5 }} gap={isMobile && 2}>
                  <LabelTextComponent
                    label="Transport Vehicle"
                    text={`${
                      studentProfileData?.data?.obj?.transportInfo
                        ?.vehicleNumber
                    } (${
                      studentProfileData?.data?.obj?.transportInfo
                        ?.vehicleNickName ?? "-"
                    })`}
                  />

                  <LabelTextComponent
                    label="Remarks"
                    text={studentProfileData?.data?.obj?.transportInfo?.remarks}
                  />
                  <LabelTextComponent
                    label="Fee Amount"
                    text={
                      studentProfileData?.data?.obj?.transportInfo?.feeAmount
                    }
                  />
                  <Box sx={{ flex: 1 }} />
                </Stack>
                <div className="divider" sx={{ my: 1 }} />
                <Typography
                  fontSize={16}
                  color="primary"
                  fontWeight={600}
                  sx={{ my: 0.5, marginTop: isMobile && 2 }}
                >
                  Fee Structure :
                </Typography>
                <div className="divider" />
                <FeeStructureList feeData={getAppliedFeeStructureData?.data} />
              </Box>
            </div>
            <div className="student_admission__detail">
              <Stack
                direction="row"
                sx={{ px: 2, py: 0.5 }}
                justifyContent="space-between"
              >
                <Typography fontSize={16} color="primary">
                  Next Session -{" "}
                  <span style={{ fontWeight: 600 }}>
                    {mainFormik?.values?.selectedNewSession}
                  </span>
                </Typography>
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  className="edit_icon__container"
                  onClick={() => setDrawer(true)}
                >
                  <PencilSquare
                    size={14}
                    style={{
                      color: "#0B4977",
                    }}
                  />
                </Stack>
              </Stack>
              <div className="divider" />
              <Box sx={{ px: 1.5 }}>
                <Stack direction="row" sx={{ py: 0.5 }} gap={isMobile && 2}>
                  <LabelTextComponent
                    label="Admission Date"
                    text={dayjs(
                      new Date(mainFormik.values?.admissionDate)
                    ).format("DD-MM-YYYY")}
                  />
                  <LabelTextComponent
                    label="Admission Type"
                    text={mainFormik.values?.admissionType}
                  />
                </Stack>
                <Stack direction="row" sx={{ pb: 0.5 }} gap={isMobile && 2}>
                  <LabelTextComponent
                    label="Roll Number"
                    text={mainFormik.values?.rollNumber}
                  />
                  <LabelTextComponent
                    label="Class-Stream"
                    text={`${
                      classesResponse?.data?.list?.find(
                        (item) =>
                          item?.classDocId === mainFormik.values?.classDocId
                      )?.className_stream
                    }`}
                  />
                  <LabelTextComponent
                    label="Section"
                    text={mainFormik.values?.section}
                  />
                </Stack>
                <div className="divider" sx={{ my: 1 }} />
                <Typography
                  fontSize={16}
                  color="primary"
                  fontWeight={600}
                  sx={{ my: 0.5, marginTop: isMobile && 2 }}
                >
                  Transport Detail
                </Typography>
                <div className="divider" />
                <Stack
                  direction="row"
                  sx={{ pb: 0.5, marginTop: isMobile && 2 }}
                  gap={isMobile && 2}
                >
                  <LabelTextComponent
                    label="Using Transport"
                    text={mainFormik.values?.transportInfo?.using}
                  />
                  <LabelTextComponent
                    label="Route No. (Route Name)"
                    text={mainFormik.values?.transportInfo?.routeNumber}
                  />
                  <LabelTextComponent
                    label="To"
                    text={mainFormik.values?.transportInfo?.to}
                  />
                </Stack>
                <Stack direction="row" sx={{ py: 0.5 }} gap={isMobile && 2}>
                  <LabelTextComponent
                    label="Transport Vehicle"
                    text={mainFormik.values?.transportInfo?.vehicleNumber}
                  />
                  <LabelTextComponent
                    label="Remarks"
                    text={mainFormik.values?.transportInfo?.remarks}
                  />
                  <Box sx={{ flex: 1 }} />
                </Stack>
                <div className="divider" sx={{ my: 1 }} />
                <Typography
                  fontSize={16}
                  color="primary"
                  fontWeight={600}
                  sx={{ my: 0.5, marginTop: isMobile && 2 }}
                >
                  Fee Structure
                </Typography>

                <div className="divider" />
                <FeeStructureList
                  feeData={feeData}
                  showTransportFee={
                    mainFormik.values?.transportInfo.using === "Yes"
                      ? true
                      : false
                  }
                  transportData={mainFormik.values?.transportInfo}
                  transportFeeData={mainFormik.values?.transportInfo}
                />
              </Box>
            </div>
          </Stack>
        </div>
        <Stack direction="row" justifyContent="flex-end" sx={{ my: 2 }} gap={2}>
          <Button
            variant="contained"
            size="large"
            onClick={() => setOpenConfirmation(true)}
            disabled={mainFormik.isValid ? false : true}
          >
            Proceed
          </Button>
          {/* <Button
            variant="contained"
            size="large"
            onClick={() => setShowPreview(true)}
            disabled={mainFormik.isValid ? false : true}
          >
            View
          </Button> */}
        </Stack>
      </Wrapper>
    </>
  );
};

export default PromoteModal;
const DrawerWrapper = styled.div`
  .customaccordian_style {
    box-shadow: none;
    border-radius: 15px !important;
    border: 1.5px solid #b6c8d6;
    margin-top: 10px;
  }
  .accordiantDetail_border {
    border-top: 1.5px solid #b6c8d6;
  }
  .MuiAccordion-root::before {
    opacity: 0 !important;
  }
  .accordian_title {
    font-size: 16px;
    font-weight: 500;
  }
  .accordian-wrapper {
    margin-bottom: 20px;
  }
  .preview-button {
    /* margin: 5px 20px 20px 20px; */
    width: 136.03px;
    height: 50px;
    color: rgba(39, 137, 253, 1);
    border: 1px solid #2789fd;
    border-radius: 10px;
    right: 0;
    font-weight: 500;
    font-size: 16px;
  }
  .preview-btn-div {
    display: flex;
    justify-content: right;
  }
  .divider {
    background-color: #d0d0e2;
    height: 1.5px;
  }
`;
const Wrapper = styled.div`
  padding: 1rem 2rem;
  .student_detail__container {
    background: #ffffff;
    border: 1px solid #b6c8d6;
    border-radius: 15px;
    padding: 1.5rem 1rem;
  }
  .student_detail__img {
    height: 80px;
    width: 80px;
    border-radius: 10px;
    object-fit: contain;
    margin-bottom: 1rem;
  }
  .student_personal__detail {
    background: rgba(208, 208, 226, 0.08);
    border: 1px solid #d0d0e2;
    border-radius: 15px;
    padding: 1rem;
  }
  .student_admission__detail {
    border: 1px solid #d0d0e2;
    border-radius: 15px;
    flex: 1;
  }
  .divider {
    background-color: #d0d0e2;
    height: 1.5px;
  }
  .edit_icon__container {
    background-color: #ebf0f4;
    height: 26px;
    width: 26px;
    border-radius: 8px;
    cursor: pointer;
  }

  .container {
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    align-items: center;
    justify-content: space-between;
  }
  .container-sm {
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    align-items: center;
    justify-content: space-around;
  }
  .container-element {
    display: flex;
    flex-direction: column;
  }
  .container-element p {
    font-weight: 600;
    color: #0c2f49;
    font-size: 16px;
  }
  .container-element h5 {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #0c2f49;
    opacity: 0.75;
  }
  .container-element-extra {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .container-element-extra p {
    font-weight: 600;
    color: #0c2f49;
    font-size: 16px;
  }
  .container-element-extra h5 {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #0c2f49;
    opacity: 0.75;
  }
  .element-wrapper {
    padding: 25px;
    background: rgba(208, 208, 226, 0.08);
    border: 1px solid #d0d0e2;
    border-radius: 15px;
  }

  .border {
    background: #ffffff;
    border: 1px solid #b6c8d6;
    border-radius: 12px;
  }
`;
