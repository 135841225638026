import { schoolTokenAxios } from "../axios";

const REPORTING_BASE = process.env.REACT_APP_SCHOOL_REPORTING_BASE;

let reporting_url = `${REPORTING_BASE}/ledgerReport`;

export const getLedgerReportSummary = (params) => {
  return schoolTokenAxios.get(`${reporting_url}/getLedgerReportSummary`, {
    params,
  });
};
