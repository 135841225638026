import React from "react";
import styled from "styled-components";

import moment from "moment";

import { useMutation } from "react-query";
import { admissionApproveStudent } from "app/services/student-management";

import "react-notifications/lib/notifications.css";
import { useNavigate } from "react-router-dom";
import appRoutes from "app/config/routes";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import DialogHeader from "app/components/common/DialogHeader";
import ShowFeeStructure from "../../pages/CreateStudent/components/ShowFeeStructure";
import { selectSelectedSchool } from "app/modules/schools/selectors";
import { Grid } from "@mui/material";
import dayjs from "dayjs";

const NameComponent = ({ head, data }) => {
  return (
    <div className="container-element">
      <h5>{head}</h5>
      <p>{data ? String(data).replace(/undefined/g, "") : "--"}</p>
    </div>
  );
};
const PreviewForExistStudent = ({
  allValues,
  setShowPreview,
  session,
  requestDocId,
  feeData,
  collectedDocumentList,
  state,
}) => {
  const navigate = useNavigate();
  const selectedSchool = useSelector(selectSelectedSchool);
  const { mutate, isLoading: addExistingStudentLoading } = useMutation(
    admissionApproveStudent,
    {
      onSuccess: (data) => {
        toast.success(data?.data?.message);
        setShowPreview(false);
        navigate(`/${appRoutes.students}`);
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const submitAdmission = (allValues) => {
    const collectedDocument = [];
    collectedDocumentList?.list?.forEach((item) => {
      if (item?.checked) {
        collectedDocument.push(item?.label);
      }
    });
    let category = {};
    category.feeRelaxationCategory = state.feeRelaxationCategory;
    if (state.feeRelaxationCategory === "Siblings") {
      const data = [];
      state.siblingDetails?.forEach((item) => {
        if (item?.studentDocId) {
          data.push({
            schoolDocId: selectedSchool?._id,
            studentDocId: item?.studentDocId,
          });
        }
      });
      category.siblingDetails = data;
    }
    if (state.feeRelaxationCategory === "Staff_Ward") {
      category.staffWardDetail = {
        schoolDocId: selectedSchool?._id,
        staffDocId: state.employeeId,
      };
    }
    if (
      !["General", "Siblings", "Staff_Ward"].includes(
        state.feeRelaxationCategory
      )
    ) {
      category.otherFeeRelaxationCategoryDetail = {
        certificateName: state.certificateName,
        certificateLink: state.certificateUrl,
      };
    }
    const body = {
      ...category,
      session: session,
      house: allValues?.house,
      admissionDate: allValues?.admissionDate,
      admissionType: allValues?.admissionType,
      registrationNumber: allValues?.registrationNumber,
      admissionNumber: allValues?.admissionNumber,
      srNumber: allValues?.srNumber,
      rollNumber: allValues?.rollNumber,
      classDocId: allValues?.classDocId,
      section: allValues?.section,
      transportInfo: {
        using: allValues?.transportInfo?.using,
        routeNumber: allValues.transportInfo?.routeNumber,
        feeAmount:
          allValues?.transportInfo?.using === "Yes"
            ? allValues?.transportInfo.feeAmount
            : null,
        to: allValues?.transportInfo?.to,
        vehicleNumber: allValues?.transportInfo?.vehicleNumber,
        transportFeeDocId: allValues?.transportInfo?.transportFeeDocId,
        remarks: allValues?.transportInfo?.remarks,
        startDate:
          allValues?.transportInfo?.using === "Yes"
            ? allValues?.transportInfo?.startDate
            : "",
        recurringDiscountAmount: Number(
          allValues?.transportInfo?.recurringDiscountAmount ?? 0
        ),
      },
      collectedDocumentInfo: collectedDocument,
      feeStructureList: feeData?.list?.map((item) => item?._id),
      allFee: true,
      admissionRequestDocId: requestDocId,
    };
    mutate({ body, requestDocId });
  };

  return (
    <Wrapper>
      <DialogHeader
        title="Student Information"
        handleClose={() => setShowPreview(false)}
      />

      <div className="preview">
        {/* Preview Card */}

        {/* Personal Info */}
        <div>
          <h4>Personal Information</h4>
          <div className="element-wrapper">
            <div className="container">
              <NameComponent head="First Name" data={allValues?.firstName} />
              <NameComponent head="Middle Name" data={allValues?.middleName} />
              <NameComponent head="Last Name" data={allValues?.lastName} />
              <NameComponent head="Aadhar Number" data={allValues?.AADHAR} />
              <NameComponent
                head="Date of Birth"
                data={
                  allValues?.DOB
                    ? moment(new Date(allValues?.DOB)).format("DD-MMM-yyyy")
                    : "--"
                }
              />
            </div>
            <div className="container">
              <NameComponent head="Email ID" data={allValues?.email} />
              <NameComponent
                head="Mobile Number"
                data={allValues?.mobileNumber}
              />
              <NameComponent head="Community" data={allValues?.community} />
              <NameComponent head="Category" data={allValues?.category} />
              <NameComponent
                head="Special Category"
                data={allValues?.special_category}
              />
              <NameComponent head="Disability" data={allValues?.disability} />
            </div>
            <div className="container">
              <div className="container-element-extra">
                <h5>Blood Group</h5>
                <p>{allValues.bloodGroup}</p>
              </div>
            </div>
          </div>
        </div>

        {/* Parent's Info */}
        <div>
          <h4 className="mt-5 mb-2">Parent's Details</h4>
          <div className="element-wrapper">
            <p>Mother's Info</p>
            <div className="container">
              <NameComponent
                head="First Name"
                data={allValues?.motherInfo?.firstName}
              />
              <NameComponent
                head="Middle Name"
                data={allValues?.motherInfo?.middleName}
              />
              <NameComponent
                head="Last Name"
                data={allValues?.motherInfo?.lastName}
              />
              <NameComponent
                head="Email ID"
                data={allValues?.motherInfo?.email}
              />
              <NameComponent
                head="Mobile Number"
                data={allValues?.motherInfo?.mobileNumber}
              />
              <NameComponent
                head="Occupation"
                data={allValues?.motherInfo?.occupation}
              />
            </div>
            <hr></hr>
            <p>Father's Info</p>
            <div className="container">
              <NameComponent
                head="Full Name"
                data={allValues?.fatherInfo?.firstName}
              />
              <NameComponent
                head="Middle Name"
                data={allValues?.fatherInfo?.middleName}
              />
              <NameComponent
                head="Last Name"
                data={allValues?.fatherInfo?.lastName}
              />
              <NameComponent
                head="Mobile Number"
                data={allValues?.fatherInfo?.mobileNumber}
              />
              <NameComponent
                head="Occupation"
                data={allValues?.fatherInfo?.occupation}
              />
            </div>
            <hr></hr>
            <p>Guardian Info</p>
            <div className="container">
              <NameComponent
                head="Full Name"
                data={allValues?.guardianInfo?.firstName}
              />
              <NameComponent
                head="Middle Name"
                data={allValues?.guardianInfo?.middleName}
              />
              <NameComponent
                head="Last Name"
                data={allValues?.guardianInfo?.lastName}
              />
              <NameComponent
                head="Email ID"
                data={allValues?.guardianInfo?.email}
              />
              <NameComponent
                head="Mobile Number"
                data={allValues?.guardianInfo?.mobileNumber}
              />
              <NameComponent
                head="Relation"
                data={allValues?.guardianInfo?.relation}
              />
            </div>
          </div>
        </div>

        {/* Address Info */}
        <div>
          <h4 className="mt-5 mb-2">Address Information</h4>
          <div className="element-wrapper">
            <p>Current Address</p>
            <div className="container">
              <NameComponent
                head="Address"
                data={allValues?.currentAddress?.address}
              />
              <NameComponent
                head="Pin Code"
                data={allValues?.currentAddress?.pinCode}
              />
              <NameComponent
                head="State"
                data={allValues?.currentAddress?.state}
              />
              <NameComponent
                head="District"
                data={allValues?.currentAddress?.dist}
              />
            </div>
            <p>Permanent Address</p>
            <div className="container">
              <NameComponent
                head="Address"
                data={allValues?.permanentAddress?.address}
              />
              <NameComponent
                head="Pin Code"
                data={allValues?.permanentAddress?.pinCode}
              />
              <NameComponent
                head="State"
                data={allValues?.permanentAddress?.state}
              />
              <NameComponent
                head="District"
                data={allValues?.permanentAddress?.dist}
              />
            </div>
          </div>
        </div>

        {/* Admission Detail */}
        <div>
          <h4 className="mt-5 mb-2">Admission Details</h4>
          <div className="element-wrapper">
            <div className="container">
              <NameComponent
                head="Admission Date"
                data={
                  allValues?.admissionDate
                    ? moment(allValues?.admissionDate).format("DD-MM-YYYY")
                    : "--"
                }
              />
              <NameComponent
                head="Admission Type"
                data={allValues?.admissionType}
              />
              <NameComponent
                head="Reg. Number"
                data={allValues?.registrationNumber}
              />
              <NameComponent
                head="Adm. Number"
                data={allValues?.admissionNumber}
              />
              <NameComponent head="Roll Number" data={allValues?.rollNumber} />
              <NameComponent
                head="Class-Stream-Sec"
                data={`${allValues?.className}-${allValues?.stream}-${allValues?.section}`}
              />
            </div>
          </div>
        </div>
        {/* Transportation */}
        <div>
          <h4 className="mt-5 mb-2">Transportation</h4>
          <div className="element-wrapper">
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <NameComponent
                  head="Using Transport"
                  data={allValues?.transportInfo?.using}
                />
              </Grid>
              <Grid item xs={3}>
                <NameComponent
                  head="Route No. (Route Name)"
                  data={`${allValues?.transportInfo?.routeNumber}:${allValues?.transportInfo?.routeName}`}
                />
              </Grid>
              <Grid item xs={3}>
                <NameComponent
                  head="Area Code"
                  data={allValues?.transportInfo?.to}
                />
              </Grid>
              <Grid item xs={3}>
                <NameComponent
                  head="Transport Vehicle"
                  data={allValues?.transportInfo?.vehicleNumber}
                />
              </Grid>
              <Grid item xs={3}>
                <NameComponent
                  head="Stop Name"
                  data={allValues?.transportInfo?.to}
                />
              </Grid>
              <Grid item xs={3}>
                <NameComponent
                  head="Fee Structure"
                  data={`${allValues?.transportInfo?.feeCode} ₹${allValues?.transportInfo?.feeAmount}`}
                />
              </Grid>
              <Grid item xs={3}>
                <NameComponent
                  head="Start Date"
                  data={dayjs(allValues?.transportInfo?.startDate).format(
                    "DD MMM, YYYY"
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <NameComponent
                  head="Recurring Discount"
                  data={allValues?.transportInfo?.recurringDiscountAmount}
                />
              </Grid>
              <Grid item xs={6}>
                <NameComponent
                  head="Remarks"
                  data={allValues?.transportInfo?.remarks}
                />
              </Grid>
            </Grid>
          </div>
        </div>

        {/* Hostel */}
        <div>
          <h4 className="mt-5 mb-2">Hostel</h4>
          <div className="element-wrapper">
            <div className="container-sm">
              <NameComponent
                head="Hostel"
                data={allValues?.hostelInfo?.hostelName}
              />
              <NameComponent
                head="Room Type"
                data={allValues?.hostelInfo?.roomType}
              />
              <NameComponent
                head="Room Number"
                data={allValues?.hostelInfo?.roomNumber}
              />
            </div>
          </div>
        </div>

        {/* House Details */}
        <div>
          <h4 className="mt-5 mb-2">House</h4>
          <div className="element-wrapper">
            <div className="container-sm">
              <NameComponent
                head="House Color"
                data={allValues?.house?.houseColor}
              />
              <NameComponent
                head="House Name"
                data={allValues?.house?.houseName}
              />
              <NameComponent
                head="Membership Role"
                data={allValues?.house?.membershipRole}
              />
            </div>
          </div>
        </div>

        {/* Fee Details */}
        <div>
          <h4 className="mt-5 mb-2">Fee Structure</h4>
          <ShowFeeStructure feeData={feeData} />
        </div>
      </div>
      <div className="footer">
        <LoadingButton
          loading={addExistingStudentLoading}
          variant="contained"
          color="primary"
          size="large"
          onClick={() => submitAdmission(allValues)}
        >
          Submit
        </LoadingButton>
      </div>
    </Wrapper>
  );
};

export default PreviewForExistStudent;

const Wrapper = styled.div`
  .footer {
    margin: 20px;
    display: flex;
    justify-content: right;
  }
  .approval-btn {
    margin-right: 10px;
    background: #ffffff;
    border: 1px solid #e8a82c;
    border-radius: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #e8a82c;
  }
  .submit-btn {
    background: #2789fd;
    border-radius: 10px;
    font-weight: 400;
    font-size: 16px;
    padding: 15px 30px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    border: 1px solid;
    color: white;
  }
  .header {
    padding: 20px 20px 0 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .header-button {
    height: 28px;
    width: 28px;
    border-radius: 10px;
    border: 1.7915px solid #0c2f49;
  }
  .preview {
    overflow: auto;
    position: relative;
    padding: 20px;

    background: white;
    border-radius: 10px;
  }

  position: relative;
  border-radius: round;
  overflow: auto;
  margin: 2px;
  flex-wrap: wrap;

  .container {
    display: flex;
    flex-direction: row;
    margin-top: 25px;

    justify-content: space-between;
  }
  .container-sm {
    display: flex;
    flex-direction: row;
    margin-top: 25px;

    justify-content: space-around;
  }
  .container-element {
    display: flex;
    flex-direction: column;
  }
  .container-element p {
    font-weight: 600;
    color: #0c2f49;
    font-size: 16px;
  }
  .container-element h5 {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #0c2f49;
    opacity: 0.75;
  }
  .container-element-extra {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .container-element-extra p {
    font-weight: 600;
    color: #0c2f49;
    font-size: 16px;
  }
  .container-element-extra h5 {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #0c2f49;
    opacity: 0.75;
  }
  .element-wrapper {
    overflow: auto;
    padding: 25px;
    background: rgba(208, 208, 226, 0.08);
    border: 1px solid #d0d0e2;
    border-radius: 15px;
  }

  .border {
    background: #ffffff;
    border: 1px solid #b6c8d6;
    border-radius: 12px;
  }
  .accordian-wrapper {
    margin-bottom: 40px;
  }
`;
