import { LoadingButton } from "@mui/lab";
import { Box, Container, MenuItem, Stack, Typography } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import StudentSearchInput from "app/components/common/StudentSearchInput";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import { CategoryForFee, Community } from "app/config/administrativeConstants";
import {
  selectActiveSession,
  selectSelectedSchoolId,
  selectedMediumSelector,
} from "app/modules/schools/selectors";
import { getAllClassDropdowns } from "app/services/management";
import {
  getAllRouteList,
  getClassDropdown,
  getStudentsListLogic,
  getTransport,
} from "app/services/student-management";
import { MenuProps } from "app/utils/helper";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const StudentFilter = ({ state, setState, handlePrint }) => {
  const selectedSession = useSelector(selectActiveSession);
  const selectedMedium = useSelector(selectedMediumSelector);
  const selectedSchoolId = useSelector(selectSelectedSchoolId);
  const [transportData, setTransportData] = useState();
  const [classOptions, setClassOptions] = useState([]);
  let header = [
    "AADHAR",
    "RegistrationNumber",
    "RollNumber",
    "AdmissionNumber",
    "admissionDate",
    "StudentName",
    "ClassStream",
    "Section",
    "MobileNumber",
    "DOB",
    "Gender",
    "Category",
    "Community",
    "FatherName",
    "FatherMobileNumber",
    "FatherOccupation",
    "MotherName",
    "MotherMobileNumber",
    "MotherOccupation",
    "CurrentAddress",
    "TransportUsing",
    "TransportRouteNumber",
    "TransportVehicleNumber",
    "TransportFeeAmount",
    "TransportTo",
    // Pre School Information
    "preSchoolName",
    "preSchoolClassName",
    "preSchoolSession",
    "preSchoolAddress",
    "House Name",
    "House Color",
    "Membership Role",
  ];

  // Fetch student By filters
  const {
    mutate: getStudentsListLogicMutate,
    isLoading: getStudentsListLogicMutateLoading,
  } = useMutation(getStudentsListLogic, {
    onSuccess: (data) => {
      setState((prev) => ({ ...prev, studentList: data?.data?.list }));
    },
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
  });
  const handleSubmitNameSearch = (list) => {
    getStudentsListLogicMutate({
      reqStudentDocIdList: JSON.stringify(list),
      session: selectedSession,
    });
  };
  const {
    isLoading: studentListLoading,
    refetch: studentFetching,
    isFetching,
  } = useQuery({
    queryKey: ["getStudents1", selectedSession],
    queryFn: () => {
      let body = {};
      switch (state.filter) {
        case "By Class":
          body = {
            medium: selectedMedium,
            ...(state.classDocId && { classDocId: state.classDocId }),
            ...(state.section && { section: state.section }),
          };
          if (body.section === "ALL") {
            delete body.section;
          }
          break;

        case "By Aadhar Number":
          body = {
            AADHAR: state.addharNumber,
            medium: selectedMedium,
          };
          break;
        case "By Transport":
          body = {
            vehicleNumber: state.vehicleNumber,
            medium: selectedMedium,
          };
          break;
        case "By Stop Name":
          body = {
            routeNumber: state.routeNumber,
            to: state.stopName,
            medium: selectedMedium,
          };
          break;
        case "By Category":
          body = {
            // classDocId: state.classDocId,
            // section: state.section,
            category: state.category,
            medium: selectedMedium,
          };
          break;
        case "By Community":
          body = {
            // classDocId: state.classDocId,
            // section: state.section,
            community: state.community,
            medium: selectedMedium,
          };
          break;
        case "By Registration Number":
          body = {
            registrationNumber: state.registrationNumber,
            medium: selectedMedium,
          };
          break;
        case "By Admission Number":
          body = {
            admissionNumber: state.admissionNumber,
            medium: selectedMedium,
          };
          break;
        case "By First Admission Date":
          body = {
            firstAdmissionDateFrom: state.firstAdmissionDateFrom,
            firstAdmissionDateTo: state.firstAdmissionDateTo,
            medium: selectedMedium,
          };
          break;
        case "By Admission Date":
          body = {
            admissionFrom: state.admissionDateFrom,
            admissionTo: state.admissionDateTo,
            medium: selectedMedium,
          };
          break;
        case "By Mobile Number":
          body = {
            mobileNumber: state.searchmobileNumber,
            medium: selectedMedium,
          };
          break;

        default:
          break;
      }
      return getStudentsListLogic({ ...body, session: selectedSession });
    },
    enabled: false,
    onSuccess: (data) => {
      setState((prev) => ({ ...prev, studentList: data?.data?.list }));
    },
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
  });
  useQuery({
    queryKey: ["getClassDropdown", selectedSchoolId, selectedMedium],
    queryFn: () => getClassDropdown(selectedSchoolId, selectedMedium),
  });
  // fetch route detail
  const { refetch: fetchRouteDetails, data: routeResponse } = useQuery({
    queryKey: ["getAllRouteList"],
    queryFn: () => getAllRouteList(),
    enabled: false,
  });

  useEffect(() => {
    studentFetching();
    setState((prev) => ({
      ...prev,
      verifiedAadharData: "",
      collapse: "student",
      mobileNumber: "",
      mobileVerified: false,
      otp: "",
      verifiedMobileData: "",
      classDocId: "",
      filter: "By Class",
      className: "",
      section: "",
      sectionList: [],
      category: "",
      community: "",
      admissionNumber: "",
      admissionDateFrom: new Date(),
      admissionDateTo: new Date(),
      studentName: "",
      registrationNumber: "",
      searchmobileNumber: "",
      vehicleNumber: "",
      route: "",
      stopName: "",
      routeNumber: "",
      studentList: [],
    }));
  }, [selectedSession]);
  useEffect(() => {
    const fetchClassData = async () => {
      const res = await getAllClassDropdowns(
        selectedSchoolId,
        selectedSession,
        selectedMedium
      );
      const data = res.data;
      setClassOptions(data?.list);
    };

    const fetchTransportData = async () => {
      const res = await getTransport({ status: "ACTIVE" });
      setTransportData(res.data);
    };

    fetchTransportData();
    fetchClassData();
  }, [selectedSession, selectedMedium]);
  useEffect(() => {
    if (state.filter === "By Stop Name") {
      fetchRouteDetails();
    }
  }, [state.filter]);

  return (
    <Container maxWidth="xl">
      <Box>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 2 }}
          gap={{ xs: 1, md: 0.2 }}
        >
          <Box
            sx={{
              width: { xs: "80vw", md: "20vw" },
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "600",
                mb: 1,
                color: (theme) => theme.palette.primary.main,
              }}
            >
              Filter Student
            </Typography>
            <CustomTextField
              SelectProps={{
                MenuProps: MenuProps,
              }}
              className="select_box"
              size="small"
              select
              value={state.filter}
              fullWidth
              onChange={(e) => {
                setState((prev) => ({
                  ...prev,
                  filter: e.target.value,
                  classDocId: "",
                  section: "",
                }));
              }}
              sx={{ width: { xs: "40vw", md: "15vw" } }}
            >
              {[
                "By Class",
                "By Name",
                "By Admission Number",
                "By Registration Number",
                "By Aadhar Number",
                "By Mobile Number",
                "By Transport",
                "By Category",
                "By Community",
                "By Admission Date",
                "By First Admission Date",
                "By Stop Name",
              ]?.map((item, ind) => (
                <MenuItem value={item} key={ind}>
                  {item}
                </MenuItem>
              ))}
            </CustomTextField>
          </Box>
        </Stack>

        {/* Show Filter options */}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mt: 1.5, px: 2 }}
        >
          <Stack direction={"row"} alignItems="flex-end" gap={1}>
            {state.filter === "By Name" && (
              <StudentSearchInput
                loading={getStudentsListLogicMutateLoading}
                handleSearch={handleSubmitNameSearch}
              />
            )}
            {state.filter === "By Class" && (
              <>
                <Box sx={{ width: { xs: "35vw", md: "15vw" } }}>
                  <TextFieldLabel title="Class-Stream" />
                  <CustomSelectBox
                    size="small"
                    select
                    MenuProps={MenuProps}
                    displayEmpty
                    value={state.classDocId}
                    fullWidth
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    <MenuItem
                      value="ALL"
                      onClick={() =>
                        setState((prev) => ({
                          ...prev,
                          classDocId: "ALL",
                          section: "",
                        }))
                      }
                    >
                      All Class
                    </MenuItem>
                    {classOptions &&
                      classOptions?.map((opt, i) => {
                        return (
                          <MenuItem
                            key={opt?.classDocId}
                            value={opt?.classDocId}
                            onClick={() =>
                              setState((prev) => ({
                                ...prev,
                                classDocId: opt?.classDocId,
                                section: "",
                                sectionList: opt?.sections,
                              }))
                            }
                          >
                            {opt?.className_stream}
                          </MenuItem>
                        );
                      })}
                  </CustomSelectBox>
                </Box>
                {state.classDocId !== "ALL" && (
                  <Box sx={{ width: { xs: "30vw", md: "10vw" } }}>
                    <TextFieldLabel title="Section" />
                    <CustomSelectBox
                      displayEmpty
                      size="small"
                      fullWidth
                      value={state.section}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          section: e.target.value,
                        }))
                      }
                      disabled={state.classDocId ? false : true}
                    >
                      <MenuItem disabled value="">
                        Select
                      </MenuItem>
                      <MenuItem value="ALL">All-Section</MenuItem>
                      {state.sectionList &&
                        state.sectionList?.map((opt, i) => {
                          return (
                            <MenuItem key={i} value={opt}>
                              {opt}
                            </MenuItem>
                          );
                        })}
                    </CustomSelectBox>
                  </Box>
                )}
                <LoadingButton
                  loading={studentListLoading || isFetching}
                  disabled={!Boolean(state.classDocId)}
                  color="secondary"
                  variant="contained"
                  size="large"
                  onClick={() => studentFetching()}
                  sx={{ mb: 0.5, textTransform: "capitalize" }}
                >
                  Search
                </LoadingButton>
              </>
            )}

            {state.filter === "By Transport" && (
              <>
                <Box sx={{ width: { xs: "65vw", md: "15vw" } }}>
                  <TextFieldLabel title="Transport Number" />
                  <CustomSelectBox
                    displayEmpty
                    MenuProps={MenuProps}
                    size="small"
                    fullWidth
                    value={state.vehicleNumber}
                    placeholder="Type Vehicle Number"
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        vehicleNumber: e.target.value,
                      }))
                    }
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    {transportData?.list?.map((transport, idx) => (
                      <MenuItem key={idx} value={transport.vehicleNumber}>
                        {transport.vehicleNumber}
                      </MenuItem>
                    ))}
                  </CustomSelectBox>
                </Box>
                <LoadingButton
                  loading={studentListLoading || isFetching}
                  disabled={!Boolean(state.vehicleNumber)}
                  color="secondary"
                  variant="contained"
                  size="large"
                  onClick={() => studentFetching()}
                  sx={{ mb: 0.5, textTransform: "capitalize" }}
                >
                  Search
                </LoadingButton>
              </>
            )}
            {state.filter === "By Stop Name" && (
              <>
                <Box sx={{ width: { xs: "30vw", md: "15vw" } }}>
                  <TextFieldLabel title="Route Name" />
                  <CustomSelectBox
                    displayEmpty
                    size="small"
                    fullWidth
                    value={state.route?.routeNumber ?? ""}
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    {routeResponse?.data?.list.map((route, idx) => (
                      <MenuItem
                        key={idx}
                        value={route.routeNumber}
                        onClick={() =>
                          setState((prev) => ({
                            ...prev,
                            route,
                            routeNumber: route.routeNumber,
                            stopName: "",
                          }))
                        }
                      >
                        {route?.routeNumber} ({route?.routeName})
                      </MenuItem>
                    ))}
                  </CustomSelectBox>
                </Box>
                <Box sx={{ width: { xs: "30vw", md: "15vw" } }}>
                  <TextFieldLabel title="Stop Name" />
                  <CustomSelectBox
                    size="small"
                    displayEmpty
                    fullWidth
                    value={state.stopName}
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        stopName: e.target.value,
                      }))
                    }
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    {state.route?.stopList?.map((stop, idx) => (
                      <MenuItem key={idx} value={stop.stopName}>
                        {stop.stopName}
                      </MenuItem>
                    ))}
                  </CustomSelectBox>
                </Box>
                <LoadingButton
                  loading={studentListLoading || isFetching}
                  color="secondary"
                  disabled={!Boolean(state.stopName)}
                  variant="contained"
                  size="large"
                  onClick={() => studentFetching()}
                  sx={{ mb: 0.5, textTransform: "capitalize" }}
                >
                  Search
                </LoadingButton>
              </>
            )}
            {state?.filter === "By Category" && (
              <>
                <Box sx={{ width: { xs: "65vw", md: "15vw" } }}>
                  <TextFieldLabel title="Category" />
                  <CustomSelectBox
                    size="small"
                    displayEmpty
                    fullWidth
                    value={state.category}
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        category: e.target.value,
                      }))
                    }
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    {CategoryForFee?.map((category, idx) => (
                      <MenuItem key={idx} value={category}>
                        {category}
                      </MenuItem>
                    ))}
                  </CustomSelectBox>
                </Box>
                <LoadingButton
                  loading={studentListLoading || isFetching}
                  color="secondary"
                  variant="contained"
                  disabled={!Boolean(state.category)}
                  size="large"
                  onClick={() => studentFetching()}
                  sx={{ mb: 0.5, textTransform: "capitalize" }}
                >
                  Search
                </LoadingButton>
              </>
            )}
            {state.filter === "By Community" && (
              <>
                <Box sx={{ width: { xs: "65vw", md: "15vw" } }}>
                  <TextFieldLabel title="Community" />
                  <CustomSelectBox
                    size="small"
                    displayEmpty
                    fullWidth
                    value={state.community}
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        community: e.target.value,
                      }))
                    }
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    {Community.map((community, idx) => (
                      <MenuItem key={idx} value={community}>
                        {community}
                      </MenuItem>
                    ))}
                  </CustomSelectBox>
                </Box>
                <LoadingButton
                  loading={studentListLoading || isFetching}
                  disabled={!Boolean(state.community)}
                  color="secondary"
                  variant="contained"
                  size="large"
                  onClick={() => studentFetching()}
                  sx={{ mb: 0.5, textTransform: "capitalize" }}
                >
                  Search
                </LoadingButton>
              </>
            )}
            {state.filter === "By Registration Number" && (
              <>
                <Box sx={{ width: { xs: "65vw", md: "15vw" } }}>
                  <TextFieldLabel title="Registration Number" />
                  <CustomTextField
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        registrationNumber: e.target.value,
                      }))
                    }
                    placeholder="Type Registration Number"
                    value={state.registrationNumber}
                    fullWidth
                    size="small"
                  />
                </Box>
                <LoadingButton
                  loading={studentListLoading || isFetching}
                  disabled={!String(state.registrationNumber).trim()}
                  color="secondary"
                  variant="contained"
                  size="large"
                  onClick={() => studentFetching()}
                  sx={{ mb: 0.5, textTransform: "capitalize" }}
                >
                  Search
                </LoadingButton>
              </>
            )}
            {state.filter === "By Admission Number" && (
              <>
                <Box sx={{ width: { xs: "65vw", md: "15vw" } }}>
                  <TextFieldLabel title="Admission Number" />
                  <CustomTextField
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        admissionNumber: e.target.value,
                      }))
                    }
                    value={state.admissionNumber}
                    fullWidth
                    size="small"
                    placeholder="Type Admision Number"
                  />
                </Box>
                <LoadingButton
                  loading={studentListLoading || isFetching}
                  disabled={!String(state.admissionNumber).trim()}
                  color="secondary"
                  variant="contained"
                  size="large"
                  onClick={() => studentFetching()}
                  sx={{ mb: 0.5, textTransform: "capitalize" }}
                >
                  Search
                </LoadingButton>
              </>
            )}
            {state.filter === "By First Admission Date" && (
              <>
                <Box sx={{ width: { xs: "33vw", md: "15vw" } }}>
                  <TextFieldLabel title="From" />
                  <DesktopDatePicker
                    inputFormat="DD MMM, YYYY"
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        firstAdmissionDateFrom: e,
                      }))
                    }
                    value={state.firstAdmissionDateFrom}
                    renderInput={(params) => (
                      <CustomTextField {...params} fullWidth size="small" />
                    )}
                  />
                </Box>

                <Box sx={{ width: { xs: "33vw", md: "15vw" } }}>
                  <TextFieldLabel title="To" />
                  <DesktopDatePicker
                    inputFormat="DD MMM, YYYY"
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        firstAdmissionDateTo: e,
                      }))
                    }
                    value={state.firstAdmissionDateTo}
                    renderInput={(params) => (
                      <CustomTextField {...params} fullWidth size="small" />
                    )}
                  />
                </Box>
                <LoadingButton
                  loading={studentListLoading || isFetching}
                  color="secondary"
                  variant="contained"
                  size="large"
                  onClick={() => studentFetching()}
                  sx={{ mb: 0.5, textTransform: "capitalize" }}
                >
                  Search
                </LoadingButton>
              </>
            )}
            {state?.filter === "By Admission Date" && (
              <>
                <Box sx={{ width: { xs: "33vw", md: "15vw" } }}>
                  <TextFieldLabel title="From" />
                  <DesktopDatePicker
                    inputFormat="DD MMM, YYYY"
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        admissionDateFrom: e,
                      }))
                    }
                    value={state.admissionDateFrom}
                    renderInput={(params) => (
                      <CustomTextField {...params} fullWidth size="small" />
                    )}
                  />
                </Box>

                <Box sx={{ width: { xs: "33vw", md: "15vw" } }}>
                  <TextFieldLabel title="To" />
                  <DesktopDatePicker
                    inputFormat="DD MMM, YYYY"
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        admissionDateTo: e,
                      }))
                    }
                    value={state.admissionDateTo}
                    renderInput={(params) => (
                      <CustomTextField {...params} fullWidth size="small" />
                    )}
                  />
                </Box>
                <LoadingButton
                  loading={studentListLoading || isFetching}
                  color="secondary"
                  variant="contained"
                  size="large"
                  onClick={() => studentFetching()}
                  sx={{ mb: 0.5, textTransform: "capitalize" }}
                >
                  Search
                </LoadingButton>
              </>
            )}
            {state.filter === "By Mobile Number" && (
              <>
                <Box sx={{ width: { xs: "65vw", md: "15vw" } }}>
                  <TextFieldLabel title="Mobile Number" />
                  <CustomTextField
                    fullWidth
                    size="small"
                    type="number"
                    placeholder="Type Mobile Number"
                    onChange={(e) => {
                      if (e.target.value.length < 11) {
                        setState((prev) => ({
                          ...prev,
                          searchmobileNumber: e.target.value,
                        }));
                      }
                    }}
                    value={state.searchmobileNumber}
                  />
                </Box>
                <LoadingButton
                  loading={studentListLoading || isFetching}
                  disabled={
                    !String(state.searchmobileNumber).trim() ||
                    String(state.searchmobileNumber).length < 10
                  }
                  color="secondary"
                  variant="contained"
                  size="large"
                  onClick={() => studentFetching()}
                  sx={{ mb: 0.5, textTransform: "capitalize" }}
                >
                  Search
                </LoadingButton>
              </>
            )}
            {state.filter === "By Aadhar Number" && (
              <>
                <Box sx={{ width: { xs: "65vw", md: "15vw" } }}>
                  <TextFieldLabel title="Aadhar Number" />
                  <CustomTextField
                    fullWidth
                    size="small"
                    type="number"
                    placeholder="Type Mobile Number"
                    onChange={(e) => {
                      if (e.target.value.length < 13) {
                        setState((prev) => ({
                          ...prev,
                          addharNumber: e.target.value,
                        }));
                      }
                    }}
                    value={state.addharNumber}
                  />
                </Box>
                <LoadingButton
                  disabled={
                    !String(state.addharNumber).trim() ||
                    String(state.addharNumber).length < 12
                  }
                  loading={studentListLoading || isFetching}
                  color="secondary"
                  variant="contained"
                  size="large"
                  onClick={() => studentFetching()}
                  sx={{ mb: 0.5, textTransform: "capitalize" }}
                >
                  Search
                </LoadingButton>
              </>
            )}
          </Stack>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <Stack direction="row" alignItems="center" gap={2}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 600,
                  mb: "-12px",
                  mr: 10,
                }}
              >
                Total Student :{" "}
                <span style={{ fontSize: "18px" }}>
                  {state.studentList?.length}
                </span>
              </Typography>
            </Stack>
          </Box>
        </Stack>

        {/*print button  */}
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mt: 1.5, px: 2 }}
        >
          <LoadingButton
            loading={false}
            disabled={!state.studentList?.length}
            color="secondary"
            variant="contained"
            size="large"
            onClick={handlePrint}
            sx={{ mb: 0.5, textTransform: "capitalize" }}
          >
            Print
          </LoadingButton>
        </Stack>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 600,
            mb: "-12px",
            my: 1,
            display: { md: "none" },
          }}
        >
          Total Student :{" "}
          <span style={{ fontSize: "18px" }}>{state.studentList?.length}</span>
        </Typography>
      </Box>
    </Container>
  );
};

export default StudentFilter;
