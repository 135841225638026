import { Box, Divider, Grid, Stack, Typography, styled } from "@mui/material";
import SchoolPrintHeader2 from "app/components/common/SchoolPrintHeader2";
import { selectSelectedSchool } from "app/modules/schools/selectors";
import { DOB_in_Word, FormatFullName } from "app/utils/helper";
import dayjs from "dayjs";
import React from "react";
import { useSelector } from "react-redux";
import { ToWords } from "to-words";

const TcTemplate2 = ({ data, color, printRef }) => {
  const toWords = new ToWords();
  const selectedSchool = useSelector(selectSelectedSchool);

  return (
    <>
      <Wrapper ref={printRef} style={{ backgroundColor: color }}>
        <SchoolPrintHeader2 />
        <Divider fullWidth sx={{ backgroundColor: "#000000" }} />

        <Stack
          sx={{
            backgroundColor: "black",
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "16px",
              textAlign: "center",
              padding: "2px",
              color: "#ffffff",
            }}
          >
            स्थानांतरण प्रमाण पत्र / Transfer Certificate{" "}
          </Typography>
        </Stack>
        <Grid container spacing={2} sx={{ px: "2em", mt: 0.001 }}>
          <Grid item xs={3}>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "12px",
              }}
            >
              विद्यालय संख्या/School No. :{" "}
              {selectedSchool?.schoolRegisterNumber
                ? selectedSchool?.schoolRegisterNumber
                : ".............."}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "12px",
              }}
            >
              पुस्तक संख्या/Book No. :{" "}
              {data?.bookNumber ? data?.bookNumber : ".."}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "12px",
              }}
            >
              क्रम संख्या/SR No. : {data?.studentProfile?.srNumber ?? "..."}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "12px",
              }}
            >
              प्रवेश संख्या/Admission No. :{" "}
              {data?.studentProfile?.admissionNumber ?? ".."}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ px: "2em" }}>
          <Grid item xs={3}>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "12px",
              }}
            >
              Affiliation Number :{" "}
              {selectedSchool?.affiliationNumber
                ? selectedSchool?.affiliationNumber
                : ".............."}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "12px",
              }}
            >
              Renewed up to Date:{" "}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "12px",
              }}
            >
              Status of School/Senior Secondary :{" "}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ px: "2em", mb: 0.001 }}>
          <Grid item xs={8}>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "12px",
              }}
            >
              Registration No. of the candidate ( in case of Class -{" "}
              <span style={{ fontWeight: 600 }}>IX</span> to{" "}
              <span style={{ fontWeight: 600 }}>XII</span> ) : .............
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "12px",
                textAlign: "center",
              }}
            >
              PEN Number: {data?.studentProfile?.penNumber_center ?? "----"}
            </Typography>
          </Grid>
        </Grid>
        <Divider fullWidth sx={{ backgroundColor: "#000000" }} />
        <Box sx={{ px: "2em" }}>
          <Box sx={{ px: "2em" }}>
            {/* Name of the Pupil  */}
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Typography className="contentheading">
                  1. विद्यार्थी का नाम / Name of the pupil
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography className="contentheading2">
                  :{" "}
                  {FormatFullName(
                    data?.studentProfile?.firstName,
                    data?.studentProfile?.middleName,
                    data?.studentProfile?.lastName
                  )}
                </Typography>
              </Grid>
            </Grid>
            {/* Mother's Name */}
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Typography className="contentheading">
                  2. माता का नाम / Mother's Name
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography className="contentheading2">
                  :{" "}
                  {FormatFullName(
                    data?.studentProfile?.motherInfo?.firstName,
                    data?.studentProfile?.motherInfo?.middleName,
                    data?.studentProfile?.motherInfo?.lastName
                  )}
                </Typography>
              </Grid>
            </Grid>
            {/* Father's Name */}
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Typography className="contentheading">
                  3. पिता का नाम / Father's Name
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography className="contentheading2">
                  :{" "}
                  {FormatFullName(
                    data?.studentProfile?.fatherInfo?.firstName,
                    data?.studentProfile?.fatherInfo?.middleName,
                    data?.studentProfile?.fatherInfo?.lastName
                  )}{" "}
                </Typography>
              </Grid>
            </Grid>
            {/* Nationality */}
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Typography className="contentheading">
                  4. राष्ट्रीयता / Nationality
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography className="contentheading2">: INDIAN</Typography>
              </Grid>
            </Grid>
            {/* Category */}
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Typography className="contentheading">
                  5. क्या छात्र अनुo जाति/जoजाo/पिछडा वर्ग से संबंधित है
                </Typography>
                <Typography className="contentheading4">
                  Whether the pupil belongs to SC/ST/OBC Category
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography className="contentheading2">
                  : {data?.studentProfile?.category}
                </Typography>
              </Grid>
            </Grid>
            {/* Date of Admission */}
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Typography className="contentheading">
                  6. विद्यालय में प्रथम प्रवेश की तिथि और कक्षा
                </Typography>
                <Typography className="contentheading4">
                  Date of first admission in the school with class
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography className="contentheading2">
                  :{" "}
                  {dayjs(data?.firstAdmissionDetail?.admissionDate).format(
                    "DD-MM-YYYY"
                  )}
                  , {data?.firstAdmissionDetail?.className}-
                  {data?.firstAdmissionDetail?.stream}-
                  {data?.firstAdmissionDetail?.section}
                </Typography>
              </Grid>
            </Grid>
            {/* Date of Birth */}
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Typography className="contentheading">
                  7. प्रवेश पुस्तिका के अनुसार जन्मतिथि (अंको में/In Figure)
                </Typography>
                <Typography className="contentheading4">
                  Date of Birth according to the Admission Register (शब्दों
                  में/in words)
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography className="contentheading2">
                  : {dayjs(data?.studentProfile?.DOB).format("DD-MM-YYYY")}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: "12px",
                    textTransform: "uppercase",
                  }}
                >
                  : {DOB_in_Word(data?.studentProfile?.DOB)}
                </Typography>
              </Grid>
            </Grid>
            {/* Failed Status */}
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Typography className="contentheading">
                  8. क्या विद्यार्थी का परीक्षा परिणाम अनुत्तीर्ण है?
                </Typography>
                <Typography className="contentheading4">
                  Whether the student is failed?
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography className="contentheading2">
                  : {data?.lastClassDetail?.failCount === 0 ? "No" : "Yes"}
                </Typography>
              </Grid>
            </Grid>
            {/* Subject Offered */}
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Typography className="contentheading">
                  9. प्रस्तावित विषय / Subjects Offered
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography className="contentheading2">
                  : {data?.lastClassDetail?.subjects?.join(", ")}
                </Typography>
              </Grid>
            </Grid>
            {/* last studied */}
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Typography className="contentheading">
                  10. पिछली कक्षा जिसमें विद्यार्थी अध्ययनरत था : (शब्दों में)
                </Typography>
                <Typography className="contentheading4">
                  Class in which the pupil last studied (in words).
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography className="contentheading2">
                  : {data?.lastClassDetail?.className}
                </Typography>
              </Grid>
            </Grid>
            {/*school Board */}
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Typography className="contentheading">
                  11.पिछले विद्यालय/बोर्ड परीक्षा एवं परिणाम
                </Typography>
                <Typography className="contentheading4">
                  School/Board Annual examination last taken with result.
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography className="contentheading2">
                  :{" "}
                  {data?.lastClassDetail?.result === "Pass" ? "Pass" : "Failed"}
                </Typography>
              </Grid>
            </Grid>
            {/*promote */}
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Typography className="contentheading">
                  12.क्या विद्यार्थी उच्च कक्षा में पदोन्नति का अधिकारी है?
                </Typography>
                <Typography className="contentheading4">
                  Whether qualified for promotion to the next higher class?
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography className="contentheading2">
                  : {data?.ableToPromote ? "Yes" : "No"}
                </Typography>
              </Grid>
            </Grid>
            {/*all dues cleared */}
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Typography className="contentheading">
                  13.क्या विद्यार्थी ने विद्यालय के सभी देय राशि का भुगतान कर
                  दिया है?
                </Typography>
                <Typography className="contentheading4">
                  Whether the pupil has paid all dues to the school?
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography className="contentheading2">
                  : {data?.isDuesCleared ? "Yes" : "No"}
                </Typography>
              </Grid>
            </Grid>
            {/*slip */}
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Typography className="contentheading">
                  14.क्या विद्यार्थी को शुल्क में कोई रियायत मिल रही थी, यदि
                  हाँ, तो उसकी प्रकृति
                </Typography>
                <Typography className="contentheading4">
                  Whether the pupil was in receipt of any fee concession, if so,
                  the nature of such concession
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography className="contentheading2">
                  : {data?.availedFeeConcession}{" "}
                  {data?.availedFeeConcession === "Yes" &&
                    `(${data?.availedFeeConcessionRemarks})`}
                </Typography>
              </Grid>
            </Grid>
            {/*NCC */}
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Typography className="contentheading">
                  15. क्या विद्यार्थी एनसीसी कैडेट/स्काउट/गर्ल गाइड है (विवरण
                  दें)
                </Typography>
                <Typography className="contentheading4">
                  Whether the pupil is NCC Cadet/Boy Scout/Girl Guide (give
                  detail)
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography className="contentheading2">
                  : {data?.participated_in_NCC}
                </Typography>
              </Grid>
            </Grid>
            {/*Date */}
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Typography className="contentheading">
                  16. विद्यालय से विद्यार्थी का नाम काटे जाने की तिथि
                </Typography>
                <Typography className="contentheading4">
                  Date on which the pupil's name was struck off the rolls of the
                  school
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography className="contentheading2">
                  :{" "}
                  {dayjs(data?.lastClassDetail?.relievingDate).format(
                    "DD-MM-YYYY"
                  )}
                </Typography>
              </Grid>
            </Grid>
            {/*Reason for leaving */}
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Typography className="contentheading">
                  17.विद्यालय छोड़ने का कारण / Reason for leaving the school
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography className="contentheading2">
                  : {data?.lastClassDetail?.relievingReason}
                </Typography>
              </Grid>
            </Grid>
            {/*total working days */}
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Typography className="contentheading">
                  18. अंतिम तिथि तक उपस्तिथियो की कुल संख्या
                </Typography>
                <Typography className="contentheading4">
                  No. of meetings up to date.
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography className="contentheading2">
                  : {data?.totalWorkingDays}
                </Typography>
              </Grid>
            </Grid>
            {/*total working days */}
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Typography className="contentheading">
                  19. विद्यार्थी की विद्यालय दिवसो की कुल उपस्तिथि / No. of
                  School
                </Typography>
                <Typography className="contentheading4">
                  days of pupil attended date.
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography className="contentheading2">
                  : {data?.totalPresent}
                </Typography>
              </Grid>
            </Grid>
            {/*general conduct */}
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Typography className="contentheading">
                  20.सामान्य आचरण / General Conduct
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography className="contentheading2">
                  : {data?.generalConduct}
                </Typography>
              </Grid>
            </Grid>
            {/*school category*/}
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Typography className="contentheading">
                  21. क्या विद्यालय सरकारी/अल्पसंख्यक/स्वतंत्र श्रेणी के अंतर्गत
                  है?
                </Typography>
                <Typography className="contentheading4">
                  Whether School is under Govt./Minority/Independent Category
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography className="contentheading2">:</Typography>
              </Grid>
            </Grid>
            {/*Remarks*/}
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Typography className="contentheading">
                  22.कोई अन्य टिप्पणी / Any Other Remarks
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography className="contentheading2">
                  : {data?.remarks}
                </Typography>
              </Grid>
            </Grid>
            {/*Certificate*/}
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Typography className="contentheading">
                  23.प्रमाणपत्र जारी करने की तिथि / Date of issue of certificate
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography className="contentheading2">
                  : {dayjs(data?.certificateIssueDate).format("DD-MM-YYYY")}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          {/* signature */}
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ marginTop: "95px", marginBottom: "1em" }}
          >
            <Typography className="bottomContent">
              तैयारकर्ता / Prepared By <br />( Name & Designation )
            </Typography>

            <Typography className="bottomContent">
              जांचकर्ता / Checked By <br />( Name & Designation )
            </Typography>
            <Typography className="bottomContent">
              हo प्राचार्य / कार्यालय{"  "}
              <span
                style={{
                  fontWeight: 700,
                  fontSize: "14px",
                }}
              >
                मोहर
              </span>{" "}
              <br />( Sign of Principal with Official Seal )
            </Typography>
          </Stack>
        </Box>
      </Wrapper>
    </>
  );
};

export default TcTemplate2;
const Wrapper = styled("div")`
  /* border: 1px solid #000000; */
  // padding: 20px;
  border: 1px solid #000000;
  margin: 1rem;

  .upperTypo {
    font-weight: 600;
    font-size: 12px;
    text-align: center;
  }
  .border {
    background: #ffffff;
    border: 1px solid #b6c8d6;
    border-radius: 12px;
  }
  .upperContent {
    justify-content: space-between;
    /* padding: 15px; */
  }
  .leftTypo {
    font-weight: 400;
    font-size: 12px;
    text-align: center;
  }
  .rightTypo {
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    margin-left: 4em;
    margin-right: 2em;
  }
  .bottomContent {
    font-weight: 700;
    font-size: 12px;
    text-align: center;
    margin-left: 1em;
    margin-right: 3em;
  }
  .contentheading {
    font-weight: 600;
    font-size: 12px;
    margin-top: 10px;
  }
  .contentheading2 {
    font-weight: 600;
    font-size: 12px;
    margin-top: 1rem;
    text-transform: uppercase;
  }
  .contentheading3 {
    font-weight: 600;
    font-size: 12px;
  }
  .contentheading4 {
    font-weight: 600;
    font-size: 12px;
    padding-left: 1.4em;
    padding-right: 1.4em;
  }
`;
