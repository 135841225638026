import {
  Button,
  Checkbox,
  DialogContent,
  FormControlLabel,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CommonFilterDialog from "../../CommonFilterDialog";
import { FilterAlt } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import { MenuProps } from "app/utils/helper";
import { useQuery } from "react-query";
import { getActiveStaffBySchoolDocId } from "app/services/hr";
import { toast } from "react-toastify";
import { StaffCardDropDown } from "app/components/common/StaffCardDropDown";

const AdvanceSalaryFilterDilog = ({ mutate = () => {} }) => {
  const dt = new Date();
  const [state, setState] = useState({
    dialog: false,
    createdFrom: dt,
    createdTo: dt,
    transactionFrom: dt,
    transactionTo: dt,
    staffDocId: "",
  });
  const [checkboxes, setCheckboxes] = useState({
    createdOn: false,
    transactionFrom: false,
    staffDocId: false,
  });
  const handleCloseDialog = () => {
    setState((prev) => ({ ...prev, dialog: false }));
  };
  const handleSave = () => {
    handleCloseDialog();
    mutate({
      ...(checkboxes.createdOn && {
        createdFrom: state?.createdFrom,
        createdTo: state?.createdTo,
      }),
      ...(checkboxes.transactionFrom && {
        transactionFrom: state?.transactionFrom,
        transactionTo: state?.transactionTo,
      }),
    });
  };

  return (
    <div>
      <Button
        variant="contained"
        onClick={() => {
          setState((prev) => ({ ...prev, dialog: true }));
        }}
        color="secondary"
        sx={{
          width: "20rem",
          height: "4rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          borderRadius: "10px",
          background: "#0099ff",
          fontSize: "1.2rem",
        }}
      >
        Filter
        <FilterAlt />
        <Typography
          sx={{
            padding: "0 5px",
            background: "#fff",
            color: "black",
            borderRadius: "50%",
          }}
        >
          {Object.values(checkboxes).filter((item) => item === true).length}
        </Typography>
      </Button>
      <CommonFilterDialog
        open={state.dialog}
        onClose={handleCloseDialog}
        minWidth="400px"
        title="Filter Salary Transaction "
        handleSaveAndView={handleSave}
      >
        <DialogContent>
          <Stack>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkboxes.createdOn}
                  color="secondary"
                  onClick={() => {
                    setCheckboxes((prev) => ({
                      ...prev,
                      createdOn: !prev.createdOn,
                    }));
                  }}
                />
              }
              label="Created On"
            />
            {checkboxes.createdOn && (
              <Stack direction="row" spacing={2}>
                <Stack>
                  <Typography>From</Typography>
                  <DatePicker
                    inputFormat="DD-MM-YYYY"
                    value={state.createdFrom}
                    onChange={(e) => {
                      setState((prev) => ({
                        ...prev,
                        createdFrom: e,
                      }));
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        placeholder="Select"
                        size="small"
                      />
                    )}
                  />
                </Stack>
                <Stack>
                  <Typography>To</Typography>
                  <DatePicker
                    inputFormat="DD-MM-YYYY"
                    value={state.createdTo}
                    onChange={(e) => {
                      setState((prev) => ({
                        ...prev,
                        createdTo: e,
                      }));
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        placeholder="Select"
                        size="small"
                      />
                    )}
                  />
                </Stack>
              </Stack>
            )}
          </Stack>
          <Stack>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkboxes.transactionFrom}
                  color="secondary"
                  onClick={() => {
                    setCheckboxes((prev) => ({
                      ...prev,
                      transactionFrom: !prev.transactionFrom,
                    }));
                    // checkboxes.transactionFrom === true
                    //   ? setState((prev) => ({
                    //       ...prev,
                    //       transactionFrom: dt,
                    //       transactionTo: dt,
                    //     }))
                    //   : setState((prev) => ({ ...prev }));
                  }}
                />
              }
              label="Transaction On"
            />
            {checkboxes.transactionFrom && (
              <Stack direction="row" spacing={2}>
                <Stack>
                  <Typography>From</Typography>
                  <DatePicker
                    inputFormat="DD-MM-YYYY"
                    value={state.transactionFrom}
                    onChange={(e) => {
                      setState((prev) => ({
                        ...prev,
                        transactionFrom: e,
                      }));
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        placeholder="Select"
                        size="small"
                      />
                    )}
                  />
                </Stack>
                <Stack>
                  <Typography>To</Typography>
                  <DatePicker
                    inputFormat="DD-MM-YYYY"
                    value={state.transactionTo}
                    onChange={(e) => {
                      setState((prev) => ({
                        ...prev,
                        transactionTo: e,
                      }));
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        placeholder="Select"
                        size="small"
                      />
                    )}
                  />
                </Stack>
              </Stack>
            )}
          </Stack>
        </DialogContent>
      </CommonFilterDialog>
    </div>
  );
};

export default AdvanceSalaryFilterDilog;
