import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Session, { Medium } from "./components/Session";
import InstituteBrand from "./components/InstituteBrand";
import { screen } from "app/utils/mixins";
import { useSelector, useDispatch } from "react-redux";
import {
  setSelectedSession,
  setSelectedMedium,
  setSessionMonth,
} from "app/modules/schools/operations";
import {
  selectMedium,
  selectSession,
  selectedMediumSelector,
  selectActiveSession,
  userDetailSelector,
} from "app/modules/schools/selectors";
import { userDocIdSelector } from "app/modules/auth/selectors";
import { getLoggedInUserDetail } from "app/modules/schools/thunk";
import { generateNameInitial } from "app/utils/helper";
import { Box, Grid, Stack } from "@mui/material";
import { userPrimaryRoleSelector } from "app/modules/schools/selectors";

import UserProfileDrawer from "./components/UserProfileDrawer";
import { SIZES, THEME } from "app/config/constants";

import dayjs from "dayjs";
import { getAllSessions } from "app/services/management";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
const Header = ({ handleOnClickHamburgeMenu }) => {
  const dispatch = useDispatch();
  const primaryRole = useSelector(userPrimaryRoleSelector);
  const selectedSession = useSelector(selectActiveSession);
  const mediumList = useSelector(selectMedium);
  const selectedMedium = useSelector(selectedMediumSelector);
  const userDocId = useSelector(userDocIdSelector);
  const userDetail = useSelector(userDetailSelector);
  const sessionlist = useSelector(selectSession);
  const [state, setState] = useState({
    showSidebar: false,
  });

  const handleSessionSelection = (session) => {
    dispatch(setSelectedSession({ sessionName: session }));
  };

  const handleMediumSelection = (medium) => {
    dispatch(setSelectedMedium({ medium: medium }));
  };
  useQuery({
    queryKey: ["getAllSessionsInHeader", selectedSession],
    queryFn: () => getAllSessions(),
    onSuccess: (success) => {
      const filteredData = success?.data?.list?.find(
        (item) => item?.sessionName === selectedSession
      );

      if (filteredData !== undefined) {
        const sessionStartDate = dayjs(filteredData.startDate);
        const sessionEndDate = dayjs(filteredData.endDate);
        const months = [];
        const dt = new Date(sessionStartDate);
        for (
          let index = 0;
          index < sessionEndDate.diff(sessionStartDate, "M") + 1;
          index++
        ) {
          const month =
            dt.getMonth() + 1 < 10
              ? `0${dt.getMonth() + 1}`
              : dt.getMonth() + 1;

          months.push(
            dayjs(new Date(`${month}-01-${dt.getFullYear()}`)).format("MMMM")
          );
          dt.setMonth(dt.getMonth() + 1);
        }
        dispatch(setSessionMonth(months));
      }
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
    enabled: selectedSession ? true : false,
  });
  // Fetch user Details
  useEffect(() => {
    dispatch(getLoggedInUserDetail({ staffDocId: userDocId }));
  }, [userDocId]);

  return (
    <StyledContainer>
      <UserProfileDrawer state={state} setState={setState} />
      <div className={"navbar-bg-NX"}>
        <div className="inner-nav">
          <Grid container display={"flex"} alignItems={"center"}>
            <Grid xs={6.5} item>
              <InstituteBrand onClick={handleOnClickHamburgeMenu} />
            </Grid>
            <Grid xs={3.5} item>
              <Stack direction="row" alignItems="center" gap={1.2}>
                <Session
                  handleSessionSelection={handleSessionSelection}
                  selectedSession={selectedSession}
                  sessions={sessionlist}
                />
                <Medium
                  handleMediumSelection={handleMediumSelection}
                  mediumList={mediumList}
                  selectedMedium={selectedMedium}
                />
              </Stack>
            </Grid>
            <Grid xs={2} item>
              <Stack direction="row" alignItems="center" gap={1}>
                {/* <Badge
                  badgeContent={2}
                  variant={"standard"}
                  color="error"
                  overlap="circular"
                >
                  <NotificationsIcon
                    sx={{ fontSize: "25px", color: "#3C6EEF" }}
                  />
                </Badge> */}
                <ProfileNameWrapper>
                  <p
                    className="profile-name"
                    title={`${userDetail?.firstName} ${
                      userDetail?.middleName ?? ""
                    } ${userDetail?.lastName ?? ""}`}
                  >
                    {userDetail?.firstName} {userDetail?.middleName ?? ""}{" "}
                    {userDetail?.lastName ?? ""}
                  </p>
                  <p className="role" style={{ textTransform: "capitalize" }}>
                    {/* {userDetail?.employmentInfo?.designation ?? ""} */}
                    {String(primaryRole).split("_")[1]?.toLowerCase()}
                  </p>
                </ProfileNameWrapper>
                <Box
                  onClick={() =>
                    setState((prev) => ({
                      ...prev,
                      showSidebar: !prev.showSidebar,
                    }))
                  }
                  sx={{ cursor: "pointer" }}
                >
                  {userDetail?.profilePic ? (
                    <img
                      alt="profile-pic"
                      src={userDetail?.profilePic}
                      // className="avatar avatar-24 bg-gray rounded-circle"
                      style={{
                        height: "50px",
                        width: "50px",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <span className="avatar avatar-24 bg-gray rounded-circle text-color p-4 fw-bold">
                      {generateNameInitial(
                        userDetail?.firstName,
                        userDetail?.lastName
                      )}
                    </span>
                  )}
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </div>
      </div>
    </StyledContainer>
  );
};

export default Header;

const ProfileNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .profile-name {
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 126.19%;
    text-align: center;
    color: #0c2f49;
    width: 10vw;
    color: #0c2f49;
    font-weight: 600;
    margin: 0 !important;
  }
  .role {
    text-overflow: ellipsis;
    width: 10rem;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 156.19%;
    color: #0c2f49;
  }
`;

const StyledContainer = styled.div`
  .MuiBadge-badge {
    height: 14px !important;
    width: 14px !important;
    border-radius: 7px !important;
  }
  .navbar-brand {
    svg {
      width: 200px;
    }
  }
  .navbar-bg {
    background: #084c84;
    padding-top: 0px;
    margin-left: 0;
    transition: 0.4s;
    padding-bottom: 0px;
    padding-left: 200px;
  }
  .navbar-bg-NX {
    background: #084c84;
    padding-top: 0px;
    margin-left: 0;
    padding-bottom: 0px;
    transition: 0.4s;
  }
  .inner-nav {
    background: ${THEME.white};
    width: 100%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    height: ${SIZES.desktopNavbarHeight};
    display: flex;
    align-items: center;
  }
  .text-color {
    color: #084c84;
  }
  ${screen.medium.standard`
      width: 100px
    `};
`;
