import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Stack,
} from "@mui/material";
import * as XLSX from "xlsx";
import DialogHeader from "../DialogHeader";

const ExportDialog3 = ({
  open = false,
  handleClose = () => {},
  header = [],
  fileName = "Report",
  exportArray = [],
  extraSelection = false,
  extraSelectionHeaderName = "",
  dynamicHeaders = [],
  setIncludeEarningDeduction,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [includeExtraSelection, setIncludeExtraSelection] = useState(false);

  const handleCloseDialog = () => {
    handleClose();
  };

  const handleCheckboxChange = (option, isChecked) => {
    if (isChecked) {
      setSelectedOptions((prevSelectedOptions) => [
        ...prevSelectedOptions,
        option,
      ]);
    } else {
      setSelectedOptions((prevSelectedOptions) =>
        prevSelectedOptions.filter(
          (selectedOption) => selectedOption !== option
        )
      );
    }
  };

  const handleExport = () => {
    const filteredExportArray = exportArray.map((item) => {
      const filteredItem = {};
      selectedOptions.forEach((option) => {
        filteredItem[option] = item[option];
      });

      if (includeExtraSelection) {
        dynamicHeaders.forEach((headerGroup) => {
          filteredItem[headerGroup.config] = item[headerGroup.config];
          filteredItem[headerGroup.deduction] = item[headerGroup.deduction];
          filteredItem[headerGroup.amount] = item[headerGroup.amount];
          filteredItem[headerGroup.paymentMode] = item[headerGroup.paymentMode];
        });
      }

      return filteredItem;
    });

    // Create the two-row header structure
    const firstRow = [...selectedOptions];
    const secondRow = new Array(selectedOptions.length).fill("");

    if (includeExtraSelection) {
      dynamicHeaders.forEach((headerGroup) => {
        firstRow.push(headerGroup.headName, "", "", "");
        secondRow.push("Config", "Deduction", "Amount", "Payment Mode");
      });
    }

    const worksheetData = [
      firstRow,
      secondRow,
      ...filteredExportArray.map((item) => Object.values(item)),
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    // Define merges for the first row
    const merges = [];
    let colIndex = selectedOptions.length;
    if (includeExtraSelection) {
      dynamicHeaders.forEach(() => {
        merges.push({
          s: { r: 0, c: colIndex },
          e: { r: 0, c: colIndex + 3 },
        });
        colIndex += 4;
      });
    }

    worksheet["!merges"] = merges;

    // Center align the merged cells
    merges.forEach((merge) => {
      for (let C = merge.s.c; C <= merge.e.c; ++C) {
        const cell = worksheet[XLSX.utils.encode_cell({ r: merge.s.r, c: C })];
        if (cell) {
          cell.s = {
            alignment: {
              horizontal: "center",
              vertical: "center",
            },
          };
        }
      }
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, fileName);
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  useEffect(() => {
    setSelectedOptions(header);
  }, [header]);

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      PaperProps={{
        sx: { minWidth: "400px" },
      }}
    >
      <DialogHeader
        title="Select Information to show in the excel file"
        handleClose={handleCloseDialog}
      />
      <DialogContent>
        <Box>
          {extraSelection && (
            <Stack direction="row">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={includeExtraSelection}
                    onChange={(e) => {
                      setIncludeExtraSelection(e.target.checked);
                      setIncludeEarningDeduction(e.target.checked);
                    }}
                  />
                }
                label={extraSelectionHeaderName}
              />
            </Stack>
          )}
          {header.map((option) => (
            <Stack direction="row" key={option}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedOptions.includes(option)}
                    onChange={(e) =>
                      handleCheckboxChange(option, e.target.checked)
                    }
                  />
                }
                label={option}
              />
            </Stack>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{ mb: 1 }}
          onClick={() => {
            handleExport();
            handleCloseDialog();
          }}
        >
          Download
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportDialog3;
