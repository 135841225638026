import { Box, Button, Grid, Stack, Typography, colors } from "@mui/material";
import styled from "styled-components";
import { useRef, useState } from "react";
//import for our files
import { mobile } from "app/modules/reception/components/responsive";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { IndianCurrency, NumberInWord } from "app/utils/helper";
import dayjs from "dayjs";
import { getUtilityInvoice } from "app/services/ledgerAccount";
import CustomLoader from "app/components/common/CustomLoader";
import SchoolPrintUtilityHeader from "app/components/common/SchoolPrintUtilityHeader";
const LabelText = ({ label, text }) => {
  return (
    <>
      <Grid item xs={2}>
        <Typography sx={{ fontWeight: 600, fontSize: "10px" }}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography sx={{ fontWeight: 400, fontSize: "10px" }}>
          : {text}
        </Typography>
      </Grid>
    </>
  );
};
const LabelText2 = ({ label, text }) => {
  return (
    <>
      <Grid item xs={3}>
        <Typography sx={{ fontWeight: 600, fontSize: "10px" }}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography sx={{ fontWeight: 400, fontSize: "10px" }}>
          : {text}
        </Typography>
      </Grid>
    </>
  );
};
const LabelText3 = ({ label, text }) => {
  return (
    <>
      <Grid item xs={2}>
        <Typography sx={{ fontWeight: 600, fontSize: "10px" }}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={5.5}>
        <Typography sx={{ fontWeight: 400, fontSize: "10px" }}>
          : {text}
        </Typography>
      </Grid>
    </>
  );
};
const LabelText4 = ({ label, text }) => {
  return (
    <>
      <Grid item xs={1.5}>
        <Typography sx={{ fontWeight: 600, fontSize: "10px" }}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={2.5}>
        <Typography sx={{ fontWeight: 400, fontSize: "10px" }}>
          : {text}
        </Typography>
      </Grid>
    </>
  );
};

export default function PaySlip() {
  const invoicePrintRef = useRef(null);
  const [data, setData] = useState({});
  const { id } = useParams();

  const handlePrint = useReactToPrint({
    content: () => invoicePrintRef.current,
  });
  const { isLoading, isFetching } = useQuery({
    queryKey: "getUtilityInvoiceDetails",
    queryFn: () =>
      getUtilityInvoice({ invoiceNumber: String(id).replace(/ /g, "/") }),
    onSuccess: (res) => {
      setData(res?.data?.obj);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });
  const firstDiv = (
    <LeftSideDiv>
      <SchoolPrintUtilityHeader data={data?.schoolFirm} />
      <InvoiceHeader>
        <div
          style={{
            width: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              fontWeight: "600",
              fontSize: "16px",

              textTransform: "capitalize",
              textAlign: "center",
              color: "#FFFFFF",
              backgroundColor: "#0C4B7E",
            }}
          >
            Invoice - {`${data?.session}`}
          </div>
        </div>
      </InvoiceHeader>
      <InvoiceBody>
        <Grid container sx={{ px: 1, pt: 0.5 }}>
          <LabelText3 label="Invoice No." text={data?.invoiceNumber} />
          <LabelText4
            label="Collection Date"
            text={moment(new Date(data?.collectionDate)).format("DD/MM/YYYY")}
          />
        </Grid>
        <Box sx={{ backgroundColor: "#000", my: 0.3, height: "1px" }} />
        {data?.payingPersonRole === "Student" && (
          <Grid container sx={{ px: 1 }}>
            <LabelText2
              label="Student's Name"
              text={data?.studentDetail?.studentName}
            />
            <LabelText
              label="Reg No."
              text={data?.studentDetail?.registrationNumber}
            />
            <LabelText2
              label="Father's Name"
              text={data?.studentDetail?.fatherName}
            />
            <LabelText
              label="Mob No."
              text={data?.studentDetail?.studentMobileNumber}
            />
            <LabelText2
              label="Admission No."
              text={data?.studentDetail?.admissionNumber}
            />
            <LabelText
              label="SR Number"
              text={data?.studentDetail?.srNumber ?? "--"}
            />
            <LabelText2
              label="ClassName"
              text={`${data?.studentDetail?.className}-
                      ${data?.studentDetail?.stream}-${data?.studentDetail?.section}`}
            />
            <LabelText label="Gender" text={data?.studentDetail?.gender} />

            {/* <LabelText
            label="Father's Name"
            text={data?.studentDetail?.fatherName}
          />
          <LabelText text={data?.studentDetail?.rollNumber} label="Roll no." />
          <LabelText
            label="DOB"
            text={
              data?.studentDetail?.DOB && formatDate(data?.studentDetail?.DOB)
            }
          /> */}
          </Grid>
        )}
        {(data?.payingPersonRole === "Other" ||
          data?.payingPersonRole === "Staff") && (
          <Grid container sx={{ px: 1 }}>
            <LabelText2
              label="Paying Person Name"
              text={data?.payingPerson?.personName}
            />
          </Grid>
        )}

        <Grid container sx={{ background: "#ffaf0d", py: 1, px: 1, mx: 0.1 }}>
          <Grid item xs={2}>
            <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
              Discription
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
              UOM
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
              Quantity
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
              Rate
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
              Discount
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
              Amount
            </Typography>
          </Grid>
        </Grid>
        {data?.invoiceItems?.map((item, ind) => (
          <Grid
            key={ind}
            container
            sx={{
              py: 0.1,
              mb: 1,
              px: 1,
              padding: "5px",
              background: "rgba(61, 57, 137, 0.1)",
            }}
          >
            <Grid item xs={2}>
              <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                {item?.description}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                {item?.UOM}
              </Typography>
            </Grid>
            {/* Quantity */}
            <Grid item xs={2}>
              <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                {item?.quantity}
              </Typography>
            </Grid>
            {/* rate */}
            <Grid item xs={2}>
              <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                {IndianCurrency(Number(item?.rate))}
              </Typography>
            </Grid>

            {/* Discount */}
            <Grid item xs={2}>
              <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                {item?.discountAmount}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                {IndianCurrency(Number(item?.itemAmount))}
              </Typography>
            </Grid>
          </Grid>
        ))}
        <Grid container sx={{ px: 1, pt: 0.3, borderTop: "1px solid #000" }}>
          <Grid item xs={8}>
            <Typography sx={{ fontSize: "10px" }}>
              Discount Remarks:{" "}
              <span style={{ fontWeight: 600 }}>{data?.discountRemark}</span>
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
              {IndianCurrency(Number(data?.totalDiscount))}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
              {IndianCurrency(Number(data?.totalAmount))}
            </Typography>
          </Grid>
        </Grid>

        <Grid container sx={{ px: 1, pt: 0.3, borderTop: "1px solid #000" }}>
          {/* previous dues of paying amount stuff */}
          <Grid item xs={9}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: "10px" }}>Previous Dues</Typography>
                <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                  {data?.previousDuesRemark}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: "10px", color: colors.red[600] }}>
                  {IndianCurrency(Number(data?.previousDuesAmount))}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: "10px" }}>Paying Amount</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                  {IndianCurrency(Number(data?.paidAmount))}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {/* grand total or tax stuff */}
          <Grid item xs={3}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: "10px" }}>Grand Total</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                  {IndianCurrency(data?.grandTotalAmount)}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: "10px" }}>Tax (if Any)</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                  {IndianCurrency(data?.taxAmount)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container sx={{ px: 1, pt: 0.3, borderTop: "1px solid #000" }}>
          <Grid item xs={9}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: 600 }}>Paid Amount</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: "10px" }}>
                  {NumberInWord(data?.paidAmount)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
              {IndianCurrency(Number(data?.paidAmount))}
            </Typography>
            <Typography sx={{ fontSize: "10px" }}>
              {data?.paymentDetails &&
                Object?.keys(data?.paymentDetails)?.length > 0 &&
                Object.keys(data?.paymentDetails)[0]}
            </Typography>
          </Grid>
        </Grid>

        {/* <Grid container sx={{ px: 1, pt: 0.3, borderTop: "1px solid #000" }}>
        
          {data?.paymentDetails?.UpiMode && (
            <Grid item xs={8}>
              <Stack direction={"row"} justifyContent={"space-around"}>
                <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                  UPI Number: {data?.paymentDetails?.UpiMode?.upiNumber}
                </Typography>
                <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                  Transaction ID: {data?.paymentDetails?.UpiMode?.transactionId}
                </Typography>
                <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                  Amount: {data?.paymentDetails?.UpiMode?.amount}
                </Typography>
              </Stack>
            </Grid>
          )}
          {data?.paymentDetails?.CardMode && (
            <Grid item xs={8}>
              <Stack direction={"row"} justifyContent={"space-around"}>
                <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                  Card Holder Name:{" "}
                  {data?.paymentDetails?.CardMode?.cardHolderName}
                </Typography>
                <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                  Card Number: {data?.paymentDetails?.CardMode?.cardNumber}
                </Typography>
                <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                  Amount: {data?.paymentDetails?.CardMode?.amount}
                </Typography>
              </Stack>
            </Grid>
          )}
          {data?.paymentDetails?.ChequeMode && (
            <Grid item xs={8}>
              <Stack direction={"row"} justifyContent={"space-around"}>
                <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                  Cheque No.: {data?.paymentDetails?.ChequeMode?.chequeNumber}
                </Typography>

                <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                  Amount: {data?.paymentDetails?.ChequeMode?.amount}
                </Typography>
                <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                  Deposition Date:{" "}
                  {dayjs(
                    data?.paymentDetails?.ChequeMode?.depositionDate
                  ).format("DD MMM YYYY")}
                </Typography>
              </Stack>
            </Grid>
          )}
          {data?.paymentDetails?.NEFTMode && (
            <Grid item xs={8}>
              <Stack direction={"row"} justifyContent={"space-around"}>
                <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                  Reference No.:{" "}
                  {data?.paymentDetails?.NEFTMode?.referenceNumber}
                </Typography>
                <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                  Amount: {data?.paymentDetails?.NEFTMode?.amount}
                </Typography>
                <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                  Transaction No.:{" "}
                  {data?.paymentDetails?.NEFTMode?.transactionNumber}
                </Typography>
              </Stack>
            </Grid>
          )}
          {data?.paymentDetails?.CashMode && (
            <Grid item xs={8}>
              <Stack direction={"row"} justifyContent={"space-around"}>
                <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                  Amount: {data?.paymentDetails?.CashMode?.amount}
                </Typography>
              </Stack>
            </Grid>
          )}
        </Grid> */}

        <Box sx={{ backgroundColor: "#000", my: 0.3, height: "1px" }} />
        <Grid container sx={{ px: 1 }}>
          <Grid item xs={9}>
            <Typography sx={{ fontSize: "10px" }}>
              Balance Amount:{" "}
              <span style={{ color: "red" }}>
                {IndianCurrency(Number(data?.balanceAmount))}
              </span>{" "}
              <span style={{ color: "red", fontWeight: 600 }}>
                ({data?.balanceRemark ?? "--"})
              </span>
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ backgroundColor: "#000", my: 0.3, height: "1px" }} />
        <Stack direction="row" justifyContent="space-between" sx={{ px: 1 }}>
          <Box>
            <Typography sx={{ fontSize: "10px" }}>
              Created By : {data?.createdBy?.personName}
            </Typography>
            <Typography sx={{ fontSize: "10px" }}>
              Created On : {dayjs(data?.createdOn).format("DD MMM YYYY")}
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ fontSize: "10px" }}>For,</Typography>
            <Typography sx={{ fontSize: "10px" }}>
              {data?.schoolName}
            </Typography>
            <Typography sx={{ fontSize: "10px", pt: 2, pb: 1 }}>
              Authorised Sign.
            </Typography>
          </Box>
        </Stack>
      </InvoiceBody>
    </LeftSideDiv>
  );

  return (
    <>
      <CustomLoader show={isLoading || isFetching} />
      <Box>
        <Wrapper>
          <Stack direction={"row"} justifyContent={"center"} my={1}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              sx={{ width: "220px" }}
              onClick={() => {
                handlePrint();
              }}
            >
              Print
            </Button>
          </Stack>

          {firstDiv}
        </Wrapper>
        <Box sx={{ display: "none" }}>
          <Stack direction="row" sx={{ p: 1 }} gap={0.5} ref={invoicePrintRef}>
            {firstDiv}
            {firstDiv}
          </Stack>
        </Box>
      </Box>
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5rem;
  padding-top: 1rem;
  ${mobile({ width: "100%", height: "100%" })}
  .particular_head {
    width: 100%;
  }
  align-items: center;
`;

const LeftSideDiv = styled.div`
  width: 60%;
  margin: 2px;
  height: 100%;
  border: 1px solid black;
`;

const InvoiceHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InvoiceBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
