import {
  selectActiveSession,
  selectSelectedSchool,
  userNameSelector,
} from "app/modules/schools/selectors";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import PdfSummaryPreviewDialog from "../common/Dialogs/PdfSummaryPreviewDialog";
import jsPDF from "jspdf";
import reportHeader from "../common/pdfReport/reportHeader";
import { calculateTotalsAndAppendSummaryRowPdf } from "app/utils/helper";
import autoTable from "jspdf-autotable";
import Footer from "../common/pdfReport/ReportFooter";

const CommonPdfReport = ({
  header = [],
  exportArray,
  filterData = {},
  exportTitle = "Fee Collection.pdf",
  showFiter = false,
  open = false,
  onClose = () => {},
  totalColumns = [],
}) => {
  const session = useSelector(selectActiveSession);
  const selectedSchool = useSelector(selectSelectedSchool);
  const [selectedOptions, setSelectedOptions] = useState(header);
  const userName = useSelector(userNameSelector);

  const handleExportPdf = async () => {
    const doc = new jsPDF({
      orientation: "landscape",
    });

    const rows = [];
    exportArray.forEach((item) => {
      const row = selectedOptions?.map((option) => item[option]);
      rows.push(row);
    });
    const tableColumn = selectedOptions?.map((col) => ({
      title: col,
      dataKey: col,
    }));
    const tableStyles = {
      headStyles: {
        fillColor: [182, 200, 214],
        textColor: 0,
        fontSize: 8,
        cellWidth: "auto",
      },
      bodyStyles: {
        fontSize: 8,
        cellWidth: "auto",
        textColor: [0, 0, 0],
      },
      columnStyles: {},
      theme: "grid",
    };

    const totalPagesExp = "{total_pages_count_string}";
    let filterTexts = [];

    // report header
    reportHeader({
      doc: doc,
      selectedSchool: selectedSchool,
    });

    if (filterData) {
      Object.keys(filterData)
        .filter((key) => filterData[key])
        .forEach((key) => {
          const formattedKey =
            key.charAt(0).toUpperCase() +
            key.slice(1).replace(/([A-Z])/g, " $1");
          const value = filterData[key] || "--";
          filterTexts.push(`${formattedKey}: ${value}`);
        });
    }

    let yaxis = 20;

    if (filterTexts.length > 0) {
      doc.text(`Applied Filters:`, 15, yaxis);

      yaxis += 6;
      let xaxis = 15;
      const lineHeight = 6;
      const maxLineWidth = 180;

      filterTexts.forEach((filterText, index) => {
        const textWidth =
          doc.getStringUnitWidth(filterText) * doc.getFontSize();

        if (xaxis + textWidth > maxLineWidth) {
          xaxis = 15;
          yaxis += lineHeight;
        }

        doc.text(filterText, xaxis, yaxis);
        xaxis += textWidth;
      });

      yaxis += 10;
    }

    rows.push(
      calculateTotalsAndAppendSummaryRowPdf(
        rows,
        setSelectedOptions,
        totalColumns
      )
    );

    autoTable(doc, {
      head: [tableColumn],
      body: rows,
      startY: 30,
      didDrawPage: (data) => {
        Footer({
          doc: doc,
          data: data,
          userName: userName,
          totalPagesExp: totalPagesExp,
        });
      },
      ...tableStyles,
    });

    if (typeof doc.putTotalPages === "function") {
      doc.putTotalPages(totalPagesExp);
    }

    doc.save(exportTitle);
  };
  return (
    <>
      <PdfSummaryPreviewDialog
        excel={true}
        allSelected={true}
        open={open}
        handleClose={onClose}
        data={header}
        setSelectedOptionsForPdf={setSelectedOptions}
        selectedOptionsForPdf={selectedOptions}
        handleExportPdf={handleExportPdf}
      />
    </>
  );
};

export default CommonPdfReport;
