import { LoadingButton } from "@mui/lab";
import {
  Stack,
  styled,
  Select,
  MenuItem,
  DialogContent,
  Box,
  DialogActions,
  Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import {
  addPayrollHead,
  deletePayrollHead,
  getPayrollHeads,
} from "app/services/hr";
import CustomLoader from "app/components/common/CustomLoader";
import CommonDialog from "app/components/common/Dialogs/CommonDialog";
import { Helmet } from "react-helmet";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import ErrorText from "app/modules/student/pages/CreateStudent/components/ErrorText";
import TableActionButtons from "app/components/common/TableActionButtons";
import DeleteConfirmationDialog from "app/components/common/Dialogs/DeleteConfirmationDialog";
import CommonTable1 from "app/components/Tables/CommonTable1";
import { IndianCurrency } from "app/utils/helper";

const HeadsPage = () => {
  const [state, setState] = useState({
    selectedHead: "",
    filtered: null,
    active: null,
    status: {},
    head: "Earning",
    headName: "",
    headError: false,
    addDialog: false,
    deleteHeadDocId: "",
    deleteConfirmationDialog: false,
  });

  const heads = ["Earning", "Deduction"];

  const handleCloseAddDialog = () => {
    setState((prev) => ({ ...prev, addDialog: false, headName: "" }));
  };
  const handleSubmit = () => {
    if (!String(state.headName).trim()) {
      setState((prev) => ({ ...prev, headError: "Head name required" }));
      return;
    }
    addPayrollHeadMutate({
      headType: state.head,
      headName: state.headName,
    });
  };
  const handleDelete = () => {
    deletePayrollHeadMutate(state.deleteHeadDocId);
  };
  const handleCloseDeleteDialog = () => {
    setState((prev) => ({
      ...prev,
      deleteConfirmationDialog: false,
      deleteHeadDocId: "",
    }));
  };
  const {
    isLoading: getPayrollHeadsLoading,
    isFetching: getPayrollHeadsFetching,
    refetch: getPayrollHeadsRefetch,
    data: getPayrollHeadsData,
  } = useQuery({
    queryKey: ["getAllPayrollHeads"],
    queryFn: () => {
      if (state.selectedHead) {
        return getPayrollHeads({
          headType: state.selectedHead,
          withAmount: "Yes",
        });
      }
      return getPayrollHeads({ withAmount: "Yes" });
    },

    onSuccess: (res) => {
      setState((prev) => ({ ...prev, filtered: res?.data?.list }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const { mutate: addPayrollHeadMutate, isLoading: addPayrollHeadLoading } =
    useMutation(addPayrollHead, {
      onSuccess: (res) => {
        toast.success(res?.data?.message);
        handleCloseAddDialog();
        getPayrollHeadsRefetch();
      },
      onError: (err) => {
        toast.error(err?.response?.data?.message);
      },
    });

  const {
    mutate: deletePayrollHeadMutate,
    isLoading: deletePayrollHeadLoading,
  } = useMutation(deletePayrollHead, {
    onSuccess: (res) => {
      toast.success(res?.data?.message);
      getPayrollHeadsRefetch();
      handleCloseDeleteDialog();
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });
  const columns = useMemo(() => {
    return [
      {
        header: "Sr.No",
        Cell: ({ cell }) => (
          <Typography>
            {parseInt(cell.row.id) < 9
              ? `0${parseInt(cell.row.id) + 1}`
              : parseInt(cell.row.id) + 1}
          </Typography>
        ),
        size: 20,
      },
      {
        header: "Head Type",
        accessorKey: "headType",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <>
              <Typography>{original?.headType}</Typography>
            </>
          );
        },
        size: 30,
      },
      {
        header: "Head Name",
        accessorKey: "headName",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return <Typography>{original?.headName}</Typography>;
        },
        size: 40,
      },

      {
        header: "Amount",
        accessorKey: "amount",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return <Typography>{IndianCurrency(original?.amount)}</Typography>;
        },
        size: 30,
      },

      {
        header: "Actions",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <TableActionButtons
              showDelete
              handleDelete={() => {
                setState((prev) => ({
                  ...prev,
                  deleteHeadDocId: original?._id,
                  deleteConfirmationDialog: true,
                }));
              }}
            />
          );
        },
        size: 20,
      },
    ];
  }, []);
  return (
    <>
      <Helmet>
        <title>Payroll | Salary Heads</title>
      </Helmet>
      <CustomLoader show={getPayrollHeadsLoading || getPayrollHeadsFetching} />
      <DeleteConfirmationDialog
        handleDelete={handleDelete}
        loading={deletePayrollHeadLoading}
        onClose={handleCloseDeleteDialog}
        open={state.deleteConfirmationDialog}
      />
      <CommonDialog
        open={state.addDialog}
        onClose={handleCloseAddDialog}
        title="Add Payroll Head"
        minWidth="400px"
      >
        <DialogContent>
          <Box mt={2}>
            <CustomSelectBox
              fullWidth
              value={state.head}
              onChange={(e) =>
                setState((prev) => ({ ...prev, head: e.target.value }))
              }
            >
              {heads?.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </CustomSelectBox>
            <Box mt={2}>
              <CustomTextField
                placeholder="Type head name"
                fullWidth
                value={state.headName}
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    headName: e.target.value,
                    headError: "",
                  }))
                }
                error={state.headError ? true : false}
              />
              {state.headError && <ErrorText text={state.headError} />}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            color="secondary"
            size="large"
            loading={addPayrollHeadLoading}
            onClick={handleSubmit}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </CommonDialog>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mt: 2 }}
      >
        <Stack direction="row" alignItems="center" spacing={2} marginRight={2}>
          <CustomSelect
            displayEmpty
            value={state?.selectedHead}
            onChange={(e) =>
              setState((prev) => ({ ...prev, selectedHead: e.target.value }))
            }
          >
            <MenuItem value={""}>
              <em>-Select Head-</em>
            </MenuItem>
            {heads.map((item) => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </CustomSelect>
          <LoadingButton
            variant="contained"
            color="secondary"
            size="large"
            loading={getPayrollHeadsLoading || getPayrollHeadsFetching}
            onClick={getPayrollHeadsRefetch}
          >
            View
          </LoadingButton>
        </Stack>

        <LoadingButton
          variant="contained"
          color="secondary"
          size="large"
          sx={{
            width: "20rem",
            height: "4rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            borderRadius: "10px",
            background: "#0099ff",
            fontSize: "1.2rem",
          }}
          onClick={() => setState((prev) => ({ ...prev, addDialog: true }))}
        >
          Create New Head
        </LoadingButton>
      </Stack>
      <Box mt={1}>
        <CommonTable1
          columns={columns}
          data={getPayrollHeadsData?.data?.list}
          maxHeight="50vh"
        />
      </Box>
    </>
  );
};

export default HeadsPage;

const CustomSelect = styled(Select)(({ theme }) => ({
  height: "40px",
  width: "200px",
  backgroundColor: "#fff",
  color: "#333",
  borderRadius: "7px",
}));
