import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createStateSyncMiddleware } from "redux-state-sync";
import { withReduxStateSync } from "redux-state-sync";

import authReducer from "app/modules/auth/slice";
import schoolsReducer from "app/modules/schools/slice";
import receptionReducer from "app/modules/reception/slice";
import feeCollectionReducer from "app/modules/fee-management/fee-collection/slice";
import examControlReducer from "app/modules/exam-control/slice";
import studentManagementReducer from "app/modules/student/slice";
import commonSlice from "./services/commonSlice";

const persistConfig = {
  key: "root",
  storage: storage,
};

// redux-state-sync config
const config = {
  blacklist: ["persist/PERSIST", "persist/REHYDRATE"],
};
const synckStateMiddleware = [createStateSyncMiddleware(config)];

const rootReducer = combineReducers({
  auth: authReducer,
  schools: schoolsReducer,
  reception: receptionReducer,
  feeCollection: feeCollectionReducer,
  examControl: examControlReducer,
  studentManagement: studentManagementReducer,
  commonStore: commonSlice,
});

const persistedReducer = persistReducer(
  persistConfig,
  withReduxStateSync(rootReducer)
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(synckStateMiddleware),
  devTools: false,
});

export const persistor = persistStore(store);
