import { Delete } from "@mui/icons-material";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Typography,
  colors,
  styled,
} from "@mui/material";

import dayjs from "dayjs";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { IndianCurrency } from "app/utils/helper";
import {
  deleteStaffDebt,
  getStaffDebtTransactions,
} from "app/services/schoolService/hr.service";
import CustomLoader from "app/components/common/CustomLoader";
import CommonDialog from "../../CommonDialog";
import DeleteConfirmationDialog from "../../DeleteConfirmationDialog";

const DebtTransactionDetailDialog = ({
  open = false,
  onClose = () => {},
  id = "",
  refetch = () => {},
}) => {
  const [state, setState] = useState({
    deleteInvoiceId: "",
  });
  const handleDeleteInvoice = () => {
    deleteStaffDebtMutate(state.deleteInvoiceId);
  };
  const {
    isFetching: getStaffDebtTransactionsFetching,
    isLoading: getStaffDebtTransactionsLoding,
    data: getStaffDebtTransactionsData,
    refetch: getStaffDebtTransactionsRefetch,
  } = useQuery({
    queryKey: ["getStaffDebtTransactionsById"],
    queryFn: () => getStaffDebtTransactions({ staffDocId: id }),
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
    enabled: open ? true : false,
  });
  const { mutate: deleteStaffDebtMutate, isLoading: deleteStaffDebtLoading } =
    useMutation({
      mutationFn: deleteStaffDebt,
      onSuccess: (success) => {
        toast.success(success?.data?.message);
        getStaffDebtTransactionsRefetch();
        setState((prev) => ({ ...prev, deleteInvoiceId: "" }));
        refetch();
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
    });
  return (
    <>
      <CustomLoader
        show={
          getStaffDebtTransactionsFetching || getStaffDebtTransactionsLoding
        }
      />
      <DeleteConfirmationDialog
        handleDelete={handleDeleteInvoice}
        loading={deleteStaffDebtLoading}
        onClose={() => setState((prev) => ({ ...prev, deleteInvoiceId: "" }))}
        open={state.deleteInvoiceId ? true : false}
      />
      <CommonDialog
        open={open}
        onClose={onClose}
        minWidth="900px"
        title="Debt Transaction Detail"
      >
        <InvoiceHeading>
          <Grid container spacing={1} p={1} columns={12}>
            <Grid item xs={2}>
              <Typography className="invoice_head__text">
                Transaction Date
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className="invoice_head__text">
                Opening Amt
              </Typography>
              <Typography
                className="invoice_head__text"
                sx={{ color: colors.blue[500] }}
              >
                Closing Amt
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                className="invoice_head__text"
                sx={{ color: colors.green[500] }}
              >
                Deposit
              </Typography>
              <Typography
                className="invoice_head__text"
                sx={{ color: colors.red[500] }}
              >
                Withdrawl
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className="invoice_head__text">Remarks</Typography>
              <Typography className="invoice_head__text">Ref. No</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className="invoice_head__text">Created By</Typography>
              <Typography className="invoice_head__text">Created On</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography className="invoice_head__text">Action</Typography>
            </Grid>
          </Grid>
          <Divider sx={{ background: "#000" }} />
          {getStaffDebtTransactionsData?.data.list?.map((item, i) => (
            <Box key={i}>
              <Grid
                container
                spacing={1}
                p={1}
                columns={12}
                display={"flex"}
                alignItems={"center"}
              >
                <Grid item xs={2}>
                  <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                    {dayjs(item?.transactionDate).format("DD MMM, YYYY")}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                    {IndianCurrency(item?.openingAmount)}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: 500,
                      color: colors.blue[500],
                    }}
                  >
                    {IndianCurrency(item?.closingAmount)}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: 500,
                      color: colors.green[500],
                    }}
                  >
                    {IndianCurrency(item?.depositAmount)}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: 500,
                      color: colors.red[500],
                    }}
                  >
                    {IndianCurrency(item?.withdrawalAmount)}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                    {item?.remarks}
                  </Typography>
                  <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                    {item?.referenceNumber}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                    {item?.createdBy?.personName}
                  </Typography>
                  <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                    {dayjs(item?.createdOn).format("DD MMM, YYYY")}
                  </Typography>
                </Grid>
                <Grid item xs={1} display={"flex"} justifyContent={"center"}>
                  <IconButton
                    onClick={() =>
                      setState((prev) => ({
                        ...prev,
                        deleteInvoiceId: item?._id,
                      }))
                    }
                  >
                    <Delete color="error" />
                  </IconButton>
                </Grid>
              </Grid>
              <Divider
                sx={{ background: (theme) => theme.palette.primary.main }}
              />
            </Box>
          ))}
        </InvoiceHeading>
      </CommonDialog>
    </>
  );
};

export default DebtTransactionDetailDialog;
const InvoiceHeading = styled(Box)`
  background-color: rgba(246, 248, 252, 1);
  .invoice_head__text {
    color: #0c2f49;
    font-size: 14px;
    font-weight: 500;
  }
`;
