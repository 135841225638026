import { DialogContent, Grid } from "@mui/material";

import dayjs from "dayjs";
import React from "react";
import CommonDialog from "../CommonDialog";
import LabelTextComponent from "../../LabelTextComponent";
import { IndianCurrency } from "app/utils/helper";

const OrderDetailDialog = ({ data = {}, open = false, onClose = () => {} }) => {
  return (
    <CommonDialog
      onClose={onClose}
      open={open}
      title="Order Detail"
      minWidth="900px"
    >
      <DialogContent>
        <Grid container spacing={1} columns={12}>
          <Grid item xs={12} lg={3}>
            <LabelTextComponent
              label={"Product Type"}
              text={data?.productDetail?.productType}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <LabelTextComponent
              label={"Product Name"}
              text={data?.productDetail?.productName}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <LabelTextComponent
              label={"Product Description"}
              text={data?.productDetail?.productDescription}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} py={1.5} columns={14}>
          <Grid item xs={14} lg={1.5}>
            <LabelTextComponent
              label={"UOM"}
              text={data?.productDetail?.unitOfMesurement}
            />
          </Grid>
          <Grid item xs={14} lg={2}>
            <LabelTextComponent
              label={"Rate"}
              text={data?.productDetail?.rate}
            />
          </Grid>

          <Grid item xs={14} lg={2}>
            <LabelTextComponent
              label={"Discounted Rate"}
              text={data?.discountedRate}
            />
          </Grid>
          <Grid item xs={14} lg={1}>
            <LabelTextComponent
              label={"Factor"}
              text={data?.productDetail?.factor}
            />
          </Grid>
          <Grid item xs={14} lg={1.5}>
            <LabelTextComponent label={"Qty"} text={data?.boughtQuantity} />
          </Grid>
          <Grid item xs={14} lg={2}>
            <LabelTextComponent
              label={"Final Amount"}
              text={IndianCurrency(
                Number(data?.discountedRate ?? 0) *
                  Number(data?.boughtQuantity ?? 0) *
                  Number(data?.productDetail?.factor ?? 0)
              )}
            />
          </Grid>
          <Grid item xs={14} lg={2}>
            <LabelTextComponent
              label={"Due Date"}
              text={dayjs(data?.dueDate).format("DD MMM, YYYY")}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </CommonDialog>
  );
};

export default OrderDetailDialog;
