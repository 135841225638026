import {
  CircularProgress,
  Dialog,
  DialogTitle,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

import Draggable from "react-draggable";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const CommonFilterDialog = ({
  open = false,
  onClose = () => {},
  minWidth = "400px",
  title = "Filter",
  loading = false,
  handleSaveAndView = () => {},
  children,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperComponent={PaperComponent}
      PaperProps={{
        sx: { minWidth: { xs: "98%", md: minWidth } },
      }}
    >
      <DialogTitle sx={{ p: "0 !important" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ py: 1, px: 2 }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              ":hover": { cursor: "-webkit-grab" },
            }}
            color="primary"
            id="draggable-dialog-title"
          >
            {title}
          </Typography>
          {loading ? (
            <CircularProgress size={25} />
          ) : (
            <Typography
              onClick={handleSaveAndView}
              sx={{
                fontSize: "16px",
                color: "#28789c",
                textTransform: "capitalize",
                cursor: "pointer",
              }}
            >
              Save & View
            </Typography>
          )}
        </Stack>
        <Divider sx={{ backgroundColor: "#0C2F49", mb: 1 }} />
      </DialogTitle>
      {children}
    </Dialog>
  );
};

export default CommonFilterDialog;
