import React from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import PayrollPage from "../../components/PayrollPage";
import AdvancePage from "../../components/AdvancePage";
import HeadsPage from "../../components/HeadsPage";
import { TabsWrapper } from "assets/styles/globalStyledComponent";
import { Container, Stack } from "@mui/material";
import { Helmet } from "react-helmet";
import SalaryTransactions from "../../components/SalaryTransactions";
import SecurityMoney from "../../components/securityMoney/SecurityMoney";

const StaffPayroll = () => {
  const [value, setValue] = React.useState("payroll");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Helmet>
        <title>Payroll</title>
      </Helmet>
      <Container maxWidth="xl">
        <TabContext value={value}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ px: 2 }}
          >
            <TabsWrapper>
              <TabList onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Payroll" value="payroll" />
                <Tab label="Advance Salary" value="advance" />
                <Tab label="Salary Heads" value="heads" />
                <Tab label="Security Money" value="securityMoney" />
                <Tab label="Salary Transactions" value="transaction" />
              </TabList>
            </TabsWrapper>
          </Stack>
          <TabPanel value="payroll" sx={{ p: "0 !important" }}>
            <PayrollPage />
          </TabPanel>
          <TabPanel value="advance" sx={{ p: "0 !important" }}>
            <AdvancePage />
          </TabPanel>
          <TabPanel value="heads" sx={{ p: "0 !important" }}>
            <HeadsPage />
          </TabPanel>
          <TabPanel value="securityMoney" sx={{ p: "0 !important" }}>
            <SecurityMoney />
          </TabPanel>
          <TabPanel value="transaction" sx={{ p: "0 !important" }}>
            <SalaryTransactions />
          </TabPanel>
        </TabContext>
      </Container>
    </>
  );
};

export default StaffPayroll;
