import { Button, Stack, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useMemo, useState } from "react";
import { Eye } from "react-bootstrap-icons";
import "react-notifications/lib/notifications.css";
import { LoadingButton } from "@mui/lab";
import { format } from "date-fns";
import { getStudentProfileByRequestDocId } from "../../../../services/student-management";
import AdmissionApprovalModal from "./AdmissionApprovalModal";
import { CustomTextField } from "assets/styles/globalStyledComponent";
import CommonTable1 from "app/components/Tables/CommonTable1";
import dayjs from "dayjs";
import { DATE_FORMAT } from "app/config/constants";
import { useQuery } from "react-query";
import { getStudentAdmissionRequests } from "app/services/schoolService/studentProfile";
import { toast } from "react-toastify";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import ExportButtonGroup from "app/components/common/ExportButtonGroup";
import ExportDialog from "app/components/common/exportDialog";
import SummaryCommonComponents from "app/components/common/SummaryCommonComponents";
export default function AdmissionRequest() {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [fromDate, setFromDate] = useState(dayjs().subtract(1, "day"));
  const [toDate, setToDate] = useState(new Date());
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);
  const [details, setDetails] = useState({});
  const [requestedOn, setrequestedOn] = useState("");
  const [requestedFor, setrequestedFor] = useState("");
  const [requestDocId, setRequestDocId] = useState("");
  const [status, setStatus] = useState("");
  const [state, setState] = useState({
    t_requested: 0,
    t_approved: 0,
    t_rejected: 0,
    t_withDrawn: 0,
  });
  const {
    isLoading: getStudentAdmissionRequestsLoading,
    isFetching: getStudentAdmissionRequestsFetching,
    data: getStudentAdmissionRequestsData,
    refetch: getStudentAdmissionRequestsRefetch,
  } = useQuery({
    queryFn: () => getStudentAdmissionRequests({ from: fromDate, to: toDate }),
    queryKey: ["getStudentAdmissionRequests"],
    onSuccess: (success) => {
      setState((prev) => ({
        ...prev,
        t_requested: success?.data?.t_requested,
        t_approved: success?.data?.t_approved,
        t_rejected: success?.data?.t_rejected,
        t_withDrawn: success?.data?.t_withDrawn,
      }));
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
    enabled: false,
  });

  const {
    isLoading: getStudentProfileByRequestDocIdLoading,
    isFetching: getStudentProfileByRequestDocIdFetching,
  } = useQuery({
    queryFn: () => getStudentProfileByRequestDocId(requestDocId),
    queryKey: ["getStudentProfileByRequestDocId", requestDocId],
    onSuccess: (success) => {
      setDetails(success?.data?.obj);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
    enabled: requestDocId ? true : false,
  });

  const handleViewAction = (cell) => {
    setRequestDocId(cell?.row?.original?._id);
    setrequestedFor(cell?.row?.original?.className);
    setrequestedOn(cell?.row?.original?.requestedOn);
    setIsViewModalVisible(true);
    setStatus(cell?.row?.original?.status);
  };
  const [openCheckBox, setOpenCheckBox] = useState(false);
  let header = [
    "#",
    "Enquiry_Person",
    "Requested_For",
    "Requested_On",
    "Status",
  ];
  let exportArray = getStudentAdmissionRequestsData?.data?.list?.map(
    (item, index) => {
      return {
        "#": index < 9 ? `0${index + 1}` : index + 1,
        Enquiry_Person: item?.fullName ?? "--",
        Requested_For: item?.className ?? "--",
        Requested_On: item?.requestedOn ?? "--",
        Status: item?.status ?? "--",
      };
    }
  );
  const { data: getStudentAdmissionRequestsData2 } = useQuery({
    queryFn: () => getStudentAdmissionRequests(),
    queryKey: ["getStudentAdmissionRequests"],
    onSuccess: (success) => {
      setState((prev) => ({
        ...prev,
        t_requested: success?.data?.t_requested,
        t_approved: success?.data?.t_approved,
        t_rejected: success?.data?.t_rejected,
        t_withDrawn: success?.data?.t_withDrawn,
      }));
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });
  const columns = useMemo(() => {
    return [
      {
        header: "Sr.No",
        Cell: ({ cell }) => (
          <p
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {parseInt(cell.row.id) + 1}
          </p>
        ),

        size: isMobile ? 40 : 20,
      },
      {
        header: "Enquiry Person",
        accessorKey: "fullName",
        size: isMobile ? 60 : 80,
      },
      {
        header: "Requested For",
        accessorKey: "className",
        size: isMobile ? 60 : 60,
      },
      {
        header: "Requested On",

        accessorKey: "requestedOn",
        id: "requestedOn",
        accessorfn: (row) => row?.requestedOn,
        Cell: ({ cell }) => {
          return format(new Date(cell.getValue()), "dd MMM, yyyy");
        },
        size: isMobile ? 60 : 60,
      },
      {
        header: "Status",
        accessorKey: "status",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <Box
              sx={{
                px: isMobile ? 0.3 : 1,
                py: 0.5,
                borderRadius: "20px",
                borderWidth: "1px",
                borderStyle: "solid",
                display: "inline-block",
                backgroundColor:
                  original?.status === "REQUESTED"
                    ? "rgba(241, 151, 15, 0.05)"
                    : original?.status === "APPROVED"
                    ? "rgba(20, 241, 15, 0.03)"
                    : original?.status === "IN_PROGRESS"
                    ? "rgba(241, 151, 15, 0.05)"
                    : "rgba(241, 15, 15, 0.05)",
                borderColor:
                  original?.status === "REQUESTED"
                    ? "rgba(241, 151, 15, 0.3)"
                    : original?.status === "APPROVED"
                    ? "rgba(7, 177, 3, 0.25)"
                    : original?.status === "IN_PROGRESS"
                    ? "rgba(241, 151, 15, 0.3)"
                    : "rgba(241, 15, 15, 0.3)",
              }}
            >
              <Typography
                sx={{
                  color:
                    original?.status === "REQUESTED"
                      ? "rgba(211, 132, 12, 1)"
                      : original?.status === "APPROVED"
                      ? "rgba(7, 177, 3, 1)"
                      : original?.status === "IN_PROGRESS"
                      ? "rgba(211, 132, 12, 1)"
                      : "rgba(222, 15, 15, 1)",
                  fontWeight: 500,
                }}
              >
                {original?.status}
              </Typography>
            </Box>
          );
        },
        size: isMobile ? 80 : 60,
      },
      {
        header: "Actions",
        Cell: ({ cell }) => {
          return (
            <Button
              onClick={() => {
                handleViewAction(cell);
              }}
              style={{
                backgroundColor: "#EBF0F4",
                borderRadius: "8px",
                border: "none",
                outline: "none",
                width: isMobile ? "10px" : "2.5rem",
                height: "2.5rem",
              }}
            >
              <Eye />
            </Button>
          );
        },
        size: isMobile ? 100 : 30,
      },
    ];
  }, []);

  let summaryArray = [
    {
      key: "Total Requested",
      value: state.t_requested,
    },
    {
      key: "Total Approved",
      value: state.t_approved,
    },
    {
      key: "Total Rejected",
      value: state.t_rejected,
    },
    {
      key: "Total Withdrawn",
      value: state.t_withDrawn,
    },
  ];

  return (
    <>
      <ExportDialog
        open={openCheckBox}
        handleClose={() => setOpenCheckBox(false)}
        header={header}
        exportArray={exportArray}
        fileName="Admission_Request"
      />

      {/* <YoutubeVideoDialog
        open={openYoutubeDialog}
        handleCloseDialog={() => setOpenYoutubeDialog(false)}
        videoLink={videoLink}
      /> */}
      <AdmissionApprovalModal
        refetch={() => getStudentAdmissionRequestsRefetch()}
        open={isViewModalVisible}
        setOpen={() => {
          setIsViewModalVisible(false);
          setRequestDocId("");
        }}
        data={details}
        requestedFor={requestedFor}
        requestedOn={requestedOn}
        requestDocId={requestDocId}
        status={status}
        loading={
          getStudentProfileByRequestDocIdLoading ||
          getStudentProfileByRequestDocIdFetching
            ? true
            : false
        }
      />

      <Stack
        direction="row"
        alignItems="flex-end"
        gap={1.5}
        sx={{ pt: 1 }}
        justifyContent={"space-between"}
      >
        <Stack direction="row" gap={1}>
          <Box sx={{ width: "15vw", flex: isMobile && "1" }}>
            <TextFieldLabel title={"From"} required />
            <DesktopDatePicker
              inputFormat={DATE_FORMAT.indianDate}
              value={fromDate}
              fullWidth
              onChange={(newValue) => {
                setFromDate(newValue);
              }}
              renderInput={(params) => (
                <CustomTextField {...params} fullWidth />
              )}
            />
          </Box>

          <Box sx={{ width: "15vw", flex: isMobile && "1" }}>
            <TextFieldLabel title={"To"} required />
            <DesktopDatePicker
              inputFormat={DATE_FORMAT.indianDate}
              value={toDate}
              onChange={(newValue) => {
                setToDate(newValue);
              }}
              renderInput={(params) => (
                <CustomTextField {...params} fullWidth />
              )}
            />
          </Box>

          <LoadingButton
            variant="contained"
            loading={
              getStudentAdmissionRequestsLoading ||
              getStudentAdmissionRequestsFetching
            }
            color="secondary"
            sx={{
              width: "15%",
              py: 1.2,
              mb: 0.5,
              mt: 2,
            }}
            onClick={() => getStudentAdmissionRequestsRefetch()}
          >
            View
          </LoadingButton>
        </Stack>
        <Stack
          direction="row"
          alignItems="flex-end
    "
          gap={2}
          sx={{ pt: 1 }}
        >
          <Box>
            <ExportButtonGroup
              handleClickExcel={() => setOpenCheckBox(true)}
              showPdf={false}
            />
          </Box>
          {/* <YouTube
            sx={{
              color: "#FF0000",
              fontSize: "2.5rem",
              cursor: "pointer",
            }}
            onClick={() => setOpenYoutubeDialog(true)}
          /> */}
        </Stack>
      </Stack>

      <SummaryCommonComponents summaryArray={summaryArray} />

      {/* table section  */}
      <Box sx={{ mt: 2 }}>
        <CommonTable1
          columns={columns}
          data={
            getStudentAdmissionRequestsData?.data?.list ||
            getStudentAdmissionRequestsData2?.data?.list
          }
        />
      </Box>
    </>
  );
}
