const AUTH_BASE = process.env.REACT_APP_AUTH_BASE;
const SCHOOL_BASE = process.env.REACT_APP_SCHOOL_BASE;
const SMS_BASE = process.env.REACT_APP_SMS_BASE;

const API_ENDPOINTS = {
  auth: {
    signIn: `${AUTH_BASE}/auth/signin`,
    // getUserProfile: `${SCHOOL_BASE}/user/getUserProfile`,
    schoolList: `${AUTH_BASE}/access/getSchoolList`,
    schoolAccessToken: `${AUTH_BASE}/access/schoolAccessToken`,
    refreshToken: `${AUTH_BASE}/auth/refreshToken`,
    schoolRefreshToken: `${AUTH_BASE}/access/schoolRefreshToken`,
    staffProfile: `${SCHOOL_BASE}/staff/getStaffProfile`,
    changePasswordWithoutOtp: `${AUTH_BASE}/auth/changePasswordWithoutOtp`,
    getRolesForSchool: `${AUTH_BASE}/auth/getRolesForSchool`,
    changeRole: `${AUTH_BASE}/access/changeRole`,
  },

  school: {
    getAllSessions: `${SCHOOL_BASE}/schoolManagement/getAllSessions`,
    getInvitationCode: `${SCHOOL_BASE}/schoolManagement/getSchoolCode`,
    updateInvitationCode: `${SCHOOL_BASE}/schoolManagement/updateSchoolCode`,
    editSchoolSession: `${SCHOOL_BASE}/schoolManagement/editSchoolSession`,
    addSchoolSession: `${SCHOOL_BASE}/schoolManagement/addSession`,
    getAllDepartments: `${SCHOOL_BASE}/schoolManagement/getAllDepartments`,
    getStaffByDepartmentDocId: `${SCHOOL_BASE}/school/getStaffByDepartmentDocId`,
    updateDepartment: `${SCHOOL_BASE}/schoolManagement/updateDepartment`,
    addDepartment: `${SCHOOL_BASE}/schoolManagement/addDepartment`,
    addUpdateDepartmentHead: `${SCHOOL_BASE}/schoolManagement/addUpdateDepartmentHead`,
    getAllClasses: `${SCHOOL_BASE}/schoolManagement/getAllClasses`,
    getSubjectByClassDocIdSection: `${SCHOOL_BASE}/schoolManagement/getSubjectByClassDocIdSection`,
    deleteSection: `${SCHOOL_BASE}/schoolManagement/deleteSection`,
    addClassSection: `${SCHOOL_BASE}/schoolManagement/addClassSection`,
    addClass: `${SCHOOL_BASE}/schoolManagement/addClass`,
    switchSubjectType: `${SCHOOL_BASE}/schoolManagement/switchSubjectType`,
    addSubject: `${SCHOOL_BASE}/schoolManagement/addSubject`,
    addStudentInSubject: `${SCHOOL_BASE}/schoolManagement/addStudentInSubject`,
    removeStudentFromSubject: `${SCHOOL_BASE}/schoolManagement/removeStudentFromSubject`,
    deleteSubjectByDocId: `${SCHOOL_BASE}/schoolManagement/deleteSubjectByDocId`,
    deleteClass: `${SCHOOL_BASE}/schoolManagement/deleteClass`,

    updateClassTeacher: `${SCHOOL_BASE}/schoolManagement/updateClassTeacher`,
    updateSubjectTeacher: `${SCHOOL_BASE}/schoolManagement/updateSubjectTeacher`,
    updateSupportiveTeacherForSubject: `${SCHOOL_BASE}/schoolManagement/updateSupportiveTeacherForSubject`,
    pullPushSupportiveTeacherForSubject: `${SCHOOL_BASE}/schoolManagement/pullPushSupportiveTeacherForSubject`,
    getPermissions: `${SCHOOL_BASE}/access/getPermissions`,
    updatePermission: `${SCHOOL_BASE}/access/updatePermission`,
    getSchoolProfile: `${SCHOOL_BASE}/school/getSchoolProfile`,
    getSchoolFirm: `${SCHOOL_BASE}/school/getSchoolFirm`,

    getFoldersDetail: `${SCHOOL_BASE}/school/getFoldersDetail`,
    getAllThought: `${SCHOOL_BASE}/school/getAllThought`,
    DeleteFolder: `${SCHOOL_BASE}/school/DeleteFolder`,
    addFolder: `${SCHOOL_BASE}/school/AddFolder`,
    updateFolderName: `${SCHOOL_BASE}/school/updateFolderName`,
    AddImage: `${SCHOOL_BASE}/school/AddImage`,
    EditImageDetail: `${SCHOOL_BASE}/school/EditImageDetail`,
    getFoldersDetailImage: `${SCHOOL_BASE}/school/getFoldersDetail`,
    DeleteImageDetail: `${SCHOOL_BASE}/school/DeleteImageDetail`,
    deleteThoughtOfDay: `${SCHOOL_BASE}/school/deleteThoughtOfDay`,
    ApproveImageStatus: `${SCHOOL_BASE}/school/ApproveImageStatus`,
    updateSchoolProfile: `${SCHOOL_BASE}/school/updateSchoolProfile`,
    upsertSchoolFirmProfile: `${SCHOOL_BASE}/school/upsertSchoolFirmProfile`,

    addUpdateThoughtOfTheDay: `${SCHOOL_BASE}/school/addUpdateThoughtOfTheDay`,
    updatePic: `${SCHOOL_BASE}/school/updatePic`,
    sendReminderForFee: `${SCHOOL_BASE}/school/sendReminderForFee`,
    getStudentBySubjectDocId: `${SCHOOL_BASE}/schoolManagement/getStudentBySubjectDocId`,
    copySubject: `${SCHOOL_BASE}/schoolManagement/copySubject`,
    deleteDepartment: `${SCHOOL_BASE}/schoolManagement/deleteDepartment`,

    getConstantSubject: `${SCHOOL_BASE}/misc/getConstantSubject`,
    getSectionList: `${SCHOOL_BASE}/misc/getSectionList`,
  },
  reception: {
    getComplaint: `${SCHOOL_BASE}/reception/getComplaint`,
    getVisitorInfo: `${SCHOOL_BASE}/reception/getVisitorInfo`,
    markStaffAttendance: `${SCHOOL_BASE}/reception/markStaffAttendance`,
    addVisitor: `${SCHOOL_BASE}/reception/addVisitorData`,
    exitVisitor: `${SCHOOL_BASE}/reception/exitVisitors`,
    updateVisitorData: `${SCHOOL_BASE}/reception/updateVisitorData`,
    getStaffAttendance: `${SCHOOL_BASE}/reception/getStaffAttendanceByDate`,
    getAllStaffAttendanceByMonth: `${SCHOOL_BASE}/reception/getAllStaffAttendanceByMonth`,
    createTodayAttendance: `${SCHOOL_BASE}/reception/createTodayStaffAttendance`,
    markHoliday: `${SCHOOL_BASE}/reception/markHolidayForStaff`,
    updateStaffAttendance: `${SCHOOL_BASE}/reception/updateStaffAttendance`,
    deleteComplaint: `${SCHOOL_BASE}/reception/deleteComplaint`,
    updateAssignee: `${SCHOOL_BASE}/reception/updateAssignee`,
    addNewComplaintByStaff: `${SCHOOL_BASE}/reception/addNewComplaintByStaff`,
    resolveComplaint: `${SCHOOL_BASE}/reception/resolveComplaint`,
    putStaffOnLeave: `${SCHOOL_BASE}/reception/putStaffOnLeave`,
    putStaffOnLeave2: `${SCHOOL_BASE}/reception/putStaffOnLeave2`,
    getMonthlyStaffAttendanceBySchool: `${SCHOOL_BASE}/reception/getMonthlyStaffAttendanceBySchool`,
  },
  hr: {
    getActiveStaffBySchoolDocId: `${SCHOOL_BASE}/hr/getActiveStaffBySchoolDocId`,
    getDeActiveStaff: `${SCHOOL_BASE}/hr/getDeActiveStaff`,
    getStaffDetailByStaffDocId: `${SCHOOL_BASE}/hr/getStaffByStaffDocId`,
    updateProfilePic: `${SCHOOL_BASE}/staff/updateProfilePic`,
    getPayroll: `${SCHOOL_BASE}/payroll/getPayroll`,
    getOnlinePayrollForPrint: `${SCHOOL_BASE}/payroll/getOnlinePayrollForPrint`,
    getAllJoiningRequest: `${SCHOOL_BASE}/hr/getAllJoiningRequest`,
    getDetailRequest: `${SCHOOL_BASE}/HR/getStaffProfileByJoiningRequestDocId`,
    rejectJoiningRequest: `${SCHOOL_BASE}/HR/rejectJoiningRequest`,
    approveJoiningRequest: `${SCHOOL_BASE}/HR/approveJoiningApproval`,
    removeStaff: `${SCHOOL_BASE}/hr/removeStaff`,
    addStaffLeave: `${SCHOOL_BASE}/hr/addStaffLeave`,
    updateStaffLeaveReport: `${SCHOOL_BASE}/hr/updateStaffLeaveReport`,
    getLeave: `${SCHOOL_BASE}/hr/getLeaveBySchool`,
    gatStaffListByDesignation: `${SCHOOL_BASE}/hr/getStaffListByDesignation`,
    updatePersonalInfo: `${SCHOOL_BASE}/staff/updatePersonalInfo`,
    updateParentInfo: `${SCHOOL_BASE}/staff/updateParentInfo`,
    updateEducationInfo: `${SCHOOL_BASE}/staff/updateEducationInfo`,
    updateExperienceInfo: `${SCHOOL_BASE}/staff/updateExperienceInfo`,
    updateEmployeeInfo: `${SCHOOL_BASE}/hr/updateEmployeeInfo`,
    updateAddressInfo: `${SCHOOL_BASE}/staff/updateAddressInfo`,
    updateAssignedFor: `${SCHOOL_BASE}/hr/updateAssignedFor`,
    getAttendanceByStaffDocIdAndYear: `${SCHOOL_BASE}/reception/getAttendanceByStaffDocIdAndYear`,
    updateStaffLeave: `${SCHOOL_BASE}/hr/updateStaffLeave`,
    rejectLeave: `${SCHOOL_BASE}/hr/rejectLeave`,
    withdrawLeave: `${SCHOOL_BASE}/hr/withdrawLeave`,
    approveLeave: `${SCHOOL_BASE}/hr/approveLeave`,
    deleteApproveLeave: `${SCHOOL_BASE}/hr/deleteApproveLeave`,
    addStaffBySchoolAdmin: `${SCHOOL_BASE}/hr/addStaffBySchoolAdmin`,
    getLeaveReportByStaffDocId: `${SCHOOL_BASE}/hr/getLeaveReportByStaffDocId`,
    getPayrollHeads: `${SCHOOL_BASE}/payroll/getAllPayrollHead`,
    getAllAdvanceSalaryBySelf: `${SCHOOL_BASE}/payroll/getAllAdvanceSalaryBySelf`,
    requestAdvanceSalaryBySelf: `${SCHOOL_BASE}/payroll/requestAdvanceSalaryBySelf`,
    updatePayrollHead: `${SCHOOL_BASE}/payroll/updatePayrollHead`,
    updateStatusPayrollHead: `${SCHOOL_BASE}/payroll/updateStatusOfPayrollHead`,
    addPayrollHead: `${SCHOOL_BASE}/payroll/addPayrollHead`,
    deletePayrollHead: `${SCHOOL_BASE}/payroll/deletePayrollHead`,
    getAdvancePayrollTransaction: `${SCHOOL_BASE}/payroll/getAllAdvancePayrollTransaction`,
    updateStatusOfRequestedAdvanceSalary: `${SCHOOL_BASE}/payroll/updateStatusOfRequestedAdvanceSalary`,
    requestAdvanceSalary: `${SCHOOL_BASE}/payroll/requestAdvanceSalary`,
    payAdvanceSalary: `${SCHOOL_BASE}/payroll/payAdvanceSalary`,
    createAndViewMonthlyPayroll: `${SCHOOL_BASE}/payroll/createAndViewMonthlyPayroll`,
    getThreeMonthsAttendance: `${SCHOOL_BASE}/reception/getLastThreeMonthStaffAttendance`,
    getLastThreeMonthSortedDataStaffAttendance: `${SCHOOL_BASE}/reception/getLastThreeMonthSortedDataStaffAttendance`,
    getPayrollTransaction: `${SCHOOL_BASE}/payroll/getPayrollTransaction`,
    addPayrollTransaction: `${SCHOOL_BASE}/payroll/addPayrollTransaction`,
    updatePayrollTransaction: `${SCHOOL_BASE}/payroll/updatePayrollTransaction`,
    deletePayrollTransaction: `${SCHOOL_BASE}/payroll/deletePayrollTransaction`,
    updateMonthlyPayroll: `${SCHOOL_BASE}/payroll/updatePayroll`,
    getEmployeeInfo: `${SCHOOL_BASE}/hr/getEmploymentInfo`,
    rejectMonthlyPayroll: `${SCHOOL_BASE}/payroll/rejectPayroll`,
    deletePayroll: `${SCHOOL_BASE}/payroll/deletePayroll`,
    approveMonthlyPayroll: `${SCHOOL_BASE}/payroll/approvePayroll`,
    payMonthlyPayroll: `${SCHOOL_BASE}/payroll/paySalary`,
  },
  commonApi: {
    getClassDropdown: `${SCHOOL_BASE}/school/getClassDropdown`,
    getAllDepartment: `${SCHOOL_BASE}/schoolManagement/getAllDepartments`,
    getStaffDetail: `${SCHOOL_BASE}/school/getStaffByDepartmentDocId`,
    getAllSubject: `${SCHOOL_BASE}/schoolManagement/getSubjectByClassDocIdSection`,
  },
  academic: {
    getHomework: `${SCHOOL_BASE}/academic/getHomework`,
    getSummarizedHomework: `${SCHOOL_BASE}/academic/getSummarizedHomework`,
    getSummarizedHomeworkForExport: `${SCHOOL_BASE}/academic/getSummarizedHomeworkForExport`,
    addHomework: `${SCHOOL_BASE}/academic/addHomework`,
    editHomework: `${SCHOOL_BASE}/academic/editHomework`,
    deleteHomework: `${SCHOOL_BASE}/academic/deleteHomework`,
    getStudentAttendanceByClassAndDate: `${SCHOOL_BASE}/academic/getStudentAttendanceByClassAndDate`,
    getStudentAttendance: `${SCHOOL_BASE}/academic/getStudentAttendance`,
    createAttendanceByClass: `${SCHOOL_BASE}/academic/createAttendanceByClass`,
    editStudentAttendance: `${SCHOOL_BASE}/academic/editStudentAttendance`,
    markAsHolidayClass: `${SCHOOL_BASE}/academic/markAsHolidayClass`,
    // time table
    addTimeTable: `${SCHOOL_BASE}/academic/addTimetable`,
    getTimeTable: `${SCHOOL_BASE}/academic/getAllTimeTable`,
    editTimeTable: `${SCHOOL_BASE}/academic/editTimetable`,
    approveTimeTable: `${SCHOOL_BASE}/academic/approveTimetable`,
    rejectTimeTable: `${SCHOOL_BASE}/academic/rejectTimetable`,
    deleteTimeTable: `${SCHOOL_BASE}/academic/deleteTimetable`,
    getTimeTableById: `${SCHOOL_BASE}/academic/getTimetableByTimetableDocId`,
    activateTableById: `${SCHOOL_BASE}/academic/activateTimetable`,

    getSubjectsForHomework: `${SCHOOL_BASE}/academic/getSubjectsForHomework`,
  },
  transport: {
    getFeeStructure: `${SCHOOL_BASE}/transport/getAllTransportationFeeList`,
    getAllRouteList: `${SCHOOL_BASE}/transport/getAllRouteList`,
    deleteTransportRoute: `${SCHOOL_BASE}/transport/deleteTransportRoute`,
    getTransport: `${SCHOOL_BASE}/transport/getTransport`,
    editFeeStructure: `${SCHOOL_BASE}/transport/updateTransportFee`,
    addTransportDetail: `${SCHOOL_BASE}/transport/addTransport`,
    updateTransportDetail: `${SCHOOL_BASE}/transport/updateTransportDetail`,
    updateStatusOfTransport: `${SCHOOL_BASE}/transport/updateStatusOfTransport`,
    AddFeeStructure: `${SCHOOL_BASE}/transport/addTransportFee`,
    updateTransportRoute: `${SCHOOL_BASE}/transport/updateTransportRoute`,
    deleteTransportFeeStructure: `${SCHOOL_BASE}/transport/deleteTransportFeeStructure`,
    addRouteDetail: `${SCHOOL_BASE}/transport/addTransportRoute`,
    getAllTransportLog: `${SCHOOL_BASE}/transport/getAllTransportLog`,
    addTransportLog: `${SCHOOL_BASE}/transport/addTransportLog`,
    updateTransportLogDetail: `${SCHOOL_BASE}/transport/updateTransportLogDetail`,
    deleteLogDetail: `${SCHOOL_BASE}/transport/deleteLogDetail`,
    replaceVehicle: `${SCHOOL_BASE}/transport/replaceVehicleInRoute`,
  },

  ledgerAccount: {
    addCollectionHead: `${SCHOOL_BASE}/ledger/addCollectionHead`,
    editCollectionHead: `${SCHOOL_BASE}/ledger/editCollectionHead`,
    getAllCollectionHead: `${SCHOOL_BASE}/ledger/getAllCollectionHead`,
    getAllCollectionSubHead: `${SCHOOL_BASE}/ledger/getAllCollectionSubHead`,
    getAllCollection: `${SCHOOL_BASE}/ledger/getAllCollections`,
    addCollection: `${SCHOOL_BASE}/ledger/addCollectionDetail`,
    deleteCollection: `${SCHOOL_BASE}/ledger/deleteCollection`,
    getAllExpenseHead: `${SCHOOL_BASE}/ledger/getAllExpenseHead`,
    addExpenseHead: `${SCHOOL_BASE}/ledger/addExpenseHead`,
    editExpenseHead: `${SCHOOL_BASE}/ledger/editExpenseHead`,
    getAllExpense: `${SCHOOL_BASE}/ledger/getAllExpenses`,
    addExpense: `${SCHOOL_BASE}/ledger/addExpenseDetail`,
    deleteExpense: `${SCHOOL_BASE}/ledger/deleteExpense`,
    verifyPaidExpense: `${SCHOOL_BASE}/ledger/verifyPaidExpense`,
    deleteCollectionHead: `${SCHOOL_BASE}/ledger/deleteCollectionHead`,
    deleteCollectionSubHead: `${SCHOOL_BASE}/ledger/deleteCollectionSubHead`,
    deleteExpenseHead: `${SCHOOL_BASE}/ledger/deleteExpenseHead`,
    deleteExpenseSubHead: `${SCHOOL_BASE}/ledger/deleteExpenseSubHead`,

    getInternalTransfer: `${SCHOOL_BASE}/ledger/getInternalTransfer`,
    addInternalTransfer: `${SCHOOL_BASE}/ledger/saveInternalTransfer`,
    updateStatusInternalTransfer: `${SCHOOL_BASE}/ledger/updateStatusInternalTransfer`,
    deleteInternalTransfer: `${SCHOOL_BASE}/ledger/deleteInternalTransfer`,
    getBookSellerNames: `${SCHOOL_BASE}/ledger/getBookSellerName`,
    addNewBookSeller: `${SCHOOL_BASE}/ledger/addBookSellerName`,
    updateBookQuantity: `${SCHOOL_BASE}/ledger/updateBookQuantity`,
    getBooksForSale: `${SCHOOL_BASE}/ledger/getBooksForSale`,
    getSoldBook: `${SCHOOL_BASE}/ledger/getSoldBook`,
    addBookExpenseDetail: `${SCHOOL_BASE}/ledger/addBookExpenseDetail`,
    updateBookRate: `${SCHOOL_BASE}/ledger/updateBookRate`,
    getBookGroup: `${SCHOOL_BASE}/ledger/getBookGroup`,
    addBookGroup: `${SCHOOL_BASE}/ledger/addBookGroup`,
    getTransactionByFirstInvoiceNo: `${SCHOOL_BASE}/ledger/getTransactionByFirstInvoice`,
    payInstallment: `${SCHOOL_BASE}/ledger/payInstallment`,
    getUtilityInvoice: `${SCHOOL_BASE}/ledger/getUtilityInvoice`,
    deleteUtilityInvoice: `${SCHOOL_BASE}/ledger/deleteUtilityInvoice`,
    saleBook: `${SCHOOL_BASE}/ledger/saleBook`,
    addCollectionDetail: `${SCHOOL_BASE}/ledger/addCollectionDetail`,
    getAllExpenseSubHead: `${SCHOOL_BASE}/ledger/getAllExpenseSubHead`,
    requestExpense: `${SCHOOL_BASE}/ledger/requestExpense`,
    rejectExpense: `${SCHOOL_BASE}/ledger/rejectExpense`,
    approveExpense: `${SCHOOL_BASE}/ledger/approveExpense`,
    payExpense: `${SCHOOL_BASE}/ledger/payExpense`,
    addCompletedExpenseDetail: `${SCHOOL_BASE}/ledger/addCompletedExpenseDetail`,
    delExpense: `${SCHOOL_BASE}/ledger/deleteExpense`,
    addCollectionSubHead: `${SCHOOL_BASE}/ledger/addCollectionSubHead`,
    addExpenseSubHead: `${SCHOOL_BASE}/ledger/addExpenseSubHead`,
  },
  fees: {
    getClasswiseCollectionsByFeeType: `${SCHOOL_BASE}/fee/getClasswiseCollectionsByFeeType`,
    getAllFeeStructure: `${SCHOOL_BASE}/fee/getAllFeeStructure`,
    getOldInvoice: `${SCHOOL_BASE}/fee/getOldInvoice`,
    getFeeCollection: `${SCHOOL_BASE}/fee/getFeeCollection`,
    convertOldInvoiceToNewInvoice: `${SCHOOL_BASE}/fee/convertOldInvoiceToNewInvoice`,
    getDistinctFeeCollection: `${SCHOOL_BASE}/fee/getDistinctFeeCollection`,
    getFeeStructureForStudentRegistration: `${SCHOOL_BASE}/newFee/getFeeStructureForStudentRegistration`,
    getAllClassDropdown: `${SCHOOL_BASE}/school/getClassDropdown`,
    addFeeStructure: `${SCHOOL_BASE}/fee/addFeeStructure`,
    getRejectFeeStructure: `${SCHOOL_BASE}/fee/rejectFeeStructure`,
    getApproveFeeStructure: `${SCHOOL_BASE}/fee/approveFeeStructure`,
    getDuesTillDate: `${SCHOOL_BASE}/fee/getDuesTillDateByClassSection`,
    getFeeReport: `${SCHOOL_BASE}/fee/getFeeReport`,
    getFeeCollectionByStudent: `${SCHOOL_BASE}/fee/getFeeCollectionByStudentSession`,
    payFee: `${SCHOOL_BASE}/fee/payFee`,
    updateFeeStructure: `${SCHOOL_BASE}/fee/updateFeeStructure`,
    deleteFeeStructure: `${SCHOOL_BASE}/fee/deleteFeeStructure`,
    aggregateFeeCollectionReport: `${SCHOOL_BASE}/fee/aggregateFeeCollectionReport`,
    getFeeCollectionsReportByUser: `${SCHOOL_BASE}/fee/getFeeCollectionsReportByUser`,
    updateFeeStructureRelaxationCategory: `${SCHOOL_BASE}/fee/updateFeeStructureRelaxationCategory`,
    chnageFeeTypeNameInFeeCollection: `${SCHOOL_BASE}/fee/chnageFeeTypeNameInFeeCollection`,
    runFeeByFeeStructure: `${SCHOOL_BASE}/fee/runFeeByFeeStructureDocId`,
    addFeeStructureInExistingGroup: `${SCHOOL_BASE}/fee/addFeeStructureInExistingGroup`,
    addFeeRelaxationCategory: `${SCHOOL_BASE}/newFee/addFeeRelaxationCategory`,
    getFeeRelaxationCategory: `${SCHOOL_BASE}/newFee/getFeeRelaxationCategory`,
    deleteFeeRelaxationCategory: `${SCHOOL_BASE}/newFee/deleteFeeRelaxationCategory`,
    getFeeType: `${SCHOOL_BASE}/newFee/getFeeType`,
    getFeeTypeForMultipleClass: `${SCHOOL_BASE}/newFee/getFeeTypeForMultipleClass`,
    addFeeType: `${SCHOOL_BASE}/newFee/addFeeType`,
    deleteFeeType: `${SCHOOL_BASE}/newFee/deleteFeeType`,
    addFeeStructureGroup: `${SCHOOL_BASE}/newFee/addFeeStructureGroup`,
    updateFineRate: `${SCHOOL_BASE}/newFee/updateFineRate`,
    getFeeStructureGroup: `${SCHOOL_BASE}/newFee/getFeeStructureGroup`,
    linkFeeStructureWithGroup: `${SCHOOL_BASE}/newFee/linkFeeStructureWithGroup`,
    deleteFeeStructureGroup: `${SCHOOL_BASE}/newFee/deleteFeeStructureGroup`,
    getAllFeeStructureNew: `${SCHOOL_BASE}/newFee/getAllFeeStructure`,
    addFeeStructureInGroup: `${SCHOOL_BASE}/newFee/addFeeStructureInGroup`,
    updateFeeStructureInGroup: `${SCHOOL_BASE}/newFee/updateFeeStructureInGroup`,
    deleteFeeStructure2: `${SCHOOL_BASE}/newFee/deleteFeeStructure`,
    approveFeeStructure: `${SCHOOL_BASE}/newFee/approveFeeStructure`,
    rejectFeeStructure: `${SCHOOL_BASE}/newFee/rejectFeeStructure`,
    getTransportDueList: `${SCHOOL_BASE}/newFee/getTransportDueList`,
  },
  studentManagement: {
    // checkAadharExist: `${SCHOOL_BASE}/student/checkAdharExist`,
    checkAadharExist: `${SCHOOL_BASE}/student/checkAdharExistInSameSchool`,
    confirmMobileNumber: `${SCHOOL_BASE}/student/confirmMobileNumber`,
    verifyOtpNumber: `${SCHOOL_BASE}/student/verifyOtpAndSendStudentDetail`,
    getAllFeeStructure: `${SCHOOL_BASE}/fee/getAllFeeStructure`,
    getAllRouteList: `${SCHOOL_BASE}/transport/getAllRouteList`,
    getTransportFeeByStopName: `${SCHOOL_BASE}/transport/getTransportFeeByStopName`,
    getLastAdmissionCount: `${SCHOOL_BASE}/student/getLastAdmissionCount`,
    registerFreshStudentBySchool: `${SCHOOL_BASE}/student/registerFreshStudent`,
    getStudentsByName: `${SCHOOL_BASE}/student/getWholeStudentsName`,
    getStudents1: `${SCHOOL_BASE}/student/getStudents1`,
    getAllDocumentByStudentDocId: `${SCHOOL_BASE}/student/getAllDocumentByStudentDocId`,
    getStudentForPromotionOrMigration: `${SCHOOL_BASE}/student/getStudentForPromotionOrMigration`,
    suspendStudent: `${SCHOOL_BASE}/student/suspendStudent`,
    getStudentsForGivenList: `${SCHOOL_BASE}/student/getStudentsForGivenList`,
    // getProfileByStudentDocId: `${SCHOOL_BASE}/student/getProfileByStudentDocId`,
    changeAdharNumber: `${SCHOOL_BASE}/student/changeAdharNumber`,

    updateStudentProfile: `${SCHOOL_BASE}/student/changeStudentProfilePic`,
    getStudentDataforMigrate: `${SCHOOL_BASE}/student/getStudentDataForMigrate`,
    migrateStudent: `${SCHOOL_BASE}/student/migrateStudent`,
    getTcById: `${SCHOOL_BASE}/student/getTransferCertificate`,
    updateMigratedStudent: `${SCHOOL_BASE}/student/updateTransferCertificate`,
    getTransportForStudent: `${SCHOOL_BASE}/student/getTransportForStudent`,
    getFeeCollectionByStudentSession: `${SCHOOL_BASE}/fee/getProfileFeeByStudentSession`,
    getFeeCollectionForStudentProfile: `${SCHOOL_BASE}/newFee/getFeeCollectionForStudentProfile`,
    collectFee: `${SCHOOL_BASE}/newFee/collectFee`,
    getFeeCollectionForPay: `${SCHOOL_BASE}/newFee/getFeeCollectionForPay`,
    deleteFee: `${SCHOOL_BASE}/fee/deleteFeeByFeeDocId`,
    deleteInvoice: `${SCHOOL_BASE}/ledger/deleteFeeInvoice`,
    updateAdmissionFee: `${SCHOOL_BASE}/fee/changeAdmissionFee`,
    updateMonthlyFee: `${SCHOOL_BASE}/fee/changeMonthlyFee`,
    getAttendanceBySessionForStudent: `${SCHOOL_BASE}/student/getStudentAttendanceByYearAndMonth`,
    promoteStudent: `${SCHOOL_BASE}/student/promoteStudent`,
    addExistingStudent: `${SCHOOL_BASE}/student/addExistingStudentToSchool`,
    admissionApproveStudent: `${SCHOOL_BASE}/student/admitStudentAdmissionApproval`,
    updateAdmissionInfo: `${SCHOOL_BASE}/student/updateAdmissionInfo`,
    updateClassInfo: `${SCHOOL_BASE}/student/updateStudentClassInfo`,
    updateTransportInfoInStudentProfile: `${SCHOOL_BASE}/student/updateTransportInfoInStudentProfile`,
    updateHostelInfo: `${SCHOOL_BASE}/student/updateHostelInfo`,
    updatePreSchoolInfo: `${SCHOOL_BASE}/student/updatePreSchoolInfo`,
    updateAddressInfo: `${SCHOOL_BASE}/student/updateAddressInfo`,
    updateStudentPersonalInfoBySchool: `${SCHOOL_BASE}/student/updateStudentPersonalInfoBySchool`,
    updateParentInfo: `${SCHOOL_BASE}/student/updateParentInfo`,
    getTransport: `${SCHOOL_BASE}/transport/getTransport`,
    uploadDocumentForStudent: `${SCHOOL_BASE}/student/uploadDocumentForStudent`,
    getAdmissionRequests: `${SCHOOL_BASE}/student/getStudentAdmissionRequests`,
    getStudentProfileByRequestDocId: `${SCHOOL_BASE}/student/getStudentProfileByAdmissionRequestDocId`,
    rejectAdmissionRequest: `${SCHOOL_BASE}/student/rejectAdmissionRequest`,
    approveAdmissionRequest: `${SCHOOL_BASE}/student/admitStudentAdmissionApproval`,
    getAlumni: `${SCHOOL_BASE}/student/getTransferCertificate`,
    getInvoiceDetail: `${SCHOOL_BASE}/ledger/getInvoice`,
    getWholeStudentName: `${SCHOOL_BASE}/student/getWholeStudentsName`,
    getRollNumberInRequestedOrder: `${SCHOOL_BASE}/student/getRollNumberInRequestedOrder`,
    getAppDownloadData: `${SCHOOL_BASE}/student/getAppDownloadData`,
  },

  dashboard: {
    getStudentCount: `${SCHOOL_BASE}/student/getAllStudentNumber`,
    getAllNewAddmissionBySession: `${SCHOOL_BASE}/student/getAllNewAdmissions`,
    getAllTeacherCount: `${SCHOOL_BASE}/dashboard/getAllTeacherNumber`,
    getAllOtherStaffCount: `${SCHOOL_BASE}/dashboard/getAllNonTeacherNumber`,
    getClassVsStudent: `${SCHOOL_BASE}/student/getClassVsStudent`,
    getTodayStudentAttendance: `${SCHOOL_BASE}/academic/getTodayStudentAttendanceData`,
    getStudentAdmissionCount: `${SCHOOL_BASE}/dashboard/getStudentAdmissionCount`,
    getStaffCount: `${SCHOOL_BASE}/dashboard/getStaffCount`,
    getDashboardFeeCollectionDetail: `${SCHOOL_BASE}/dashboard/getDashboardFeeCollectionDetail`,
    getTodayStudentAttendanceData: `${SCHOOL_BASE}/dashboard/getTodayStudentAttendanceData`,
    getApprovalData: `${SCHOOL_BASE}/dashboard/getApprovalData`,
    getStudentCountByDueAmount: `${SCHOOL_BASE}/dashboard/getStudentCountByDueAmount`,
    getTodayStaffAttendanceData: `${SCHOOL_BASE}/dashboard/getTodayStaffAttendanceData`,
    getCollectionVsExpenseData: `${SCHOOL_BASE}/dashboard/getCollectionVsExpenseData`,
    getAllEvent: `${SCHOOL_BASE}/dashboard/getAllEvent`,
    getTodayActiveNoticeForStaff: `${SCHOOL_BASE}/dashboard/getTodayActiveNoticeForStaff`,
    getAllEventAcademic: `${SCHOOL_BASE}/calendar/getAllEvent`,
    addEvent: `${SCHOOL_BASE}/calendar/addEvent`,
    updateEvent: `${SCHOOL_BASE}/calendar/updateEvent`,
    sendTextReminderForFee: `${SCHOOL_BASE}/dashboard/sendTextReminderForFee`,
    deleteEvent: `${SCHOOL_BASE}/calendar/deleteEvent`,
    deleteStudentAttendance: `${SCHOOL_BASE}/academic/deleteStudentAttendance`,
    getTodayEvent: `${SCHOOL_BASE}/calendar/getTodayEvent`,
  },
  communication: {
    addNewNotice: `${SCHOOL_BASE}/communication/addNewNotice`,
    updateNotice: `${SCHOOL_BASE}/communication/updateNotice`,
    getNotice: `${SCHOOL_BASE}/communication/getNotice`,
    publishNotice: `${SCHOOL_BASE}/communication/publishNotice`,
    revertNotice: `${SCHOOL_BASE}/communication/revertNotice`,
    // getNoticeByDocId: `${SCHOOL_BASE}/communication/getNotice`,
    getSMSAccountDetail: `${SMS_BASE}/sms/getSMSaccountDetail`,

    getApprovedTemplateBySenderId: `${SMS_BASE}/dlt/getApprovedTemplateBySenderId`,
    getAllTemplateStatus: `${SMS_BASE}/dlt/getAllTemplateStatus`,
    addSMStemplate: `${SMS_BASE}/dlt/addSMStemplate`,
    editSMStemplate: `${SMS_BASE}/dlt/addSMStemplate`,
    getMyEntity: `${SMS_BASE}/dlt/getMyEntity`,
    addEntity: `${SMS_BASE}/dlt/addEntity`,
    addNewSenderId: `${SMS_BASE}/dlt/addNewSenderId`,
  },
  syllabus: {
    getSyllabus: `${SCHOOL_BASE}/syllabus/getSyllabus`,
    getSyllabusBySubject: `${SCHOOL_BASE}/syllabus/getSyllabusBySubject`,
    getTopicsByChapter: `${SCHOOL_BASE}/syllabus/getTopicsByChapter`,
    addUnit: `${SCHOOL_BASE}/syllabus/addUnit`,
    updateUnitName: `${SCHOOL_BASE}/syllabus/updateUnitName`,
    updateChapterDetail: `${SCHOOL_BASE}/syllabus/updateChapterDetail`,
    addTopicsInChapter: `${SCHOOL_BASE}/syllabus/addTopicsInChapter`,
    getUnitsBySubject: `${SCHOOL_BASE}/syllabus/getUnitsBySubject`,
    addChapterInUnit: `${SCHOOL_BASE}/syllabus/addChapterInUnit`,
    deleteUnit: `${SCHOOL_BASE}/syllabus/deleteUnit`,
    deleteChapter: `${SCHOOL_BASE}/syllabus/deleteChapter`,
    updateAssigneeToChapter: `${SCHOOL_BASE}/syllabus/updateAssigneeToChapter`,
    getRatedStudentDetail: `${SCHOOL_BASE}/syllabus/getRatedStudentDetail`,
    deleteTopic: `${SCHOOL_BASE}/syllabus/deleteTopic`,
    updateTopicDetail: `${SCHOOL_BASE}/syllabus/updateTopicDetail`,
    updateTopicRatingByAdmin: `${SCHOOL_BASE}/syllabus/updateTopicRatingByAdmin`,
    updateStatusOfTopic: `${SCHOOL_BASE}/syllabus/updateStatusOfTopic`,
  },
};

export default API_ENDPOINTS;
